import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {TranslateService} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {UploadModalComponent} from '../../../../shared/components/upload-modal/upload-modal.component';
import {regex} from '../../../../shared/regex/form.regex';
import {BadgesService} from '../../../../shared/service/badges.service';
import {FileUtilsService} from '../../../../shared/utils/file-utils.service';
import {maximumSizeFile} from '../../../../shared/validators/maximum-size-file.validator';
import {requiredFileType} from '../../../../shared/validators/required-file-type.validator';
import {
  CreditNoteProformaBodyResourceModel,
  InvoiceProformaBodyResourceModel,
} from '../../models/invoice-proforma-body-resource.model';
import {InvoicingModel} from '../../models/InvoicingModel';
import {InvoiceService} from '../../services/invoice.service';

@Component({
  selector: 'proforma-generation',
  templateUrl: './credit-note-generation.component.html',
  styleUrls: ['./credit-note-generation.component.scss'],
})
export class CreditNoteGenerationComponent implements OnInit {
  protected readonly faTimes = faTimes;
  proformaFile;
  proformaData: CreditNoteProformaBodyResourceModel;
  invoiceProformaGenerationForm: FormGroup;
  invoiceTransmitForm: FormGroup;
  manualInvoiceFilename;
  manualInvoiceFileToUpload: File = null;
  loading: boolean = false;
  performingValidation = false;
  public currentDate: Date;
  public accountingMonth: Date;
  public invoice: InvoicingModel;

  constructor(private invoiceService: InvoiceService,
              private fb: FormBuilder,
              private toastrService: ToastrService,
              private translateService: TranslateService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public uploadModal: MatDialog,
              private badgeService: BadgesService,
              private logger: NGXLogger,
  ) {
    this.currentDate = new Date();
  }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.params['id'];
    this.invoiceService.getInvoiceById(id).subscribe((res: any) => {
      this.invoice = res;
      this.constructInvoiceProformaGenerationForm();
    });
  }

  canDisplay(formGroup: FormGroup, formControl: string): boolean {
    if (formGroup.get(formControl).disabled) {
      return false;
    } else if (formGroup.get(formControl).pristine) {
      return false;
    } else {
      return formGroup.get(formControl).value !== '';
    }
  }

  clearInputField(formGroup: FormGroup, formControl: string): void {
    formGroup.get(formControl).reset();
  }

  invoiceProformaGenerationFormSubmit() {
    this.loading = true;
    const proformaBodyRequest: CreditNoteProformaBodyResourceModel = {
      creditNoteNumber: this.invoiceProformaGenerationForm.get('invoiceNumber').value,
      reason: this.invoiceProformaGenerationForm.get('reason').value,
      creditNoteDate: undefined,
      proforma: true,

    };
    // this.logger.info('Your proforma body request is:', proformaBodyRequest);

    this.invoiceService.generateCreditNoteProforma(this.invoice.id, proformaBodyRequest).subscribe({
      next: (res: any) => {
        this.invoiceProformaGenerationForm.disable();
        this.loading = false;
        this.proformaFile = res.fileContent;
        this.constructInvoiceTransmitForm(proformaBodyRequest);
      },
      error: (error: any) => {
        if (error.error?.errors?.invoiceNumber[0].code === 'UniqueInvoice') {
          this.toastrService.error(this.translateService.instant('proforma_generation.proforma-generation-ts.invoice-generation-form-submit.UniqueInvoice'));
        } else {
          this.toastrService.error(this.translateService.instant('global.ts.error'));
        }
        this.loading = false;
        this.logger.error('An error occurred during proforma generation:', error);
      },
    });
  }

  cancelProforma() {
    this.invoiceProformaGenerationForm.enable();
    this.invoiceProformaGenerationForm.reset();
    this.manualInvoiceFileToUpload = null;
    this.proformaFile = null;
    this.loading = false;
  }

  invoiceTransmitFormSubmit() {
    this.performingValidation = true;
    const transmitData = Object.assign(new InvoiceProformaBodyResourceModel(), this.proformaData);
    transmitData.proforma = false;

    if (this.invoiceTransmitForm.enabled) {
      this.invoiceService.mergeValues(transmitData, this.invoiceTransmitForm.value);
      transmitData.creditNoteDate.setHours(this.currentDate.getHours());

      transmitData.taxlessTotal = transmitData.taxlessTotal;
      transmitData.taxAmount = transmitData.taxAmount;
      transmitData.totalIncludingTax = transmitData.totalIncludingTax;

    }

    this.invoiceService.generateCreditNoteProforma(this.invoice.id, transmitData).subscribe({
        next: () => {
          this.performingValidation = false;
          this.loading = false;
          this.toastrService.success(this.translateService.instant(`Invoice.ts.${transmitData.fileContent ? 'transmit_manual' : 'transmit_auto'}`));
          this.badgeService.refreshInvoiceToGenerateBadge();
          this.router.navigate(['/accounting/credit-notes']);
        },
        error: (err: any) => {
          this.performingValidation = false;
          this.loading = false;
          this.handleValidationError(err.error);
        },
      },
    );
  }

  clearFile() {
    this.manualInvoiceFilename = null;
    this.manualInvoiceFileToUpload = null;
    this.invoiceTransmitForm.reset();
    this.disableManualInvoice();
  }

  async onFileChange(file: File) {
    this.manualInvoiceFilename = file.name;
    this.manualInvoiceFileToUpload = file;
    this.enableManualInvoice();
    this.invoiceTransmitForm.get('fileSize').setValue(this.manualInvoiceFileToUpload);
    const fileBase64 = await FileUtilsService.convertFileToBase64(this.manualInvoiceFileToUpload);
    this.invoiceTransmitForm.get('fileName').setValue(this.manualInvoiceFileToUpload.name);
    this.invoiceTransmitForm.get('fileContent').setValue(fileBase64);
  }

  enableManualInvoice() {
    this.invoiceTransmitForm.enable();
  }

  disableManualInvoice() {
    this.invoiceTransmitForm.disable();
  }

  /**
   * Build reactive form with rules on advancePayment changes
   * @private
   */
  private constructInvoiceProformaGenerationForm() {
    this.invoiceProformaGenerationForm = this.fb.group({
      invoiceNumber: [null, [Validators.pattern(regex.noLeadingTrailingSpacesPattern), Validators.required]],
      reason: [null, [Validators.required]],
    });
  }

  constructInvoiceTransmitForm(proformaData: CreditNoteProformaBodyResourceModel) {
    this.proformaData = proformaData;
    this.invoiceTransmitForm = this.fb.group({
      fileSize: [null, [maximumSizeFile(2, 'mo')]],
      fileContent: [null, Validators.required],
      fileName: [null, [requiredFileType('pdf')]],
      creditNoteDate: [null, [Validators.required]],
      taxAmount: [null, [Validators.required]],
      taxlessTotal: [null, [Validators.required]],
      totalIncludingTax: [null, [Validators.required]],
    });
    this.invoiceTransmitForm.disable();
  }

  openModal(): void {
    const modalRef = this.uploadModal.open(UploadModalComponent, {
      width: '658px',
      data: {},
    });

    modalRef.afterClosed().subscribe((response) => {
      if (response) {
        this.onFileChange(response.file);
        this.proformaFile = response.preview;
      }
    });
  }

  private handleValidationError(error: any) {
    this.logger.error('An error occurred during invoice transmission:', error);
    if (!error.errors.supplierInvoicePostResource) {
      this.toastrService.error(this.translateService.instant('Invoice.ts.transmit_failure'));
      return;
    }

    const errorsArray = error.errors.supplierInvoicePostResource;
    if (errorsArray.find(element => element.message === 'CONTRACT_STATUS_EXPIRED')) {
      this.toastrService.error(this.translateService.instant('Invoice.error_message.CONTRACT_STATUS_EXPIRED'));
    }

    if (errorsArray.find(element => element.message === 'CONTRACT_STATUS_INVALID')) {
      this.toastrService.error(this.translateService.instant('Invoice.error_message.CONTRACT_STATUS_INVALID'));
    }

    if (errorsArray.find(element => element.message === 'INVALID_LEGAL_DOCUMENTS')) {
      this.toastrService.error(this.translateService.instant('Invoice.error_message.INVALID_LEGAL_DOCUMENTS'));
    }

    if (errorsArray.find(element => element.message === 'TAX_AMOUNT_INVALID')) {
      this.toastrService.error(this.translateService.instant('Invoice.error_message.TAX_AMOUNT_INVALID'));
    }

    if (errorsArray.find(element => element.message === 'TAXLESS_TOTAL_INVALID')) {
      this.toastrService.error(this.translateService.instant('Invoice.error_message.TAXLESS_TOTAL_INVALID'));
    }

    if (errorsArray.find(element => element.message === 'TOTAL_INCLUDING_TAX_INVALID')) {
      this.toastrService.error(this.translateService.instant('Invoice.error_message.TOTAL_INCLUDING_TAX_INVALID'));
    }
  }

  getInvoiceDate() {
    return this.invoice.date ? new Date(this.invoice.date) : null;
  }
}
