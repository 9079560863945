<div class="row">
  <div class="col-md-12">
    <h2 class="underline">{{"employees.edit.label"|translate}}</h2>
  </div>
</div>
<form [formGroup]="updateEmployeeForm" (ngSubmit)="updateEmployeeFormSubmit()" @slideInAnimation>

  <div class="row slide-in-animation">
    <div class="col-md-12 ">
      <div class="card box-shadow-hover">
        <div class="card-body">

          <div class="row">
            <div class="col-md-2">
              <app-update-employee-picture
                [canEdit]="canEdit()"
                [name]="employee?.firstName+' '+employee?.lastName"
              ></app-update-employee-picture>
              <hr>
              <ul class="list-group" *ngIf="account">
                <li class="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                  <span><i class="icons cui-wrench"></i>&nbsp;
                    {{"employees.edit.label_account"|translate}}
                    <span *ngIf="!account.activated" class="badge badge-pill" [ngClass]="{'badge-success': account.activated, 'badge-warning': !account.activated}">
                      {{'global.common.'+(account.activated ? 'active' : 'in-active')|translate}}
                    </span>

                    <span *ngIf="account.activated" class="badge badge-pill" [ngClass]="{'badge-success': account.enabled, 'badge-warning': !account.enabled}">
                      {{'employees.edit.'+(account.enabled ? 'enabled' :'disabled')| translate}}
                    </span>
                  </span>
                </li>

                <li *ngIf="employee && employee.authorities && employee.authorities[0]" class="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                  <span>
                    <fa-icon [icon]="faShieldHalved" class="pr-1"></fa-icon>
                    <span
                      class="badge badge-pill"
                      [ngClass]="{'badge-primary': employee.authorities[0].name==='SUPPLIER_ADMIN','badge-info': employee.authorities[0].name ==='SUPPLIER','badge-purple': employee.authorities[0].name==='PROVIDER'}"
                    >
                      {{ ('global.role.' + employee.authorities[0].name.toLowerCase()) | translate }}
                    </span>
                  </span>
                </li>
              </ul>
            </div>

            <div class="col-md-10">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="firstName">{{"employees.creation.first_name.label"|translate}} <span class="text-primary">*</span></label>
                    <input type="text" class="form-control" id="firstName" formControlName="firstName">
                    <app-form-feedback
                      *ngIf="updateEmployeeForm.get('firstName').errors?.required && (updateEmployeeForm.get('firstName').dirty || updateEmployeeForm.get('firstName').touched)"
                      [message]="'employees.creation.first_name.feedback.required'|translate"
                    ></app-form-feedback>
                    <app-form-feedback
                      *ngIf="updateEmployeeForm.get('firstName').errors?.pattern && (updateEmployeeForm.get('firstName').dirty || updateEmployeeForm.get('firstName').touched)"
                      [message]="'employees.creation.first_name.feedback.characterset'|translate"
                    ></app-form-feedback>
                    <app-form-feedback
                      *ngIf="updateEmployeeForm.get('firstName').errors?.maxlength  && (updateEmployeeForm.get('firstName').dirty || updateEmployeeForm.get('firstName').touched)"
                      [message]="'employees.creation.first_name.feedback.maxlength'|translate"
                    ></app-form-feedback>
                  </div>

                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="lastName">{{'employees.creation.last_name.label'|translate}} <span class="text-primary">*</span></label>
                    <input type="text" class="form-control" id="lastName" formControlName="lastName">
                    <app-form-feedback
                      *ngIf="updateEmployeeForm.get('lastName').errors?.required && (updateEmployeeForm.get('lastName').dirty || updateEmployeeForm.get('lastName').touched)"
                      [message]="'employees.creation.last_name.feedback.required'|translate"
                    ></app-form-feedback>
                    <app-form-feedback
                      *ngIf="updateEmployeeForm.get('lastName').errors?.pattern && (updateEmployeeForm.get('lastName').dirty || updateEmployeeForm.get('lastName').touched)"
                      [message]="'employees.creation.last_name.feedback.characterset'|translate"
                    ></app-form-feedback>
                    <app-form-feedback
                      *ngIf="updateEmployeeForm.get('lastName').errors?.maxlength  && (updateEmployeeForm.get('lastName').dirty || updateEmployeeForm.get('lastName').touched)"
                      [message]="'employees.creation.last_name.feedback.maxlength'|translate"
                    ></app-form-feedback>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group ">
                    <label for="email">{{"employees.creation.email.label"|translate}} <span
                      class="text-primary">*</span></label>
                    <input type="text" class="form-control" id="email" formControlName="email" maxlength="100">
                    <app-form-feedback
                      *ngIf="updateEmployeeForm.get('email').errors?.required && (updateEmployeeForm.get('email').dirty || updateEmployeeForm.get('email').touched)"
                      [message]="'employees.creation.email.feedback.required'|translate"
                    ></app-form-feedback>
                    <app-form-feedback
                      *ngIf="updateEmployeeForm.get('email').errors?.pattern && (updateEmployeeForm.get('email').dirty || updateEmployeeForm.get('email').touched)"
                      [message]="'employees.creation.email.feedback.characterset'|translate"
                    ></app-form-feedback>
                    <app-form-feedback
                      *ngIf="updateEmployeeForm.get('email').errors?.maxlength  && (updateEmployeeForm.get('email').dirty || updateEmployeeForm.get('email').touched)"
                      [message]="'employees.creation.email.feedback.maxlength'|translate"
                    ></app-form-feedback>
                    <app-form-feedback
                      *ngIf="updateEmployeeForm.get('email').errors?.emailUsed"
                      [message]="'employees.creation.email.feedback.exists'|translate"
                    ></app-form-feedback>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group last-form-group">
                    <label for="phoneNumber">
                      {{"employees.creation.phoneNumber.label"|translate}} <span class="text-primary">*</span>
                    </label>
                    <ngx-intl-tel-input
                      id="phoneNumber"
                      [cssClass]="'form-control'"
                      [preferredCountries]="preferredCountries"
                      [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[searchCountryField.Iso2, searchCountryField.Name]"
                      [selectFirstCountry]="false"
                      [selectedCountryISO]="countryISO.France"
                      [maxLength]="15"
                      [phoneValidation]="true"
                      [separateDialCode]="false"
                      [id]="'phoneNumber'"
                      formControlName="phoneNumber">
                    </ngx-intl-tel-input>
                    <app-form-feedback
                      *ngIf="updateEmployeeForm.get('phoneNumber').errors?.required && updateEmployeeForm.get('phoneNumber').touched"
                      [message]="'employees.creation.phoneNumber.feedback.required'|translate"
                    ></app-form-feedback>
                    <app-form-feedback
                      *ngIf="updateEmployeeForm.get('phoneNumber').errors?.validatePhoneNumber && updateEmployeeForm.get('phoneNumber').touched"
                      [message]="'employees.creation.phoneNumber.feedback.characterset'|translate"
                    ></app-form-feedback>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group last-form-group">
                    <label for="title">{{"employees.creation.title_field.label"|translate}} </label>
                    <input type="text" class="form-control" id="title" formControlName="title">
                    <app-form-feedback
                      *ngIf="updateEmployeeForm.get('title').errors?.required && (updateEmployeeForm.get('title').dirty || updateEmployeeForm.get('title').touched)"
                      [message]="'employees.creation.title_field.feedback.required'|translate"
                    ></app-form-feedback>
                    <app-form-feedback
                      *ngIf="updateEmployeeForm.get('title').errors?.pattern && (updateEmployeeForm.get('title').dirty || updateEmployeeForm.get('title').touched)"
                      [message]="'employees.creation.title_field.feedback.characterset'|translate"
                    ></app-form-feedback>
                    <app-form-feedback
                      *ngIf="updateEmployeeForm.get('title').errors?.maxlength  && (updateEmployeeForm.get('title').dirty || updateEmployeeForm.get('title').touched)"
                      [message]="'employees.creation.title_field.feedback.maxlength'|translate"
                    ></app-form-feedback>
                  </div>
                </div>

                <div class="col-md-6" *ngIf="isProvider">
                  <div class="form-group last-form-group">
                    <label for="suppliers">
                      {{'employees.creation.suppliers.label'|translate}} <span class="text-primary">*</span>
                    </label>
                    <ng-select
                      id="suppliers"
                      [items]="suppliersList"
                      [multiple]="true"
                      [searchable]="true"
                      [hideSelected]="true"
                      [typeahead]="suppliersInput$"
                      [compareWith]="compareSupplier"
                      [placeholder]="'employees.creation.suppliers.input-placeholder'|translate"
                      [typeToSearchText]="'employees.creation.suppliers.input-search-text'|translate"
                      bindLabel="name"
                      formControlName="suppliers"
                      class="ng-select-custom w-100"
                    >
                      <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                   let-index="index">
                        <input id="item-{{index.id}}" type="checkbox" [checked]="item$.selected"/>
                        <span class="ml-1" [title]="item.name">{{item.firstName + ' '
                        + item.lastName}}</span>
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items | slice:0:5">
                          <span
                            class="ng-value-label" [class.item-disabled]="item.disabled"
                            [title]="item.name">
                            {{item.firstName + ' ' + item.lastName | ellipsis: 18}}
                          </span>
                        </div>
                        <div class="ng-value" *ngIf="items.length > 5">
                          <span class="ng-value-label">{{items.length - 5}} {{'employees.creation.suppliers.input-items-length-exceeded'|translate}}</span>
                        </div>
                      </ng-template>
                    </ng-select>
                    <app-form-feedback
                      *ngIf="updateEmployeeForm.get('suppliers').errors?.required && (updateEmployeeForm.get('suppliers').dirty || updateEmployeeForm.get('suppliers').touched)"
                      [message]="'global.feedback.required'|translate"
                    ></app-form-feedback>
                    <app-form-feedback
                      *ngIf="updateEmployeeForm.get('suppliers').errors?.maxlength && (updateEmployeeForm.get('suppliers').dirty || updateEmployeeForm.get('suppliers').touched)"
                      [message]="'employees.creation.suppliers.feedback.maxLength'|translate"
                    ></app-form-feedback>
                    <app-form-feedback
                      *ngIf="updateEmployeeForm.get('suppliers').errors?.minlength && (updateEmployeeForm.get('suppliers').dirty || updateEmployeeForm.get('suppliers').touched)"
                      [message]="'employees.creation.suppliers.feedback.maxLength'|translate"
                    ></app-form-feedback>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="isProvider">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="providerType">
                      {{'global.common.type'|translate}} <span class="text-primary">*</span>
                    </label>
                    <div id="providerType">
                      <div *ngFor="let type of providerTypes" class="custom-control custom-radio custom-control-inline cursor-pointer">
                        <input class="custom-control-input" type="radio" [id]="type" [value]="type" name="providerType" formControlName="providerType">
                        <label class="custom-control-label" [for]="type">
                          {{ 'employees.creation.provider.type.' + type | translate }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row slide-in-animation" *ngIf="canEdit() || isProvider">
    <div class="col-md-12">
      <button type="submit" class="btn btn-primary float-right" [disabled]="updateEmployeeForm.invalid">
        {{'employees.edit.update_button'|translate}}
      </button>
    </div>
  </div>
</form>


