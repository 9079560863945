import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {
  PurchaseOrderAdvancedSearch
} from '../../../shared/models/purchase-order/purchase-order-advanced-search.model';
import {PurchaseOrderModel} from '../../../shared/models/purchase-order/purchase-order.model';
import {PurchaseOrderStatusEnum} from '../../../shared/enums/Purchase-order.status.enum';
import {commonProperties} from '../../../../assets/environments/environment.common';
import {environment} from '../../../../assets/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {
  constructor(private httpClient: HttpClient) {
  }

  mapAdvancedSearchForm(params: any): PurchaseOrderAdvancedSearch {
    return new PurchaseOrderAdvancedSearch(params);
  }

  getPurchaseOrdersByPage(page: number, size: number, sort: string, search: string, advancedSearch: PurchaseOrderAdvancedSearch): Observable<ListContentModel> {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    } else if (advancedSearch) {
      if (advancedSearch.buyerName) {
        params = params.set('buyerName', advancedSearch.buyerName);
      }
      if (advancedSearch.purchaseOrderNumber) {
        params = params.set('purchaseOrderNumber', advancedSearch.purchaseOrderNumber);
      }
      if (advancedSearch.requestNumber) {
        params = params.set('requestNumber', advancedSearch.requestNumber);
      }
      if (advancedSearch.status) {
        params = params.set('status', advancedSearch.status);
      }
    }

    params = params.set('size', size.toString()).set('page', page.toString()).set('sort', sort);
    return this.httpClient.get<ListContentModel>(environment.api_root + commonProperties.purchaseOrdersList, {params});
  }

  getPurchaseOrderById(purchaseOrderId: string): Observable<PurchaseOrderModel> {
    return this.httpClient.get<PurchaseOrderModel>(environment.api_root + commonProperties.purchaseOrderById.replace(':purchaseOrderId', purchaseOrderId));
  }

  rejectPurchaseOrderById(purchaseOrderId: string, body: any): Observable<any> {
    return this.httpClient.patch(environment.api_root + commonProperties.purchaseOrderActions.replace(':purchaseOrderId', purchaseOrderId), body);
  }

  uploadPurchaseOrderSignedById(purchaseOrderId: string, formValue: any): Observable<any> {
    return this.httpClient.post(environment.api_root + commonProperties.purchaseOrderSignedById.replace(':purchaseOrderId', purchaseOrderId),
      {
        fileContent: formValue.attachmentFile,
        fileName: formValue.attachmentFileName
      });
  }

  deletePurchaseOrderSignedById(purchaseOrderId: string): Observable<any> {
    return this.httpClient.delete(environment.api_root + commonProperties.purchaseOrderSignedById.replace(':purchaseOrderId', purchaseOrderId));
  }


  getPurchaseOrderFile(purchaseOrderId: any, status: string) {
    const headers = new HttpHeaders().set('Accept', 'application/pdf');
    const URL = status === PurchaseOrderStatusEnum.VALIDATED ||
    status === PurchaseOrderStatusEnum.EXPIRED ? environment.api_root + commonProperties.purchaseOrderSignedById.replace(':purchaseOrderId', purchaseOrderId)
      : environment.api_root + commonProperties.purchaseOrderFileById.replace(':purchaseOrderId', purchaseOrderId);
    return this.httpClient.get<Blob>(URL, {
      observe: 'response',
      headers: headers,
      responseType: 'blob' as 'json'
    });
  }

  handlePurchaseOrderFileDownload(res: any, purchaseOrderNumber: string) {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(res.body);
    a.download = 'PURCHASE_ORDER_' + purchaseOrderNumber;
    a.click();
    a.remove();
  }

  getPurchaseOrdersWaiting(): Observable<any> {
    return this.httpClient.get(environment.api_root + commonProperties.purchaseOrdersWaiting);
  }

  getPurchaseOrdersWaitingForRequest(requestId): Observable<any> {
    return this.httpClient.get(environment.api_root + 'purchaseOrderMgtApi/purchase-orders/request/' + requestId + '/waiting');
  }

}
