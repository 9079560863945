<div class="box box-shadow-hover">
  <div class="mx-4 py-4" @slideIn>
    <div class="d-flex">
      <div class="missions-header-border mr-2"></div>
      <div class="missions-header">
        {{ "Mission_view.billing.billing" | translate }}
      </div>
    </div>

    <div class="mt-3">
      <div class="row">
        <div class="col">
          <isp-info-line [icon]="faFileContract" [color]="'primary'">
            <div info-header>{{'Mission_view.billing.contract'|translate}}</div>
            <div info-subheader>{{'Invoice.mode.'+billing.contractMode | translate}}</div>
          </isp-info-line>
        </div>

        <div *ngIf="billing.contractMode === 'T_M'" class="col">
          <isp-info-line [icon]="faHourglassHalf" [color]="'success'">
            <div info-header>{{'Mission_view.billing.measure_unit'|translate}}</div>
            <div info-subheader>{{'global.common.'+(billing.quantityByDate ? 'days' : 'hours') | translate}}</div>
          </isp-info-line>
        </div>

        <div class="col">
          <isp-info-line [icon]="faUserCheck" [color]="'purple'">
            <div info-header>{{'Mission_view.billing.client_validation'|translate}}</div>
            <div info-subheader>{{'global.common.'+(billing.clientValidation ? 'yes' : 'no')| translate}}</div>
          </isp-info-line>
        </div>

        <ng-container *ngIf="billing.contractMode === 'FIXED_PRICE'||billing.contractMode === 'BILL_ONLY'">
          <div class="col">
            <isp-info-line [icon]="faCalendarWeek" [color]="'primary'">
              <div info-header>{{'Mission_view.billing.due_payment'|translate}}</div>
              <div info-subheader>{{'Mission_view.billing.'+(billing.duePaymentByDate | booleanFormat: ["date", "event"]) |translate}}</div>
            </isp-info-line>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
