import {Component, EventEmitter, Input, Output} from '@angular/core';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {faCalendar, faClock, faMoneyBill1} from '@fortawesome/free-regular-svg-icons';
import {faFile, faIndustry, faMapMarker} from '@fortawesome/free-solid-svg-icons';
import {TranslateService} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {
  CallForTenderOfferListItemModel,
} from '../../../../shared/models/call-for-tender/call-for-tender-offer-list-item.model';
import {ActivityAreaModel} from '../../../company-config/activity-areas/activity-area.model';
import {CallForTenderService} from '../../services/call-for-tender.service';

marker('Call_for_tender_offer_list.statuses.SENT');
marker('Call_for_tender_offer_list.statuses.PUBLISHED');
marker('Call_for_tender_offer_list.statuses.SELECTED');
marker('Call_for_tender_offer_list.statuses.REPLIED');
marker('Call_for_tender_offer_list.statuses.REJECTED');
marker('Call_for_tender_offer_list.statuses.REJECTED_BY_SA');
marker('Call_for_tender_offer_list.statuses.CLOSED_BY_SUPPLIER');
marker('Call_for_tender_offer_list.statuses.ACCEPTED');
marker('Call_for_tender_offer_list.statuses.CANCELLED');

@Component({
  selector: 'app-call-for-tender-offers-list-card',
  templateUrl: './call-for-tender-offers-list-card.component.html',
  styleUrls: ['./call-for-tender-offers-list-card.component.scss']
})
export class CallForTenderOffersListCardComponent {
  protected readonly faMoneyBill1 = faMoneyBill1;
  protected readonly faFile = faFile;
  protected readonly faIndustry = faIndustry;
  protected readonly faCalendar = faCalendar;
  protected readonly faClock = faClock;
  protected readonly faMapMarker = faMapMarker;

  @Input() activityAreas: ActivityAreaModel[];
  @Input() callForTenderOffer: CallForTenderOfferListItemModel;
  @Output() callForTenderOfferDetail = new EventEmitter<string>();
  @Output() callForTenderOfferTimeSlot = new EventEmitter<string[]>();
  @Output() callForTenderOfferNegotiation = new EventEmitter<string[]>();
  page: number = 0;

  constructor(private callForTenderService: CallForTenderService,
              private toastrService: ToastrService,
              private translateService: TranslateService,
              private logger: NGXLogger, ) {
  }

  deleteOffer(): void {
    this.callForTenderOfferDetail.emit(this.callForTenderOffer.id);
  }

  showTimeSlotModal() {
    this.callForTenderOfferTimeSlot.emit([this.callForTenderOffer.id, this.callForTenderOffer.callForTenderId]);
  }

  showNegotiationModal() {
    this.callForTenderOfferNegotiation.emit([this.callForTenderOffer.id, this.callForTenderOffer.callForTenderId]);
  }

  getActivityAreas(cft: CallForTenderOfferListItemModel) {
    if (!cft?.callForTender?.activityAreaIds?.length) {
      return '';
    }

    return cft.callForTender.activityAreaIds
      .map(id => this.activityAreas.find(area => area.id === id)?.label)
      .filter(label => label) // Remove undefined values
      .join(', ');
  }
}
