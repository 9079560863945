<div class="row">
  <div class="col-md-12">
    <div class="header">{{ "CreditNote.credit_note" | translate }}</div>
  </div>
</div>

<ng-container *ngIf="!firstCallDone; else firstCallDoneBlock">
  <app-spinner

    [backgroundTransparent]="true"
  ></app-spinner>
</ng-container>

<ng-template #firstCallDoneBlock>
  <div *ngIf="!isListEmpty; else noCompanyBlock" @slideInStagger>
    <!-- basic search and advanced search forms -->
    <div class="row mb-3 slide-in-animation">
      <div
        class="col"
        [ngClass]="{
          'col-md-5': !isAdvancedSearchDisplayed,
          'col-md-10': isAdvancedSearchDisplayed
        }"
      >
        <div
          class="text-right"
          [ngClass]="{ 'offset-advanced-search': isAdvancedSearchDisplayed }"
        >
          <button
            class="advanced-search-button"
            (click)="toggleAdvancedSearch()"
          >
            <u>{{"CreditNote.advanced_search"|translate}}</u>&nbsp;
            <i
              class="fa ml-1"
              [ngClass]="{
                'fa-angle-down': isAdvancedSearchDisplayed,
                'fa-angle-left': !isAdvancedSearchDisplayed
              }"
              aria-hidden="true"
            ></i>
          </button>
        </div>

        <form
          [formGroup]="advancedSearchForm"
          (ngSubmit)="advancedSearchFormSubmit()"
          *ngIf="isAdvancedSearchDisplayed; else basicSearchBlock"
          @fadeIn
        >
          <div class="row">
            <div class="col form-group">
              <label class="mb-1"
                     for="credit-note-number-input">{{"CreditNote.search_fields.credit_note"|translate}}</label
              >
              <input
                type="text"
                class="form-control"
                id="credit-note-number-input"
                formControlName="number"
              />
            </div>
            <div class="col form-group">
              <label class="mb-1"
                     for="invoice-number-input">{{"CreditNote.search_fields.invoice_number"|translate}}</label>
              <input
                type="text"
                class="form-control"
                id="invoice-number-input"
                formControlName="linkedNumber"
              />
            </div>
            <div class="col form-group">
              <label class="mb-1"
                     for="purchase-order-number-input">{{"CreditNote.search_fields.po_number"|translate}}</label>
              <input
                type="text"
                class="form-control"
                id="purchase-order-number-input"
                formControlName="purchaseOrderNumber"
              />
            </div>
            <div class="col form-group">
              <label class="mb-1"
                     for="buyer-name-input">{{"CreditNote.search_fields.buyer"|translate}}</label>
              <input
                type="text"
                class="form-control"
                id="buyer-name-input"
                formControlName="buyerName"
              />
            </div>
            <!-- div class="col form-group">
              <label class="mb-1" for="supplier-name-input">Supplier</label>
              <input type="text" class="form-control" id="supplier-name-input"
                     formControlName="supplierName">
            </div -->
            <div class="col form-group">
              <label class="mb-1"
                     for="status-input">{{"CreditNote.search_fields.status"|translate}}</label>
              <select
                class="custom-select"
                id="status-input"
                formControlName="status"
              >
                <option selected></option>
                <option [value]="status" *ngFor="let status of status">
                  {{ 'Invoice.status.'+status | translate }}
                </option>
              </select>
            </div>
            <div class="col d-flex align-items-end pl-0">
              <button
                class="btn btn-outline-primary px-5 mb-3"
                type="submit"
                [disabled]="advancedSearchForm.pristine"
              >
                {{"CreditNote.button_search"|translate}}
              </button>
            </div>
          </div>
        </form>

        <ng-template #basicSearchBlock>
          <!--          <div class="form-group basic-search-group" @fadeIn>-->
          <div class="input-group search-input-group">
            <label for="search"></label>
            <input
              type="text"
              class="form-control search-input border-right-0 border"
              placeholder="{{ 'global.common.search_ellipsis' | translate }}"
              id="search"
              [value]="basicSearch"
              (input)="triggerBasicSearch($event.target.value)"
            />
            <span class="input-group-append">
              <div class="input-group-text bg-transparent">
                <fa-icon [icon]="faSearch"></fa-icon>
              </div>
            </span>
          </div>
        </ng-template>
      </div>
    </div>

    <!-- line for page size and message of number of elements of the page -->
    <div
      class="row justify-content-between align-items-center mb-3 slide-in-animation"
    >
      <div class="col-md-3 form-inline">
        <select
          name="result"
          id="select-result"
          class="custom-select mr-2"
          [(ngModel)]="size"
          (change)="changeSize()"
        >
          <option [value]="5">5</option>
          <option [value]="10">10</option>
          <option [value]="20">20</option>
        </select>

        <label for="select-result"
        >&nbsp; {{ "global.pages.results_per_page" | translate }}</label
        >
      </div>
      <div class="col-md-2">
        <span class="float-right"
        >{{ "global.pages.showing" | translate }}
          <strong
          >{{ numberOfElements }} {{ "global.pages.of" | translate }}
            {{ totalElements }}</strong
          >
          {{ "global.pages.entries" | translate }}</span
        >
      </div>
    </div>

    <div
      *ngIf="listContent.length > 0; else noResultCompanyBlock"
      @slideInStagger
    >
      <div class="row slide-in-animation">
        <div class="col-md-12">
          <table
            class="table table-material box-shadow-hover table-responsive-sm"
          >
            <thead class="thead-normal">
            <tr class="text-center">
              <th
                scope="col"
                class="cursor-pointer"
                (click)="sortColumn('number')"
                title="Sort number"
              >
                {{ "CreditNote.credit_note_number" | translate }}
                <i *ngIf="sort !== 'number'" class="fa fa-sort"></i>
                <i
                  *ngIf="sort === 'number' && sortDirection === 'asc'"
                  class="fa fa-sort-asc"
                ></i>
                <i
                  *ngIf="sort === 'number' && sortDirection === 'desc'"
                  class="fa fa-sort-desc"
                ></i>
              </th>
              <th>
                {{ "CreditNote.invoice_number" | translate }}
              </th>
              <th>
                {{ "CreditNote.buyerName" | translate }}
              </th>
              <th
                scope="col"
                class="cursor-pointer"
                (click)="sortColumn('status')"
                title="Sort status"
              >
                {{ "CreditNote.status" | translate }}
                <i *ngIf="sort !== 'status'" class="fa fa-sort"></i>
                <i
                  *ngIf="sort === 'status' && sortDirection === 'asc'"
                  class="fa fa-sort-asc"
                ></i>
                <i
                  *ngIf="sort === 'status' && sortDirection === 'desc'"
                  class="fa fa-sort-desc"
                ></i>
              </th>
              <th
                scope="col"
                class="cursor-pointer"
                (click)="sortColumn('date')"
                title="Sort date"
              >
                {{ "CreditNote.date" | translate }}
                <i *ngIf="sort !== 'date'" class="fa fa-sort"></i>
                <i
                  *ngIf="sort === 'date' && sortDirection === 'asc'"
                  class="fa fa-sort-asc"
                ></i>
                <i
                  *ngIf="sort === 'date' && sortDirection === 'desc'"
                  class="fa fa-sort-desc"
                ></i>
              </th>
              <th>
                {{ "CreditNote.period" | translate }}
              </th>
              <th>
                {{ "CreditNote.provider" | translate }}
              </th>
              <th scope="col">{{ "CreditNote.actions" | translate }}</th>
            </tr>
            </thead>
            <tbody>
            <tr class="text-center" *ngFor="let creditNote of listContent">
              <td>
                {{ creditNote.number }}
                <div class="small text-muted">
                  <span>{{ "CreditNote.po_n" | translate }}</span> |
                  {{ creditNote.purchaseOrders?.join(', ') }}
                </div>
              </td>
              <td>
                {{ creditNote.linkedInvoices.join(', ') }}
              </td>
              <td>{{ creditNote.buyers?.join(', ') }}</td>
              <td>
                  <span
                    class="badge"
                    [ngClass]="{
                      'badge-info': creditNote.status === InvoiceStatusEnum.WAITING,
                      'badge-primary': creditNote.status === InvoiceStatusEnum.REFUSED,
                      'badge-success': creditNote.status === InvoiceStatusEnum.VALIDATED
                    }"
                  >
                    {{ creditNote.status }}
                  </span>
              </td>
              <td>{{ creditNote.date | date: "dd/MM/yyyy" }}</td>
              <td>
                <ng-container
                  *ngFor="let period of creditNote.periods">{{ period | localizedDate: "MMM YYYY" | default: ("global.common.not-set"|translate) }}
                </ng-container>
              </td>
              <td>{{ creditNote.employees?.join(', ') | default: ("global.common.not-set"|translate) }}</td>

              <td class="d-flex flex-row justify-content-around">
                <isp-button
                  class="px-1"
                  *ngIf="creditNote.status == 'VALIDATED'"
                  [type]="ButtonTypeEnum.ICON"
                  [tooltip]="'CreditNote.download_file' | translate"
                  [icon]="faDownload"
                  [colour]="IspColoursEnum.primary"
                  (trigger)="downloadInvoice(creditNote)"
                  [size]="SizeEnum.xs"
                ></isp-button>
                <isp-button
                  class="px-1"
                  *ngIf="creditNote.status == 'REFUSED' && creditNote.moderationReason"
                  [type]="ButtonTypeEnum.ICON"
                  [tooltip]="creditNote.moderationReason"
                  [icon]="faCommentCaptions"
                  [colour]="IspColoursEnum.secondary"
                  (trigger)="{}"
                  [size]="SizeEnum.xs"
                ></isp-button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- pagination -->
      <div class="row slide-in-animation" *ngIf="totalElements > size">
        <div class="col-md-12">
          <pagination
            class="float-right"
            [totalItems]="totalElements"
            [maxSize]="5"
            [boundaryLinks]="true"
            [ngModel]="page"
            (pageChanged)="changePage($event)"
            [itemsPerPage]="size"
            previousText="&lsaquo;"
            nextText="&rsaquo;"
            firstText="&laquo;"
            lastText="&raquo;"
          >
          </pagination>
        </div>
      </div>
    </div>

    <ng-template #noResultCompanyBlock>
      <div class="text-center my-3" @slideIn>
        <img
          src="assets/img/result_empty.svg"
          alt="No results"
          style="width: 30rem"
        />
        <h5 class="font-weight-bold mt-3">
          {{ "CreditNote.no_data" | translate }}
        </h5>
      </div>
    </ng-template>
  </div>

  <ng-template #noCompanyBlock>
    <app-no-content
      class="company-list-empty"
      content="{{ 'CreditNote.no_credit_notes_yet' | translate }}"
      iconClassName="fa fa-money"
      @slideIn
    >
    </app-no-content>
  </ng-template>
</ng-template>
