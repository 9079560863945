import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateModule} from '@ngx-translate/core';
import {AlertModule} from 'ngx-bootstrap/alert';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {SharedModule} from '../../shared/shared.module';
import {CreateOnCallModalComponent} from './create-on-call-modal/create-on-call-modal.component';
import {OnCallListComponent} from './on-call-list/on-call-list.component';
import {OnCallRoutingModule} from './on-call-routing.module';

@NgModule({
  declarations: [
    OnCallListComponent,
    CreateOnCallModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    OnCallRoutingModule,
    TranslateModule,
    ReactiveFormsModule,
    TimepickerModule,
    FormsModule,
    PaginationModule,
    AlertModule,
    MatTooltipModule,
    BsDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule
  ]
})
export class OnCallModule { }
