export class InvoiceAdvancedSearchModel {
  number: string;
  purchaseOrderNumber: string;
  linkedNumber: string;
  buyerName: string;
  supplierName: string;
  status: string;
  activityType: string;
  mode: string;
  contractMode: string;
  credited: boolean;
  types: string[];
  constructor(object?: any) {
    if (object) {
      this.number = object.number;
      this.types = object.types;
      this.purchaseOrderNumber = object.purchaseOrderNumber;
      this.linkedNumber = object.linkedNumber;
      this.buyerName = object.buyerName;
      this.supplierName = object.supplierName;
      this.status = object.status;
      this.activityType = object.activityType;
      this.mode = object.mode;
      this.contractMode = object.contractMode;
      this.credited = object.credited;
    }
  }
}
