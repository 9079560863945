export class ActivitySheetModel {
  id: number;
  accountingMonth: string;
  activityFileName: string;
  allowedQuantity: number;
  allowedQuantityPerDay: number;
  comment: string;
  empty: string;
  employeeName: string;
  endDate: string;
  entryList: EntryModel[];
  projectName: string;
  purchaseOrderNumber: string;
  refusalComment: string;
  startDate: string;
  status: string;
  supplierCompanyName: string;
  buyerCompanyName: string;
  type: string;
  summary: string;
  sum: number;
  submissionDate: string;
  createdDate: string;
  toModerate: boolean;
  fillColour: string;
  requestId: string;
}

export class EntryModel {
  id: number;
  date: string;
  quantity: number;
}

export enum StatusEnum {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  VALIDATED = 'VALIDATED',
  REFUSED = 'REFUSED',
  CANCELLED = 'CANCELLED'
}

export class AccountingMonthSummaryModel {
  accountingMonth: string;
  filled: number;
  total: number;
}

export enum ActionEnum {
  POST = 'POST',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  CLOSE = 'CLOSE'
}
