<div class="box box-shadow-hover my-4">
  <div class="mx-4 py-4">

    <div class="d-flex">
      <div class="missions-header-border mr-2"></div>
      <div class="missions-header">
        {{ "Mission_view.project.project_title"| translate }} : {{ project?.projectName | titlecase }}
      </div>
    </div>

    <div class="d-flex mt-3">
      <div class="mr-2">
        <ngx-avatars
          [name]="project.projectManagers[0]?.firstName +' '+ project.projectManagers[0]?.lastName"
          bgColor="#A83C1B88"
          fgColor="#fff"
          size="50" textSizeRatio="2"
          initialsSize="2"
        ></ngx-avatars>
      </div>

      <div class="d-flex flex-column">
        <div class="mission-details-name">
          {{ project.projectManagers[0]?.firstName | titlecase }}
          {{ project.projectManagers[0]?.lastName | uppercase }}
        </div>
        <div class="mission-details-email">
          {{ project.projectManagers[0]?.email }}
        </div>
      </div>
    </div>
  </div>
</div>
