import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss']
})
export class CurrencyInputComponent {
  @Input() formGroup: FormGroup;
  @Input() placeholder: string;
  @Input() currency: string;
  @Input() inputName: string | number | null;


  getCurrencyLabelPosition(): 'prepend' | 'append' {
    switch (this.currency) {
      case 'USD':
      case 'CAD':
      case 'JPY':
      case 'YEN':
        return 'prepend';
      case 'EUR':
      default:
        return 'append';
    }
  }
}
