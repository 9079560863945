import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CountryISO, SearchCountryField} from 'ngx-intl-tel-input-gg';
import {regex} from '../shared/regex/form.regex';
import {AuthenticationService} from '../core/services/authentication.service';
import {TranslatorService} from '../core/services/translator.service';
import {SignupEnum} from '../shared/enums/Signup.enum';
import {TranslateService} from '@ngx-translate/core';
import {ButtonActionEnum, ButtonTypeEnum, IspColoursEnum, LocationService} from '@i-supplier/angular-shared-module';
import {ToastrService} from 'ngx-toastr';
import {MyCompanyService} from '../views/company-config/services/my-company.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent {
  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly ButtonActionEnum = ButtonActionEnum;
  protected readonly IspColoursEnum = IspColoursEnum;
  signupForm: FormGroup;
  SUPPLIER_TYPE = SignupEnum.SUPPLIER;
  FREELANCE_TYPE = SignupEnum.FREELANCE;
  // phone number input variables
  preferredCountries: CountryISO[] = [CountryISO.France];
  searchCountryField = SearchCountryField;
  countryISO = CountryISO;
  loaders: { [key: string]: boolean } = {};
  headerMsg: string;
  bodyMsg: string;
  endMsg: string;

  // languages
  languages: any;
  selectedLanguage: any;

  constructor(
    private _formBuilder: FormBuilder,
    private translatorService: TranslatorService,
    private translateService: TranslateService,
    private toastrService: ToastrService,
    private authenticationService: AuthenticationService,
    private locationService: LocationService,
    private companyService: MyCompanyService
  ) {
    this.loaders['isSuccess'] = false;
    this.signupForm = this._formBuilder.group({
      name: [null, [Validators.required, Validators.pattern(regex.companyName), Validators.maxLength(75)]],
      type: [this.FREELANCE_TYPE, [Validators.required]],
      origin: [null, [Validators.required]],
      incorporationNumber: [null, [Validators.required, Validators.pattern(regex.incorporationNumber), Validators.maxLength(14)]],
      hasDpo: [false, Validators.required],
      firstNameDpo: [null],
      lastNameDpo: [null],
      emailDpo: [null],
      administrativeManager: this._formBuilder.group({
        firstName: [null, [Validators.required, Validators.pattern(regex.firstName), Validators.maxLength(50)]],
        lastName: [null, [Validators.required, Validators.pattern(regex.lastName), Validators.maxLength(50)]],
        email: [null, [Validators.required, Validators.pattern(regex.email), Validators.maxLength(100)]],
        phoneNumber: [null, [Validators.required]]
      })
    });
    this.loaders['companySignup'] = false;
    this.languages = this.translatorService.languages;
    this.selectedLanguage = this.translatorService.getLanguage();
    this.changeLang(this.selectedLanguage);
    this.listeningToCreateSupplierFormChanges();

    this.locationService.getCountryCode().then((code) => this.companyService.getCountries().subscribe({
      next: (countries: any[]) => this.signupForm.get('origin').setValue(countries.find(item => item.alpha2Code === code))
    }));
  }

  private listeningToCreateSupplierFormChanges(): void {
    this.signupForm.get('hasDpo')?.valueChanges.subscribe(() => {
      this.updateDpoFieldsValidators();
    });
  }

  private updateDpoFieldsValidators(): void {
    const fields = [
      { controlName: 'firstNameDpo', validators: [Validators.required, Validators.pattern(regex.firstName), Validators.maxLength(50)] },
      { controlName: 'lastNameDpo', validators: [Validators.required, Validators.pattern(regex.lastName), Validators.maxLength(50)] },
      { controlName: 'emailDpo', validators: [Validators.required, Validators.pattern(regex.email), Validators.maxLength(100)] }
    ];
  
    const hasDpo = this.signupForm.get('hasDpo').value;
  
    fields.forEach(({ controlName, validators }) => {
      const control = this.signupForm.get(controlName);
      if (control) {
        hasDpo ? control.setValidators(validators) : control.clearValidators();
        control.updateValueAndValidity();
      }
    });
  }
  

  changeCompanyType(companyType: string) {
    this.signupForm.patchValue({'type': companyType});
  }

  changeLang(event: any) {
    this.translatorService.changeLang(event);
  }

  createCompanyFormSubmit(): void {
    this.loaders['companySignup'] = true;
    this.authenticationService.createCompany(this.signupForm.value).subscribe({
      next: () => {
        this.loaders['isSuccess'] = true;
        this.headerMsg = this.translateService.instant('Signup.success.account_created');
        this.bodyMsg = this.translateService.instant('Signup.success.creation_success_message');
        this.endMsg = this.translateService.instant('Signup.success.seconds');
      },
      error: () => {
        this.loaders['companySignup'] = false;
        this.toastrService.error(this.translateService.instant('Signup.error'));
      }
    });
  }
}
