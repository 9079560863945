import {IbanTypeEnum} from "../../enums/Iban-type.enum";

export class AddressModel {
  street1: string;
  street2: string;
  postCode: string;
  stateOrProvince: string;
  city: string;
  country: string;

  constructor(object?: any) {
    if (object) {
      this.street1 = object.street1;
      this.street2 = object.street2;
      this.postCode = object.postCode;
      this.stateOrProvince = object.stateOrProvince;
      this.city = object.city;
      this.country = object.country;
    }
  }
}

export class BankModel {
  bankName: string;
  iban: string;
  bicOrSwift: string;
  ibanType: IbanTypeEnum;

  constructor(object?: any) {
    if (object) {
      this.bankName = object.bankName;
      this.iban = object.iban;
      this.bicOrSwift = object.bicOrSwift;
      this.ibanType = object.ibanType;
    }
  }
}

export class LegalInformationModel {
  id: string;
  name: string;
  origin: string;
  incorporationNumber: string;
  registrationCity: string;
  registrationNumber: string;
  shareCapital: string;
  vatNumber: string;
  legalForm: string;
  customLegalForm: string;
  phoneNumber: string;
  addressId: string;
  bank: BankModel = new BankModel();
  subjectToVat: boolean;
  country: string;
  hasDpo: boolean;
  firstNameDpo: string;
  lastNameDpo: string;
  emailDpo: string;

  constructor(object?: any) {
    if (object) {
      this.id = object.id;
      this.name = object.name;
      this.incorporationNumber = object.incorporationNumber;
      this.registrationNumber = object.registrationNumber;
      this.shareCapital = object.shareCapital;
      this.vatNumber = object.vatNumber;
      this.registrationCity = object.registrationCity;
      this.legalForm = object.legalForm;
      this.phoneNumber = object.phoneNumber;
      this.addressId = object.addressId;
      this.bank = new BankModel(object.bank);
      this.hasDpo = object.hasDpo;
      if (this.hasDpo) {
        this.firstNameDpo = object.firstNameDpo;
        this.lastNameDpo = object.lastNameDpo;
        this.emailDpo = object.emailDpo;
      }
    }
  }
}
