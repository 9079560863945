<ng-container *ngIf="!firstCallDone; else firstCallDoneBlock">
  <app-spinner  [backgroundTransparent]="true"></app-spinner>
</ng-container>

<ng-template #firstCallDoneBlock>
  <div *ngIf="!isListEmpty; else noMissionBlock" @slideInStagger>
    <div class="row slide-in-animation">
      <div class="col" [ngClass]="{'col-md-6': !isAdvancedSearchDisplayed, 'col-md-8': isAdvancedSearchDisplayed}">
        <div class="text-right" [ngClass]="{'offset-advanced-search': isAdvancedSearchDisplayed}">
          <button class="advanced-search-button" (click)="toggleAdvancedSearch()"><u>{{'Mission_list.advanced_search'|translate}}</u>
            <i class="fa ml-1"
               [ngClass]="{'fa-angle-down': isAdvancedSearchDisplayed, 'fa-angle-left': !isAdvancedSearchDisplayed}"
               aria-hidden="true"></i>
          </button>
        </div>
        <form [formGroup]="advancedSearchForm" (ngSubmit)="advancedSearchFormSubmit()"
              *ngIf="isAdvancedSearchDisplayed; else basicSearchBlock" @fadeIn>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label class="mb-1" for="mission-input">{{'Mission_list.mission_number'|translate}}</label>
                <input type="text" class="form-control" id="mission-input" formControlName="missionNumber">
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="mb-1" for="po-number-input">{{'Mission_list.po_number'|translate}}</label>
                <input type="text" class="form-control" id="po-number-input" formControlName="poNumber">
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="mb-1" for="buyer-input">{{'Mission_list.buyer'|translate}}</label>
                <input type="text" class="form-control" id="buyer-input" formControlName="buyer">
              </div>
            </div>
            <div class="col" *ngIf="userRole !== 'ROLE_FREELANCER'">
              <div class="form-group">
                <label class="mb-1" for="provider-input">{{'Mission_list.provider'|translate}}</label>
                <input type="text" class="form-control" id="provider-input" formControlName="provider">
              </div>
            </div>
            <div class="col d-flex align-items-end pl-0">
              <button class="btn btn-outline-primary px-5 mb-3" type="submit" [disabled]="advancedSearchForm.pristine">
                {{'Mission_list.search'|translate}}
              </button>
            </div>
          </div>
        </form>

        <ng-template #basicSearchBlock>
          <div class="form-group basic-search-group" @fadeIn>
            <input type="text" class="form-control" placeholder="{{'Mission_list.search'|translate}}..." [value]="basicSearch"
                   #basicSearchInput (input)="triggerBasicSearch(basicSearchInput.value)">
          </div>
        </ng-template>
      </div>
    </div>

    <!-- line for page size and message of number of elements of the page -->
    <div class="row justify-content-between align-items-center mb-3 slide-in-animation">
      <div class="col-md-3 form-inline">
        <select name="result" id="select-result" class="custom-select mr-2"
                [(ngModel)]="size" (change)="changeSize()">
          <option [value]="8">8</option>
          <option [value]="12">12</option>
          <option [value]="16">16</option>
        </select>
        <label for="select-result">&nbsp; {{'global.pages.results_per_page'|translate}}</label>
      </div>
      <div class="col-md-2">
        <span class="float-right" *ngIf="totalElements > 0">{{'Mission_list.showing'|translate}}
          <strong>{{numberOfElements}}
            {{'Mission_list.of'|translate}} {{totalElements}}</strong> {{'Mission_list.entries'|translate}}</span>
      </div>
    </div>

    <ng-container *ngIf="listContent.length > 0; else noResultRequestBlock">

      <div class="row">
        <div class="col-md-6 col-lg-4 col-xl-3 slide-in-animation" *ngFor="let mission of listContent">
          <mission-list-card [mission]="mission"></mission-list-card>
        </div>
      </div>

      <!-- pagination -->
      <div class="row slide-in-animation" *ngIf="totalElements > size">
        <div class="col-md-12">
          <pagination class="float-right" [totalItems]="totalElements" [maxSize]="5"
                      [boundaryLinks]="true"
                      [ngModel]="page"
                      (pageChanged)="changePage($event)"
                      [itemsPerPage]="size"
                      previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;"
                      lastText="&raquo;">
          </pagination>
        </div>
      </div>
    </ng-container>

    <ng-template #noResultRequestBlock>
      <div class="text-center my-3" @slideIn>
        <img src="assets/img/result_empty.svg" alt="No results" style="width: 30rem;">
        <h5 class="font-weight-bold mt-2">{{'Mission_list.no_result'|translate}} </h5>
      </div>
    </ng-template>
  </div>

  <ng-template #noMissionBlock>
    <app-no-content
      class="request-list-empty" @slideIn
      iconClassName="fa fa-folder-open-o"
      [content]="'Mission_list.no_mission'|translate">
    </app-no-content>
  </ng-template>
</ng-template>
