import {Injectable} from '@angular/core';
import {
  CallForTenderOffersListAdvancedSearchModel
} from '../../../shared/models/call-for-tender/call-for-tender-offers-list-advanced-search.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {combineLatest, forkJoin, Observable, of, switchMap} from 'rxjs';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {
  CallForTenderOfferListItemModel
} from '../../../shared/models/call-for-tender/call-for-tender-offer-list-item.model';
import {CallForTenderOfferModel} from '../../../shared/models/call-for-tender/call-for-tender-offer.model';
import * as moment from 'moment';
import {MeetingEmbeddable} from '../../../shared/models/call-for-tender/meeting-embeddable';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {RoleEnum} from '../../../shared/enums/CallForTenderOffers.enum';
import {commonProperties} from '../../../../assets/environments/environment.common';
import {environment} from '../../../../assets/environments/environment';
import {map} from 'rxjs/operators';
import {ActivityAreasService} from '../../company-config/services/activity-areas.service';
import {CallForTenderListItemModel} from "../../../shared/models/call-for-tender/call-for-tender-list-item.model";


@Injectable({
  providedIn: 'root'
})
export class CallForTenderOffersService {

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private activityAreasService: ActivityAreasService
  ) {}

  mapAdvancedSearchForm(params: any): CallForTenderOffersListAdvancedSearchModel {
    return new CallForTenderOffersListAdvancedSearchModel(params);
  }

  mapFormValueToCftOfferResource(formValue: any): CallForTenderOfferModel {
    const resource = new CallForTenderOfferModel();
    resource.title = formValue.title;
    resource.providerId = (formValue.providerId) ? formValue.providerId : null;
    if (this.authenticationService.getRole() !== RoleEnum.FREELANCER) {
      resource.providerFirstName = (formValue.pointOfContact.firstName) ? formValue.pointOfContact.firstName : null;
      resource.providerLastName = (formValue.pointOfContact.lastName) ? formValue.pointOfContact.lastName : null;
      resource.providerEmail = (formValue.pointOfContact.email) ? formValue.pointOfContact.email : null;
    }
    resource.resumeFileName = formValue.attachmentFileName;
    resource.resumeFileContent = formValue.attachmentFile;
    resource.comment = formValue.comment;
    resource.callForTenderId = formValue.callForTenderId;
    resource.amount = (formValue.amount) ? formValue.amount : null;
    resource.dailyRate = (formValue.dailyRate) ? formValue.dailyRate : null;
    return resource;
  }

  getCallForTenderOffersList(page: number, sort: string, advancedSearch: CallForTenderOffersListAdvancedSearchModel): Observable<ListContentModel> {
    let params = new HttpParams();
    if (advancedSearch) {
      if (advancedSearch.reference) {
        params = params.set('reference', advancedSearch.reference);
      }
      if (advancedSearch.title) {
        params = params.set('title', advancedSearch.title);
      }
      if (advancedSearch.provider) {
        params = params.set('provider', advancedSearch.provider);
      }
      if (advancedSearch.status) {
        params = params.set('status', advancedSearch.status);
      }
      if (advancedSearch.buyer) {
        params = params.set('buyer', advancedSearch.buyer);
      }
    }

    params = params.set('size', '10').set('page', page.toString()).set('sort', sort);

    return this.httpClient.get<ListContentModel>(environment.api_root + commonProperties.callForTenderOffers, {params});

  }

  getCallForTenderOffersById(offerId: any): Observable<CallForTenderOfferListItemModel> {
    return this.httpClient.get<CallForTenderOfferListItemModel>(environment.api_root + commonProperties.callForTenderOfferById.replace(':offerId', offerId));
  }

  createCallForTenderOffer(offerCreationForm: any) {
    const offerCreationBody: CallForTenderOfferModel = this.mapFormValueToCftOfferResource(offerCreationForm);
    return this.httpClient.post(environment.api_root + commonProperties.createOffer, offerCreationBody);
  }

  updateOffer(offerUpdateForm: any, offerId: string) {
    const offerUpdateBody: CallForTenderOfferModel = this.mapFormValueToCftOfferResource(offerUpdateForm);
    return this.httpClient.patch(environment.api_root + commonProperties.callForTenderOfferById.replace(':offerId', offerId), offerUpdateBody);
  }

  deleteOffer(offerId: string) {
    return this.httpClient.delete(environment.api_root + commonProperties.callForTenderOfferById.replace(':offerId', offerId));
  }

  getCallForTenderOfferResume(callForTenderOfferId: string): Observable<Blob> {
    const url = environment.api_root + commonProperties.callForTenderOfferResume.replace(':offerId', callForTenderOfferId);
    return this.httpClient.get<Blob>(url, {responseType: 'blob' as 'json'});
  }

  mapMeetingsFormValueToArray(meetingsFormValue: any): MeetingEmbeddable[] {
    const meetings = [];
    Object.values(meetingsFormValue).forEach(meetingFormValue => {
      // @ts-ignore
      if (meetingFormValue.date != null) {
        let meetingEmbeddable: MeetingEmbeddable;
        // @ts-ignore
        const date = moment(meetingFormValue.date).format('YYYY-MM-DD');
        // @ts-ignore
        const time = meetingFormValue.time;
        // @ts-ignore
        const status = meetingFormValue.status;

        meetingEmbeddable = {
          schedule: date + ' ' + time + ':00',
          status: status
        };
        meetings.push(meetingEmbeddable);
      }
    });
    return meetings;
  }

  selectCallForTenderOffer(callForTenderOfferId: string, meetings: MeetingEmbeddable[]): Observable<CallForTenderOfferModel> {
    const url = environment.api_root + commonProperties.callsForTenderOfferActions.replace(':callForTenderOfferId', callForTenderOfferId);
    return this.httpClient.patch<CallForTenderOfferModel>(url, {meetings});
  }

  updateNegotiation(callForTenderOfferId: any, priceNegotiation: any, action: string) {
    let params = new HttpParams();
    params = params.set('priceNegotiation', priceNegotiation);

    return this.httpClient.patch<ListContentModel>(environment.api_root + commonProperties.updateOfferNegotiation.replace(':callForTenderOfferId', callForTenderOfferId),
      {action : action, priceNegotiation: priceNegotiation}
    );
  }
}
