<div class="card box-shadow-hover my-4">
  <div class="card-body">
    <div class="card-title">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <div class="missions-header-border mr-2"></div>
          <div>
            <div class="missions-header">
              {{ 'Mission_view.provider.purchase_order'|translate }}
            </div>
            <div
              *ngIf="request?.purchaseOrderId && request?.requestClientStepResource?.billing.contractMode !== ContractModeEnum.T_M"
              [innerHtml]="'Mission_view.purchase-order-detail.period'|translate:{startDate:poPeriod.earliestStartDate|localizedDate,endDate:poPeriod.earliestEndDate|localizedDate}"
            ></div>
            <div
              *ngIf="!request?.purchaseOrderId && purchaseOrder && request?.requestClientStepResource?.billing?.contractMode !== ContractModeEnum.T_M"
              [innerHtml]="'Mission_view.purchase-order-detail.period'|translate:{
                startDate:request?.requestClientStepResource?.billing?.initialRequest.startDate,
                endDate:request?.requestClientStepResource?.billing?.initialRequest.endDate
              }"
            ></div>
          </div>
        </div>
      </div>


    </div>

    <ng-container *ngIf="request">
      <ng-container
        *ngIf="(request.requestClientStepResource.billing.contractMode === ContractModeEnum.T_M) || getPoItemTotal(PurchaseOrderItemTypeEnum.QUANTITY)>0">
        <h5 class="my-3">{{ 'core.components.breadcrumb.activity-sheets'|translate }}</h5>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>{{ 'Mission_view.provider.name'|translate }}</th>
              <th>{{ 'Mission_view.provider.start_date'|translate }}</th>
              <th>{{ 'Mission_view.provider.end_date'|translate }}</th>
              <th>{{ 'Mission_view.provider.quantity'|translate }}</th>
              <th>{{ 'Mission_view.provider.daily_rate'|translate }}</th>
            </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of getPoItem(PurchaseOrderItemTypeEnum.QUANTITY)">
            <td>{{ getProviderInfo(item.providerId) }}</td>
            <td>{{ item.startDate|localizedDate:'shortDate' }}</td>
            <td>{{ item.endDate|localizedDate:'shortDate' }}</td>
            <td>{{ item.quantity }}</td>
            <td>{{ item.unitPrice|localizedCurrency:request.requestClientStepResource.billing.currency }}</td>
          </tr>
          </tbody>
        </table>
      </ng-container>

      <ng-container *ngIf="request.requestClientStepResource.billing.contractMode !== ContractModeEnum.T_M && getPoItemTotal(PurchaseOrderItemTypeEnum.AMOUNT)>0">
        <isp-info-line [icon]="faCalendarWeek" [color]="IspColoursEnum.tertiary">
          <div info-header>
            {{ 'core.components.breadcrumb.due-payments'|translate }} :
          </div>
          <div info-subheader>
            {{ getPoItemTotal(PurchaseOrderItemTypeEnum.AMOUNT)|localizedCurrency:request.requestClientStepResource.billing.currency }}
          </div>
        </isp-info-line>
      </ng-container>

      <ng-container *ngIf="getPoItemTotal(PurchaseOrderItemTypeEnum.FEES)>0">
        <isp-info-line [icon]="faMoneyBills" [color]="IspColoursEnum.success">
          <div info-header>
            {{ 'Mission_view.provider.fees'|translate }} :
          </div>
          <div info-subheader>
            {{ getPoItemTotal(PurchaseOrderItemTypeEnum.FEES)|localizedCurrency:request.requestClientStepResource.billing.currency }}
          </div>
        </isp-info-line>
      </ng-container>

      <ng-container *ngIf="getPoItemTotal(PurchaseOrderItemTypeEnum.ON_CALL)>0">
        <isp-info-line [icon]="faHeadset" [color]="IspColoursEnum.secondary">
          <div info-header>
            {{ 'Mission_view.provider.on_call'|translate }} :
          </div>
          <div info-subheader>
            {{ getPoItemTotal(PurchaseOrderItemTypeEnum.ON_CALL)|localizedCurrency:request.requestClientStepResource.billing.currency }}
          </div>
        </isp-info-line>
      </ng-container>
    </ng-container>
  </div>
</div>
