import {query, transition, trigger, useAnimation} from '@angular/animations';
import {HttpResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {faBan, faDownload, faFileCircleXmark, faFileInvoice, faSearch} from '@fortawesome/pro-duotone-svg-icons';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {fadeInAnimation, slideIn50StaggerAnimation, slideInAnimation} from '../../../shared/animation/common.animation';
import {ListContentComponent} from '../../../shared/components/list-content/list-content.component';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {regex} from '../../../shared/regex/form.regex';
import {ActivityTypeEnum} from '../models/activity-type.enum';
import {ContractModeEnum} from '../models/contract-mode.enum';
import {InvoiceModeEnum} from '../models/invoice-mode.enum';
import {InvoiceStatusEnum} from '../models/invoice-status.enum';
import {InvoicingModel} from '../models/InvoicingModel';
import {InvoicingStatusEnum} from '../models/InvoicingStatusEnum';
import {InvoiceService} from '../services/invoice.service';

@Component({
  selector: 'invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ]),
    trigger('slideIn', [
      transition(':enter', [
        useAnimation(slideInAnimation)
      ])
    ]),
    trigger('slideInStagger', [
      transition(':enter', [
        query('.slide-in-animation', [
          useAnimation(slideIn50StaggerAnimation)
        ], {optional: true})
      ])
    ])
  ]
})
export class InvoiceListComponent extends ListContentComponent implements OnInit, OnDestroy {

  constructor(
    private invoiceService: InvoiceService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private toastrService: ToastrService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    super(router, route);
    this.advancedSearchForm = this.fb.group({
      number: [null],
      buyerName: [null],
      purchaseOrderNumber: [null],
      status: [null],
      mode: [null],
      contractMode: [null],
      activityType: [null],
      credited: [null]
    });
  }
  protected readonly faSearch = faSearch;
  protected readonly faFileCircleXmark = faFileCircleXmark;
  protected readonly faDownload = faDownload;
  protected readonly faBan = faBan;
  protected readonly faFileInvoice = faFileInvoice;
  listContent: InvoicingModel[] = [];
  status = Object.values(InvoiceStatusEnum);
  activityTypes = Object.values(ActivityTypeEnum);
  modes = Object.values(InvoiceModeEnum);
  contractModes = Object.values(ContractModeEnum);

  listParamValidator = {
    page: RegExp('^[1-9][0-9]*$'),
    size: ['5', '10', '20'],
    sort: RegExp('^(number|status|date|createdDate|mode)\,(asc|desc)$'),
    search: RegExp('.{3,}'),
    number: RegExp('^[a-zA-Z0-9_.-]+$'),
    buyerName: RegExp(regex.companyName),
    supplierName: RegExp(regex.companyName),
    purchaseOrderNumber: RegExp('^[a-zA-Z0-9_.-]+$'),
    status: Object.values(InvoiceStatusEnum),
    mode: Object.values(InvoiceModeEnum),
    contractMode: RegExp(`^(${this.contractModes.join('|')})(\,(${this.contractModes.join('|')}))*$`),
    activityType: RegExp(`^(${this.activityTypes.join('|')})(\,(${this.activityTypes.join('|')}))*$`),
    credited: ['true', 'false']
  };

  protected readonly InvoiceStatusEnum = InvoiceStatusEnum;

  protected readonly InvoicingStatusEnum = InvoicingStatusEnum;

  ngOnInit(): void {
    super.ngOnInit();
    this.sort = 'date';
    this.sortDirection = 'desc';
    this.subscribeToQueryParam();
  }

  // Override method
  mapQueryParamToAdvancedSearchForm(params: any): void {
    Object.keys(params).forEach(paramKey => {
      if (this.advancedSearchForm.contains(paramKey)) {
        if (paramKey === 'type') {
          const type = params[paramKey].split(',');
          this.advancedSearchForm.get('type').setValue(_.uniq(type));
        } else if (paramKey === 'contractMode') {
          const contractMode = params[paramKey].split(',');
          this.advancedSearchForm.get('contractMode').setValue(_.uniq(contractMode));
        } else {
          this.advancedSearchForm.get(paramKey).setValue(params[paramKey]);
        }
        this.isAdvancedSearchDisplayed = true;
        this.isSearchActive = true;
      }
    });
  }

  // Override method
  mapAdvancedSearchFormToQueryParam(advancedSearchForm: any): any {
    if (advancedSearchForm.hasOwnProperty('type') && advancedSearchForm.type) {
      advancedSearchForm.type = advancedSearchForm.type.join();
    }
    if (advancedSearchForm.hasOwnProperty('contractMode') && advancedSearchForm.contractMode) {
      advancedSearchForm.contractMode = advancedSearchForm.contractMode.join();
    }
    return advancedSearchForm;
  }

  // Override method
  retrieveListContent(params: any): void {
    const page = params.page ? params.page - 1 : 0;
    const size = params.size ? params.size : 5;
    const sort = params.sort ? params.sort : 'date,desc';
    const search = params.search ? params.search : null;
    const advancedSearch = this.invoiceService.mapAdvancedSearchForm(this.advancedSearchForm.value);
    advancedSearch.types = ['SUPPLIER'];
    this.invoiceService.getInvoicesByPage(page, size, sort, search, advancedSearch).subscribe({
      next: (res: ListContentModel) => {
        this.listContent = res.content;
        this.totalElements = res.totalElements;
        this.numberOfElements = res.numberOfElements;
        this.isListEmpty = !this.isSearchActive && res.empty;

        if (res.totalPages !== 0 && params.page > res.totalPages) {
          this.updateQueryParam({page: res.totalPages});
        }

        this.firstCallDone = true;
      },
      error: (error: any) => {
          this.toastrService.error(this.translateService.instant('global.ts.error'));
      }
    });
  }

  downloadInvoice(invoice) {
    this.invoiceService.downloadFile(invoice.id).subscribe(
      (res: HttpResponse<Blob>) => {
        const filename = invoice.number + '.pdf';
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res.body);
        a.download = filename;
        // this.logger.info('your file: ', a);
        a.click();
        a.remove();
      },
      (error: any) => {
          this.toastrService.error(this.translateService.instant('global.ts.error'));
      }
    );
  }
}
