<div class="row mb-4 mt-1">

  <ng-container *ngIf="request.requestSupplierStepResource.unlinkedProviders">
    <div class="col-12" *ngIf="request.requestClientStepResource.billing.contractMode === 'T_M'">
      <div class="alert alert-danger">
        <div class="row">
          <div class="col-3">
            <div class="notif-number">
              {{ request.requestSupplierStepResource.unlinkedProviders }}
            </div>
          </div>

          <div class="col-9 notif-container">
            <div
              class="notif-provider">{{ 'Mission_view.provider.label_attach_provider'|translate }}
            </div>
            <div class="notif-provider-guide">
              {{ 'Mission_view.provider.label_attach_provider_details'|translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="col-5" [ngClass]="{'col-6': request.requestClientStepResource.billing.contractMode !== 'T_M'}">
    <billing-view-detail
      class="slide-in-animation"
      [billing]="request.requestClientStepResource.billing"
    ></billing-view-detail>
  </div>

  <div class="col-4" @slideIn
       *ngIf="request?.requestClientStepResource?.billing?.contractMode === 'T_M' && request?.requestSupplierStepResource?.projects?.length > 0">
    <provider-view-detail
      class="slide-in-animation"
      (newModifyEvent)="ngOnInit()"
      [billingConfig]="request.requestClientStepResource.billing"
      [requestId]="request.requestId"
      [providers]="getProviders(request?.requestSupplierStepResource?.projects[0].id, this.request.requestSupplierStepResource.providers)"
      [supplierId]="request.requestSupplierStepResource.supplier.supplierId"
    ></provider-view-detail>
  </div>

  <div class="col-3" *ngIf="request.requestSupplierStepResource.requestId" @slideIn>
    <supplier-view-detail
      class="slide-in-animation"
      [supplier]="request.requestSupplierStepResource.supplier"
    ></supplier-view-detail>
  </div>

  <ng-container *ngIf="request.requestSupplierStepResource.projects && buyerId">
    <div
      class="col-3"
      *ngFor="let project of request.requestSupplierStepResource.projects"
      @slideIn
    >
      <project-view-detail
        class="slide-in-animation"
        *ngIf="request.requestSupplierStepResource.projects && buyerId"
        [project]="project"
        [buyerId]="buyerId"
      ></project-view-detail>
    </div>
  </ng-container>

  <ng-container *ngIf="request.requestClientStepResource.billing.contractMode !== 'T_M'">
    <div class="col-3" *ngIf="request.requestSupplierStepResource.requestId" @slideIn>
      <activities-view-detail
        class="slide-in-animation"
        [billings]="request.requestClientStepResource.billing"
      ></activities-view-detail>
    </div>

    <div class="col-4">
      <total-billings-view-detail
        class="slide-in-animation"
        [billings]="request.requestClientStepResource.billing"
      ></total-billings-view-detail>
    </div>
  </ng-container>

  <div class="col-5" [ngClass]="{'col-9': request.requestClientStepResource.billing.contractMode === 'T_M'}">
    <app-request-purchase-order-detail
      *ngIf="request && request?.requestSupplierStepResource?.projects?.length > 0"
      [requestId]="request.requestId"
      [providers]="getProviders(request?.requestSupplierStepResource?.projects[0].id, this.request.requestSupplierStepResource.providers)"
    ></app-request-purchase-order-detail>
  </div>
</div>
