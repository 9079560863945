import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ListContentComponent} from '../../../shared/components/list-content/list-content.component';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoryModel} from '../../../shared/models/call-for-tender/category.model';
import {
  CallForTenderOfferListItemModel
} from '../../../shared/models/call-for-tender/call-for-tender-offer-list-item.model';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NGXLogger} from 'ngx-logger';
import * as _ from 'lodash';
import {ActivityAreaModel} from '../../company-config/activity-areas/activity-area.model';
import {ActivityAreasService} from '../../company-config/services/activity-areas.service';
import {CallForTenderOffersService} from '../services/call-for-tender-offers.service';
import {query, transition, trigger, useAnimation} from '@angular/animations';
import {slideIn50StaggerAnimation, slideInAnimation} from '../../../shared/animation/common.animation';
import {ModalConfirmComponent} from '../../../shared/components/modal-confirm/modal-confirm.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {CallForTenderModel} from '../../../shared/models/call-for-tender/call-for-tender.model';
import {CallForTenderService} from '../services/call-for-tender.service';
import {forkJoin} from 'rxjs';
import * as moment from 'moment';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {CallForTenderOfferStatusEnum, OfferNegotiationEnum, OfferTimeSlotStatusEnum} from '../../../shared/enums/CallForTenderOffers.enum';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'call-for-tender-offers-list',
  templateUrl: './call-for-tender-offers-list.component.html',
  styleUrls: ['./call-for-tender-offers-list.component.scss'],
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        useAnimation(slideInAnimation)
      ])
    ]),
    trigger('slideInStagger', [
      transition(':enter', [
        query('.slide-in-animation', [
          useAnimation(slideIn50StaggerAnimation)
        ], {optional: true})
      ])
    ])
  ]
})
export class CallForTenderOffersListComponent extends ListContentComponent implements OnInit, OnDestroy {
  static readonly ASC = 'asc';
  static readonly DESC = 'desc';
  @ViewChild('offerTimeSlotsModal', {static: true}) offerTimeSlotsModal;
  @ViewChild('offerNegotiationModal', {static: true}) offerNegotiationModal;
  callForTenderDetailModalValue: CallForTenderModel = new CallForTenderModel();
  callForTenderOfferDetailModalValue: CallForTenderOfferListItemModel = new CallForTenderOfferListItemModel();
  buttonClicked: string = '';
  isLast: boolean;
  listContent: CallForTenderOfferListItemModel[] = [];
  categoryList: CategoryModel[] = [];
  callForTender: CallForTenderModel;
  meetingsForm: FormGroup;
  negotiationForm: FormGroup;
  confirmModalCallForTenderOfferId: string;
  userRole: string;
  minAmount: any;
  maxAmount: any;
  statusSearchable = [CallForTenderOfferStatusEnum.SENT, CallForTenderOfferStatusEnum.REJECTED, CallForTenderOfferStatusEnum.SELECTED, CallForTenderOfferStatusEnum.REPLIED, CallForTenderOfferStatusEnum.PUBLISHED, CallForTenderOfferStatusEnum.ACCEPTED, CallForTenderOfferStatusEnum.REJECTED_BY_SA, CallForTenderOfferStatusEnum.CANCELLED, CallForTenderOfferStatusEnum.CLOSED_BY_SUPPLIER];
  listParamValidator = {
    sort: RegExp('^(modifiedDate|createdDate)\,(asc|desc)$'),
    reference: RegExp('.+'),
    title: RegExp('.+'),
    buyer: RegExp('.+'),
    provider: RegExp('.+'),
    status: RegExp(`^(${this.statusSearchable.join('|')})(\,(${this.statusSearchable.join('|')}))*$`)
  };
  sortedMeetings: ({ name: string; group: any })[];
  activityAreas: ActivityAreaModel[];

  constructor(public router: Router,
              public route: ActivatedRoute,
              private fb: FormBuilder,
              private callForTenderOffersService: CallForTenderOffersService,
              private callForTenderService: CallForTenderService,
              private authenticationService: AuthenticationService,
              private toastrService: ToastrService,
              private translateService: TranslateService,
              private activityAreasService: ActivityAreasService,
              private modalService: BsModalService,
              private logger: NGXLogger) {
    super(router, route);
    this.advancedSearchForm = fb.group({
      reference: [],
      title: [],
      buyer: [],
      provider: [],
      status: []
    });
    this.negotiationForm = fb.group({
      amount: [null, [Validators.required, this.checkAmountDefault, this.checkAmountRange]]
    });
    this.meetingsForm = this.fb.group({
      meeting1: this.fb.group({
        date: [null],
        time: [null],
        status: [null]
      }),
      meeting2: this.fb.group({
        date: [null],
        time: [null],
        status: [null]
      }),
      meeting3: this.fb.group({
        date: [null],
        time: [null],
        status: [null]
      })
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isInfiniteList = true;
    this.sort = 'modifiedDate';
    this.sortDirection = CallForTenderOffersListComponent.DESC;
    this.page = 0;
    this.activityAreasService.getAll().subscribe(value => {
      this.activityAreas = value;
    });
    super.subscribeToQueryParam();
    this.userRole = this.authenticationService.getRole();
  }

  retrieveListContent(params: any): void {
    const sort = params.sort ? params.sort : 'modifiedDate,desc';
    const page = this.page ? this.page : 0;
    const advancedSearch = this.callForTenderOffersService.mapAdvancedSearchForm(params);
    this.subscriptions.push(this.callForTenderOffersService.getCallForTenderOffersList(page, sort, advancedSearch).subscribe(
      (res: ListContentModel) => {
        res.first ? this.listContent = res.content : this.listContent = this.listContent.concat(res.content);
        this.isListEmpty = !this.isSearchActive && res.empty;
        this.firstCallDone = true;
        this.isLast = res.last;
      }, (error: any) => {
        this.logger.error(error.url, '- STATUS :', error);
        this.toastrService.error(this.translateService.instant('global.ts.error'));
      }
    ));
  }

  setSort(value: any): void {
    const sort = value + ',' + this.sortDirection;
    this.page = 0;
    this.updateQueryParam({sort});
  }

  setSortDirection(): void {
    const sort = this.sort + ',' + (this.sortDirection === CallForTenderOffersListComponent.ASC ? CallForTenderOffersListComponent.DESC : CallForTenderOffersListComponent.ASC);
    this.page = 0;
    this.updateQueryParam({sort});
  }

  resetAdvancedSearchForm(): void {
    if (this.isAdvancedSearchFormEmpty()) {
      return;
    }

    this.advancedSearchForm.reset();
    if (this.isSearchActive) {
      this.isSearchActive = false;
      this.page = 0;
      this.updateQueryParam({...this.advancedSearchForm.value});
    }
  }

  getNextPageCallForTenderList(): void {
    if (!this.isLast) {
      this.page += 1;
      this.refreshListContent();
    }
  }

  // Override method
  mapAdvancedSearchFormToQueryParam(advancedSearchForm: any): any {
    if (advancedSearchForm.hasOwnProperty('status') && advancedSearchForm.status) {
      advancedSearchForm.status = advancedSearchForm.status.join();
    }

    return advancedSearchForm;
  }

  // Override method
  mapQueryParamToAdvancedSearchForm(params: any): void {
    Object.keys(params).forEach(paramKey => {
      if (this.advancedSearchForm.contains(paramKey)) {
        if (paramKey === 'status') {
          const status = params[paramKey].split(',');
          this.advancedSearchForm.get('status').setValue(_.uniq(status));
        } else {
          this.advancedSearchForm.get(paramKey).setValue(params[paramKey]);
        }
        this.isAdvancedSearchDisplayed = true;
        this.isSearchActive = true;
      }
    });
  }

  deleteOffer(offerId: string): void {
    this.subscriptions.push(this.translateService.get('Call_for_tender_offer_list.delete_btn_msg').subscribe((data: any) => {
      const initialState = {message: data};
      const modalRef = this.modalService.show(ModalConfirmComponent, {class: 'modal-lg', initialState});
      this.subscriptions.push(modalRef.content.actionConfirmed.subscribe(
        () => {
          this.subscriptions.push(this.callForTenderOffersService.deleteOffer(offerId).subscribe(
            () => {
              super.subscribeToQueryParam();
            }, (error: any) => {
              this.logger.error(error.url, '- STATUS :', error.status);
              this.toastrService.error(this.translateService.instant('global.ts.error'));
            }
          ));
        }
      ));
    }));
  }


  showOfferTimeSlotsModel(ids: string[]) {
    this.meetingsForm.reset();
    const observablesArray = [];
    observablesArray.push(this.callForTenderOffersService.getCallForTenderOffersById(ids[0]));
    observablesArray.push(this.callForTenderService.getCallForTenderById(ids[1]));
    this.confirmModalCallForTenderOfferId = ids[0];

    this.subscriptions.push(forkJoin(observablesArray).subscribe(
      (observableResponses: any) => {
        this.callForTenderOfferDetailModalValue = observableResponses[0];
        this.callForTenderDetailModalValue = observableResponses[1];
        let meetingNumber = 1;
        observableResponses[0].meetings.forEach(value => {
          this.meetingsForm.get('meeting' + meetingNumber).get('date').setValue(moment(value.schedule).format('YYYY-MM-DD'));
          this.meetingsForm.get('meeting' + meetingNumber).get('time').setValue(moment(value.schedule).format('HH:mm'));
          this.meetingsForm.get('meeting' + meetingNumber).get('status').setValidators([Validators.required]);
          this.meetingsForm.get('meeting' + meetingNumber).get('status').updateValueAndValidity();
          meetingNumber++;
        });
        this.meetingsForm.updateValueAndValidity();
        this.sortMeetings();
        this.offerTimeSlotsModal.show();
      }, (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
        this.toastrService.error(this.translateService.instant('global.ts.error'));
      }
    ));
  }

  showNegotiationModal(ids: string[]) {
    const observablesArray = [];
    observablesArray.push(this.callForTenderOffersService.getCallForTenderOffersById(ids[0]));
    observablesArray.push(this.callForTenderService.getCallForTenderById(ids[1]));
    this.confirmModalCallForTenderOfferId = ids[0];

    forkJoin({
      callForTenderOffersById: this.callForTenderOffersService.getCallForTenderOffersById(ids[0]),
      callForTenderById: this.callForTenderService.getCallForTenderById(ids[1])
    }).subscribe({
      next: forked => {
        this.callForTenderOfferDetailModalValue = forked.callForTenderOffersById;
        this.callForTenderDetailModalValue = forked.callForTenderById;

        if (this.callForTenderDetailModalValue.contractMode == 'T_M') {
          this.minAmount = this.callForTenderDetailModalValue.minDailyRate ?? 1;
          this.maxAmount = this.callForTenderDetailModalValue.maxDailyRate;
        } else {
          this.minAmount = this.callForTenderDetailModalValue.minAmount ?? 1;
          this.maxAmount = this.callForTenderDetailModalValue.maxAmount;
        }

        this.offerNegotiationModal.show();
      },
      error: (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
        this.subscriptions.push(this.translateService.get('global.ts.error').subscribe((data: any) => {
          this.toastrService.error(data);
        }));
      }
    });
  }

  setButtonClicked(buttonName: string) {
    this.buttonClicked = buttonName;
  }

  onFormSubmit() {
    if (this.buttonClicked === 'accept') {
      this.openAcceptNegotiationModal(this.callForTenderOfferDetailModalValue);
    } else if (this.buttonClicked === 'negotiate') {
      const newAmount = this.negotiationForm.get('amount').value;
      this.openUpdateNegotiationModal(this.callForTenderOfferDetailModalValue, newAmount);
    } else if (this.buttonClicked === 'closed') {
      this.openCloseNegotiationModal(this.callForTenderOfferDetailModalValue);
    }
  }

  openAcceptNegotiationModal(callForTenderOfferDetailModalValue: any): void {
    this.subscriptions.push(this.translateService.get(['call_for_tender_negotiation_card.accept_negotiation_modal']).subscribe((data: any) => {
      const initialState = {
        message: data['call_for_tender_negotiation_card.accept_negotiation_modal'],
      };
      const modalRef = this.modalService.show(ModalConfirmComponent, {class: 'modal-lg', initialState});
      this.subscriptions.push(modalRef.content.actionConfirmed.subscribe(
        value => {
          this.acceptNegotiationOffer(callForTenderOfferDetailModalValue, value);
        }
      ));
    }));
  }

  acceptNegotiationOffer(callForTenderOfferDetailModalValue: any, value: any): void {
    this.subscriptions.push(this.callForTenderOffersService.updateNegotiation(callForTenderOfferDetailModalValue.id, value, OfferNegotiationEnum.ACCEPT).subscribe(
      (res: any) => {
        this.refreshListContent();
        this.toastrService.success(this.translateService.instant('call_for_tender_negotiation_card-ts.negotiation-sent'));
        this.offerNegotiationModal.hide();

      }, (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
  }

  openUpdateNegotiationModal(callForTenderOfferDetailModalValue: any, newAmount: any): void {
    this.subscriptions.push(this.translateService.get(['call_for_tender_negotiation_card.update_negotiation_modal']).subscribe((data: any) => {
      const initialState = {
        message: data['call_for_tender_negotiation_card.update_negotiation_modal'],
      };
      const modalRef = this.modalService.show(ModalConfirmComponent, {class: 'modal-lg', initialState});
      this.subscriptions.push(modalRef.content.actionConfirmed.subscribe(
        value => {
          this.updateNegotiationOffer(callForTenderOfferDetailModalValue, newAmount);
        }
      ));
    }));
  }

  updateNegotiationOffer(callForTenderOfferDetailModalValue: any, newAmount: any): void {
    this.subscriptions.push(this.callForTenderOffersService.updateNegotiation(callForTenderOfferDetailModalValue.id, newAmount, OfferNegotiationEnum.NEGOTIATING).subscribe(
      (res: any) => {
        this.refreshListContent();
        this.toastrService.success(this.translateService.instant('call_for_tender_negotiation_card-ts.negotiation-sent'));
        this.offerNegotiationModal.hide();

      }, (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
  }

  openCloseNegotiationModal(callForTenderOfferDetailModalValue: any): void {
    this.subscriptions.push(this.translateService.get(['call_for_tender_negotiation_card.close_negotiation_modal']).subscribe((data: any) => {
      const initialState = {
        message: data['call_for_tender_negotiation_card.close_negotiation_modal'],
      };
      const modalRef = this.modalService.show(ModalConfirmComponent, {class: 'modal-lg', initialState});
      this.subscriptions.push(modalRef.content.actionConfirmed.subscribe(
        value => {
          this.closeNegotiationOffer(callForTenderOfferDetailModalValue, value);
        }
      ));
    }));
  }

  closeNegotiationOffer(callForTenderOfferDetailModalValue: any, value: any): void {
    this.subscriptions.push(this.callForTenderOffersService.updateNegotiation(callForTenderOfferDetailModalValue.id, value, OfferNegotiationEnum.CLOSE).subscribe(
      (res: any) => {
        this.refreshListContent();
        this.toastrService.success(this.translateService.instant('call_for_tender_negotiation_card-ts.negotiation-sent'));
        this.offerNegotiationModal.hide();

      }, (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
  }

  patchCallForTenderOffer() {
    this.subscriptions.push(this.translateService.get('Call_for_tender_offer_list.accept_btn_msg').subscribe((data: any) => {
      const initialState = {message: data};
      const modalRef = this.modalService.show(ModalConfirmComponent, {class: 'modal-lg', initialState});
      this.subscriptions.push(modalRef.content.actionConfirmed.subscribe(
        () => {
          const meetings = this.callForTenderOffersService.mapMeetingsFormValueToArray(this.meetingsForm.value);
          this.subscriptions.push(this.callForTenderOffersService.selectCallForTenderOffer(this.confirmModalCallForTenderOfferId, meetings).subscribe(
            () => {
              super.subscribeToQueryParam();
            }, (error: any) => {
              this.toastrService.error(this.translateService.instant('global.ts.error'));
              this.logger.error(error.url, '- STATUS :', error.status);
            }
          ));
          const meetingNames = ['meeting1', 'meeting2', 'meeting2'];
          for (const meetingName of meetingNames) {
            this.meetingsForm.get(meetingName).get('status').setValidators([]);
            this.meetingsForm.get(meetingName).get('status').updateValueAndValidity();
            this.meetingsForm.get(meetingName).get('date').setValue(null);
            this.meetingsForm.get(meetingName).get('time').setValue(null);
          }
          this.offerTimeSlotsModal.hide();
        }
      ));
    }));
  }

  rejectTimeSlot(meeting: { group: { status: OfferTimeSlotStatusEnum; }; name: string; }) {
    meeting.group.status = OfferTimeSlotStatusEnum.REFUSED;
    this.meetingsForm.get(meeting.name).get('status').setValue(meeting.group.status);
  }

  acceptTimeSlot(meeting: { group: { status: OfferTimeSlotStatusEnum; }; name: string; }) {
    meeting.group.status = OfferTimeSlotStatusEnum.ACCEPTED;
    this.meetingsForm.get(meeting.name).get('status').setValue(meeting.group.status);
  }

  hideModal(): void {
    this.offerTimeSlotsModal.hide();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
  }

  private checkAmountDefault = (control: FormControl) => {
    const amount = control?.value;
    if (amount == this.callForTenderOfferDetailModalValue.priceNegotiation) {
      return {amountDefault: true};
    }
    return null;
  }

  private checkAmountRange = (control: FormControl) => {
    const amount = control?.value;
    if (amount > this.maxAmount || amount < this.minAmount) {
      return {amountRange: true};
    }
    return null;
  }

  sortMeetings() {
    const meetings = [
      { name: 'meeting1', group: this.meetingsForm.get('meeting1').value },
      { name: 'meeting2', group: this.meetingsForm.get('meeting2').value },
      { name: 'meeting3', group: this.meetingsForm.get('meeting3').value }
    ];

    this.sortedMeetings = meetings.sort((a, b) => {
      const dateA = new Date(a.group.date).getTime();
      const dateB = new Date(b.group.date).getTime();
      return dateA - dateB;
    });
  }

  getActivityAreas(cft: CallForTenderOfferListItemModel) {
    if (!cft?.callForTender?.activityAreaIds?.length) {
      return '';
    }

    return cft.callForTender.activityAreaIds
      .map(id => this.activityAreas.find(area => area.id === id)?.label)
      .filter(label => label) // Remove undefined values
      .join(', ');
  }
}
