import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../assets/environments/environment';
import { EnveloppeResource } from './enveloppe.resource';


@Injectable({
  providedIn: 'root'
})
export class MissionService {

  constructor(private httpClient: HttpClient) {
  }
  getEnveloppes(): Observable<EnveloppeResource[]> {
    return this.httpClient.get<EnveloppeResource[]>(environment.api_root + 'requestMgtApi/requests/enveloppes');
  }
}
