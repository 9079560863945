<div class="animated fadeIn">
  <div class="row">
    <div class="col-md-12">
      <h2 class="underline">{{'Call_for_tender.cft_title'|translate}}</h2>
    </div>
  </div>
  <ng-container *ngIf="!firstCallDone; else firstCallDoneBlock">
    <app-spinner [backgroundTransparent]="true"></app-spinner>
  </ng-container>
  <ng-template #firstCallDoneBlock>
    <div *ngIf="!isListEmpty; else noCallForTenderBlock" @slideInStagger>
      <div class="row">
        <div class="col-md-3">
          <div class="card box-shadow-hover mb-2 slide-in-animation">
            <div class="card-body">
              <div class="row">
                <div class="col-md-9 form-group pr-1">
                  <label for="sort">{{'Call_for_tender.sort_by.sort_by_title'|translate}}</label>
                  <select class="custom-select" id="sort" (change)="setSort(sortSelect.value)"
                          [value]="sort"
                          #sortSelect>
                    <option value="modifiedDate">{{'Call_for_tender.sort_by.modified_date'|translate}}</option>
                    <option value="createdDate">{{'Call_for_tender.sort_by.created_date'|translate}}</option>
                    <option value="title">{{'Call_for_tender.sort_by.title'|translate}}</option>
                  </select>
                </div>
                <div class="col-md-3 form-group d-flex align-items-end pl-1">
                  <button class="btn btn-secondary w-100" type="button"
                          (click)="setSortDirection()" [title]="sortDirection">
                    <fa-icon [icon]="faSortAmountDesc" flip="{{sortDirection === 'asc' ? 'vertical' : ''}}"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <form [formGroup]="advancedSearchForm" (ngSubmit)="advancedSearchFormSubmit()">
            <div class="card box-shadow-hover mb-2 slide-in-animation">
              <div class="card-body">
                <div class="form-group">
                  <label for="reference">{{'Call_for_tender.reference'|translate}}</label>
                  <input type="text" class="form-control" id="reference"
                         formControlName="reference">
                </div>
                <div class="row">
                  <div class="col form-group">
                    <label for="title">{{'Call_for_tender.title'|translate}}</label>
                    <input type="text" class="form-control" id="title" formControlName="title">
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group">
                    <label for="buyer">{{'Call_for_tender.buyer'|translate}}</label>
                    <input type="text" class="form-control" id="buyer" formControlName="buyer">
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group">
                    <label>
                      {{'activity-area.title'|translate}}
                    </label>
                    <app-activity-area-dropdown
                      #activityAreaDropDownSelector
                      (change)="setActivityAreaFilterValue($event)"
                      [config]="{multiple: false,closeOnSelect: true, searchable: false}"
                      placeholder="activity-area.select.activity-area"
                    ></app-activity-area-dropdown>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end align-items-center slide-in-animation">
              <button class="btn btn-link" type="button"
                      (click)="resetAdvancedSearchForm()">{{'Call_for_tender.reset_btn'|translate}}</button>
              <button class="btn btn-primary px-5" type="submit"
                      [disabled]="advancedSearchForm.pristine">{{'Call_for_tender.apply_filter_btn'|translate}}
              </button>
            </div>
          </form>
        </div>
        <div class="col-md-9">
          <p class="text-dark-gray mb-1 text-right"
             *ngIf="totalElements > 0">{{'Call_for_tender.list.entries_displayed'|translate: {
            elements: numberOfElements,
            totalElements: totalElements
          } }}</p>
          <div *ngIf="listContent.length > 0; else noResult" infinite-scroll
               [infiniteScrollDistance]="2"
               [infiniteScrollThrottle]="300"
               [scrollWindow]="true"
               (scrolled)="getNextPageCallForTenderList()">
            <app-call-for-tender-list-card
              class="slide-in-animation d-block"
              *ngFor="let callForTender of listContent"
              [callForTender]="callForTender"
              [activityAreas]="activityAreas"
              (callForTenderDetail)="openCallForTenderDetailModal($event)">
            </app-call-for-tender-list-card>
          </div>
        </div>

        <ng-template #noResult>
          <div class="text-center my-3 slide-in-animation" @slideIn>
            <img src="assets/img/result_empty.svg" alt="No results" style="width: 30rem;">
            <h5 class="font-weight-bold mt-3">{{'Call_for_tender.no_result'|translate}}</h5>
          </div>
        </ng-template>
      </div>
    </div>

    <ng-template #noCallForTenderBlock>
      <app-no-content class="d-block" content="{{'Call_for_tender.no_cft'|translate}}"
                      iconClassName="fa fa-newspaper-o" @slideIn>
      </app-no-content>
    </ng-template>
  </ng-template>


  <div class="modal fade" bsModal #callForTenderDetailModal="bs-modal"
       tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-lg modal-right">
      <div class="modal-content">
        <div class="px-3 pb-2 pt-4">
          <ng-container [ngSwitch]="callForTenderDetailModalValue.status">
            <span class="badge float-right" [ngClass]="{
              'badge-success': 'WAITING_FOR_REPLIES' === callForTenderDetailModalValue.status,
              'badge-warning': 'SENT' === callForTenderDetailModalValue.status,
              'badge-danger': 'REJECTED' === callForTenderDetailModalValue.status,
              'badge-gray': 'CLOSED' === callForTenderDetailModalValue.status || !['WAITING_FOR_REPLIES', 'SENT', 'REJECTED'].includes(callForTenderDetailModalValue.status)
              }">
              {{'marketplace.call-for-tenders.status.' + callForTenderDetailModalValue.status | translate | titlecase}}
            </span>
          </ng-container>
          <div class="d-flex align-items-center">
            <h3 class="mr-2 text-justify">{{callForTenderDetailModalValue.title}}</h3>
          </div>
          <span class="text-dark-gray mr-2">({{callForTenderDetailModalValue.reference}})</span>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-8">
              <h5>{{'Call_for_tender.list.modal.profile'|translate}}</h5>
              <p class="text-dark-gray mb-1" title="ADR">
                <ng-container *ngIf="callForTenderDetailModalValue.contractMode === 'FIXED_PRICE' ? (callForTenderDetailModalValue.minAmount && callForTenderDetailModalValue.minAmount !== 0 && callForTenderDetailModalValue.minAmount !== callForTenderDetailModalValue.maxAmount)
            : (callForTenderDetailModalValue.minDailyRate && callForTenderDetailModalValue.minDailyRate !== 0 && callForTenderDetailModalValue.minDailyRate !== callForTenderDetailModalValue.maxDailyRate)">
                  {{callForTenderDetailModalValue.contractMode === 'FIXED_PRICE' ? callForTenderDetailModalValue.minAmount : callForTenderDetailModalValue.minDailyRate}}{{callForTenderDetailModalValue.currency | currencyFormat: 'symbol'}}
                  -
                </ng-container>
                {{callForTenderDetailModalValue.contractMode === 'FIXED_PRICE' ? callForTenderDetailModalValue.maxAmount : callForTenderDetailModalValue.maxDailyRate}}{{callForTenderDetailModalValue.currency | currencyFormat: 'symbol'}}
                <ng-container *ngIf="callForTenderDetailModalValue.contractMode !== 'FIXED_PRICE'">{{ (callForTenderDetailModalValue.dailyRateByDate ? 'marketplace.by-day' : 'marketplace.by-hour')|translate }}</ng-container>
              </p>

              <p class="text-dark-gray" title="Technologies">

                <div *ngIf="callForTenderDetailModalValue?.cftSkills?.length == 0">
                  <span *ngFor="let technology of callForTenderDetailModalValue.technologies" class="badge skill-badge badge-gray mr-1">
                    {{technology}}
                  </span>
                </div>

                <div *ngIf="callForTenderDetailModalValue?.cftSkills?.length > 0">
                  <h5>{{'marketplace.call-for-tender-list.mandatory-skills'|translate}}</h5>
                  <p class="text-dark-gray" title="Technologies" >
                    <span *ngFor="let skill of mandatorySkills" class="badge skill-badge badge-gray mr-1 mt-1">
                      {{skill.name}}
                      <span *ngIf="skill.experience">
                        ({{skill.experience}})
                      </span>
                    </span>

                  </p>
                  <h5>{{'marketplace.call-for-tender-list.optional-skills'|translate}}</h5>
                  <p class="text-dark-gray" title="Technologies">
                    <span *ngFor="let skill of optionalSkills" class="badge skill-badge badge-gray mr-1 mt-1">
                      {{skill.name}}
                      <span *ngIf="skill.experience">
                        ({{skill.experience}})
                      </span>
                    </span>
                  </p>
                </div>

              <br/>


              <h5>{{'Call_for_tender.list.modal.description'|translate}}</h5>
              <div class="text-dark-gray call-for-tender-description" [innerHTML]="callForTenderDetailModalValue.description">
              </div>
            </div>
            <div class="col-md-4 pl-0">
              <button class="btn btn-dim btn-outline-primary btn-card mb-2" (click)="downloadFile(callForTenderDetailModalValue);"
                    *ngIf="cftAttachmentsModalValue && cftAttachmentsModalValue.length != 0">
                <fa-icon [icon]="faDownload" class="pr-2"></fa-icon>
                {{'Call_for_tender.download_file'|translate}}
              </button>
              <div class="panel panel-gray">
                <p class="mb-0" title="Contract Mode">
                  <fa-icon [icon]="faFileText" class="mr-1"></fa-icon>
                  {{callForTenderDetailModalValue.contractMode | statusReadable |titlecase}}
                </p>
                <p class="mb-0" title="Start date">
                  <fa-icon [icon]="faCalendar" class="mr-1"></fa-icon>
                  {{callForTenderDetailModalValue.startDate | date: 'dd MMMM yyyy'}}
                  {{callForTenderDetailModalValue.endDate ? ' - '.concat(
                  callForTenderDetailModalValue.endDate | date: 'dd MMM. yyyy') : ''}}
                </p>
                <p class="mb-0" title="Mission duration">
                  <fa-icon [icon]="faClock" class="mr-1"></fa-icon>
                  {{callForTenderDetailModalValue.quantity}} {{(callForTenderDetailModalValue.quantityByDate ? 'global.common.days' : 'global.common.hours') | translate}}
                </p>
                <p class="mb-0" title="ActivityArea" *ngIf="callForTenderDetailModalValue?.activityAreaIds">
                  <fa-icon [icon]="faIndustry" class="mr-1"></fa-icon>
                  {{getActivityAreas(callForTenderDetailModalValue)}}
                </p>

              </div>

              <div class="panel panel-gray">
                <h6 class="font-weight-bold">{{'Call_for_tender.workplace'|translate}}</h6>
                <a
                  [href]="'https://www.google.fr/maps/search/' + callForTenderDetailModalValue.workplace.postCode + ' ' + callForTenderDetailModalValue.workplace.city + ', ' + callForTenderDetailModalValue.workplace.country"
                  target="_blank">
                  {{callForTenderDetailModalValue.workplace.postCode}} {{callForTenderDetailModalValue.workplace.city}}
                </a>
                <p class="mb-1">{{callForTenderDetailModalValue.workplace.country}}</p>
                <p class="mb-1">
                  <strong>{{'Call_for_tender.teleworking'|translate}} : </strong>
                  {{!callForTenderDetailModalValue.nbDaysTeleworking ? ('global.common.no' | translate) : callForTenderDetailModalValue.nbDaysTeleworking}}
                  {{ !callForTenderDetailModalValue.nbDaysTeleworking ? ""
                  : (callForTenderDetailModalValue.nbDaysTeleworking === 1 ? "global.common.day-weeks" : "global.common.days-weeks") | translate}}
                </p>
                <p class="mb-1" *ngIf="callForTenderDetailModalValue.allowBusinessTrip">
                  <strong>{{'Call_for_tender.list.modal.displacement'|translate}}
                    : </strong>{{callForTenderDetailModalValue.businessTripComment}}
                </p>
              </div>

              <div class="panel panel-gray"
                   *ngIf="callForTenderDetailModalValue.status !== 'CLOSED'">
                <h6 class="font-weight-bold">{{'Call_for_tender.expiration'|translate}}</h6>
                  <fa-icon [icon]="faExclamationTriangle" class="mr-1 text-danger" *ngIf="getExpiration(callForTenderDetailModalValue.closedDate) <= 7"></fa-icon>
                <span
                  [ngClass]="{'text-danger': getExpiration(callForTenderDetailModalValue.closedDate) <= 7}">
                  {{'Call_for_tender.list.modal.days'|translate: {
                  days: getExpiration(callForTenderDetailModalValue.closedDate)
                } }}
                </span>
              </div>

              <div class="panel panel-gray">
                <h6 class="font-weight-bold">{{'Call_for_tender.visibility'|translate}}</h6>
                <ng-container
                  *ngIf="callForTenderDetailModalValue.open ; else callForTenderNotOpen">
                  <p class="mb-1">
                    <fa-icon [icon]="faGlobe" class="mr-1"></fa-icon>
                    Public
                  </p>
                </ng-container>
                <ng-template #callForTenderNotOpen>
                  <p *ngFor="let league of callForTenderDetailModalValue.leagues" class="mb-1">
                    <fa-icon [icon]="faInstitution" class="mr-1"></fa-icon>
                    {{league.name}}
                  </p>
                </ng-template>
              </div>
              <div class="mb-3">
                <button class="btn btn-primary" *ngIf="callForTenderDetailModalValue.freelanceOfferExist" disabled
                        [routerLink]="['/marketplace/calls-for-tender', callForTenderDetailModalValue.id, 'offer-creation']">
                  {{'Call_for_tender.offer_sent'|translate}}
                </button>
                <button class="btn btn-primary" *ngIf="!callForTenderDetailModalValue.freelanceOfferExist"
                        [routerLink]="['/marketplace/calls-for-tender', callForTenderDetailModalValue.id, 'offer-creation']">
                  {{'Call_for_tender.create_new_offer_btn'|translate}}
                </button>
              </div>
              <button class="btn btn-link" type="button"
                      (click)="backToCftList()">{{'Call_for_tender.list.button_back'|translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
