export enum PurchaseOrderStatusEnum {
  CREATE = 'CREATE', // It seems it is technical (Not exists on the BE)
  EXPIRED = 'EXPIRED', // It seems it is technical (Not exists on the BE)

  GENERATED = 'GENERATED',
  REFUSED_BY_SUPPLIER = 'REFUSED_BY_SUPPLIER',
  CANCELLED = 'CANCELLED',
  VALIDATED = 'VALIDATED'
}

export enum PurchaseOrderItemTypeEnum {
  AMOUNT = 'AMOUNT',
  FEES = 'FEES',
  ON_CALL = 'ON_CALL',
  QUANTITY = 'QUANTITY',
}

export enum PurchaseOrderItemOriginalTypeEnum {
  PROVIDER = 'PROVIDER',
  REQUEST = 'REQUEST',
}

export class PurchaseOrderModel {
  id: string;
  requestNumber: string;
  requestId: string;
  contractNumber: string;
  supplierId: string;
  supplierName: string;
  buyerName: string;
  createdDate: string;
  lang: string;
  billableCompany: string;
  billableCompanyId: string;
  date: string;
  purchaseOrderNumber: string;
  total: string;
  status: PurchaseOrderStatusEnum;
  reason: string;
  items: {
    id?: string;
    providerId?: number;
    startDate?: string;
    endDate?: string;
    description?: string;
    details?: string;
    type?: PurchaseOrderItemTypeEnum;
    unitPrice?: number;
    quantity?: number;
    warningValue?: number;
    warningQuantity?: number;
    displayed?: boolean;
    taxlessTotalAmount?: number;
    taxGroupId?: string;
    rate?: number;
    rateType?: string;
    originalType?: PurchaseOrderItemOriginalTypeEnum;
    originalId?: string | number;
  }[];
  recipientUserId: string;
  recipientEmail: string;
  recipientFirstName: string;
  recipientLastName: string;

  constructor() {
    this.items = [];
  }
}
