<div class="animated fadeIn">

  <div class="row">
    <div class="col-md-12">
      <h2 class="underline">{{'On_call.on_call'|translate}}</h2>
    </div>
  </div>
  <div class="row slide-in-animation">
    <div class="col" [ngClass]="{'col-md-12': !isAdvancedSearchDisplayed, 'col-md-10': isAdvancedSearchDisplayed}">
      <div  [ngClass]="{'text-right offset-advanced-search': isAdvancedSearchDisplayed, 'text-center': !isAdvancedSearchDisplayed}">
        <button class="advanced-search-button" (click)="toggleAdvancedSearch()">
          <u>{{'global.button.advanced-search'|translate}}</u>&nbsp;
          <i class="fa ml-1"
             [ngClass]="{'fa-angle-down': isAdvancedSearchDisplayed, 'fa-angle-left': !isAdvancedSearchDisplayed}"
             aria-hidden="true"></i>
        </button>
      </div>

      <form [formGroup]="advancedSearchForm" (ngSubmit)="advancedSearchFormSubmit()"
            *ngIf="isAdvancedSearchDisplayed; else basicSearchBlock" @fadeIn>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="mb-1" for="invoice-number-input">{{'global.common.po-num-search'|translate}}</label>
              <input type="text" class="form-control" id="invoice-number-input" formControlName="purchaseOrderNumber">
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="mb-1" for="client-input">{{'global.common.client'|translate}}</label>
              <input type="text" class="form-control" id="client-input" formControlName="buyerCompanyName">
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="mb-1" for="project-name-input">{{'global.common.project-name'|translate}}</label>
              <input type="text" class="form-control" id="project-name-input" formControlName="projectName">
            </div>
          </div>
          <div class="col form-group">
            <label class="mb-1" for="date-input">{{'global.common.date'|translate}}</label>
            <div class="d-flex">
              <input type="text" class="form-control" id="date-input" readonly bsDaterangepicker formControlName="date"
                     [bsConfig]="{rangeInputFormat: 'DD/MM/YYYY', showPreviousMonth: true, containerClass: 'theme-red'}">
              <span *ngIf="advancedSearchForm.get('date').value" class="adv-search-date-icon" (click)="clearAdvanceSearchDate('date')">×</span>
            </div>
          </div>
          <div class="col form-group">
            <label class="mb-1"
                    for="type-input">{{'global.common.status'|translate}}</label>
            <ng-select [items]="status"
                        [multiple]="true"
                        [closeOnSelect]="false"
                        [hideSelected]="false"
                        [labelForId]="'type-input'"
                        [searchable]="true"
                        formControlName="status"
                        class="ng-select-custom" id="type-input">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{index}}" type="checkbox" [checked]="item$.selected"/>
                  {{'global.status.' + item.toLowerCase() |translate}}
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items | slice:0:1">
                    <span class="ng-value-label">{{'global.status.' + item.toLowerCase() |translate}}</span>
                  </div>
                  <div class="ng-value" *ngIf="items.length > 1">
                    <span class="ng-value-label">{{items.length - 1}} {{'global.common.more-ellipsis'|translate}}</span>
                  </div>
                </ng-template>
            </ng-select>
            </div>
          <div class="col d-flex align-items-end pl-0">
            <button class="btn btn-outline-primary px-5 mb-3" type="submit" [disabled]="advancedSearchForm.pristine">
              {{'global.button.search'|translate}}
            </button>
          </div>
        </div>
      </form>
      <ng-template #basicSearchBlock>
        <div class="row mb-3" @slideIn>
          <div class="col-sm-9">
            <div class="row">
              <div class="col-sm-6">
                <div class="input-group search-input-group">
                  <label for="example-search-input"></label>
                  <input class="form-control search-input border-right-0 border" type="search" value="search"
                         id="example-search-input"
                         [value]="basicSearch"
                         placeholder='{{"global.common.search_ellipsis"|translate}}'
                         (input)="triggerBasicSearch($event.target.value)">
                  <span class="input-group-append">
                    <div class="input-group-text bg-transparent">
                      <i class="fa fa-search"></i>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3 d-none d-md-block a">
            <button class="btn btn-outline-info float-right" (click)="createOnCall()"
              [disabled]="onCallEnvelopeList.length==0">
              <i class="las la-plus"></i> {{'On_call.new_on_call'|translate}}
            </button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 form-inline">
      <select name="result" id="select-result" class="custom-select size-select"
              [(ngModel)]="size" (change)="changeSize()">
        <option [value]="5">5</option>
        <option [value]="10">10</option>
        <option [value]="20">20</option>
      </select>
      <label for="select-result">&nbsp; {{'global.pages.results_per_page'|translate}}</label>
    </div>
    <div class="col-md-6 my-auto">
        <span class="float-right"> {{'global.pages.showing'|translate}}
          <strong>{{numberOfElements}} {{'global.pages.of'|translate}} {{totalElements}} {{'global.pages.entries'|translate}}</strong> </span>
    </div>
  </div>

  <div class="row slide-in-animation">

    <div class="col-md-12">

      <table class="table table-sm table-responsive-sm table-outline mb-0">

        <thead class="mb-4">
        <tr class="text-center">
          <th scope="col" class="cursor-pointer" (click)="sortColumn('purchaseOrderNumber')">
            {{'On_call.po_num'|translate}}
            <i *ngIf="sort !== 'purchaseOrderNumber'" class="fa fa-sort float-right"></i>
            <i *ngIf="sort === 'purchaseOrderNumber' && sortDirection === 'asc'"
               class="fa fa-sort-asc float-right"></i>
            <i *ngIf="sort === 'purchaseOrderNumber' && sortDirection === 'desc'"
               class="fa fa-sort-desc float-right"></i>
          </th>
          <th scope="col" class="cursor-pointer" (click)="sortColumn('buyerCompanyName')">
            {{'On_call.client'|translate}}
            <i *ngIf="sort !== 'buyerCompanyName'" class="fa fa-sort float-right"></i>
            <i *ngIf="sort === 'buyerCompanyName' && sortDirection === 'asc'"
               class="fa fa-sort-asc float-right"></i>
            <i *ngIf="sort === 'buyerCompanyName' && sortDirection === 'desc'"
               class="fa fa-sort-desc float-right"></i>
          </th>
          <th scope="col" class="cursor-pointer" (click)="sortColumn('buyer.projectName')">
            {{'On_call.project_name'|translate}}
            <i *ngIf="sort !== 'buyer.projectName'" class="fa fa-sort float-right"></i>
            <i *ngIf="sort === 'buyer.projectName' && sortDirection === 'asc'"
               class="fa fa-sort-asc float-right"></i>
            <i *ngIf="sort === 'buyer.projectName' && sortDirection === 'desc'"
               class="fa fa-sort-desc float-right"></i>
          </th>
          <th scope="col" class="cursor-pointer" (click)="sortColumn('config.workItemReference')">
            {{'On_call.type'|translate}}
            <i *ngIf="sort !== 'config.workItemReference'" class="fa fa-sort float-right"></i>
            <i *ngIf="sort === 'config.workItemReference' && sortDirection === 'asc'"
               class="fa fa-sort-asc float-right"></i>
            <i *ngIf="sort === 'config.workItemReference' && sortDirection === 'desc'"
               class="fa fa-sort-desc float-right"></i>
          </th>
          <th scope="col" class="cursor-pointer" (click)="sortColumn('config.workUnit')">
            {{'On_call.work_unit'|translate}}
            <i *ngIf="sort !== 'config.workUnit'" class="fa fa-sort float-right"></i>
            <i *ngIf="sort === 'config.workUnit' && sortDirection === 'asc'"
               class="fa fa-sort-asc float-right"></i>
            <i *ngIf="sort === 'config.workUnit' && sortDirection === 'desc'"
               class="fa fa-sort-desc float-right"></i>
          </th>
          <th scope="col" class="cursor-pointer" (click)="sortColumn('date')">
            {{'On_call.date'|translate}}
            <i *ngIf="sort !== 'date'" class="fa fa-sort float-right"></i>
            <i *ngIf="sort === 'date' && sortDirection === 'asc'"
               class="fa fa-sort-asc float-right"></i>
            <i *ngIf="sort === 'date' && sortDirection === 'desc'"
               class="fa fa-sort-desc float-right"></i>
          </th>
          <th scope="col" class="cursor-pointer" (click)="sortColumn('submissionDate')">
            {{'Activity_report.submission_date'|translate}}
            <i *ngIf="sort !== 'submissionDate'" class="fa fa-sort float-right"></i>
            <i *ngIf="sort === 'submissionDate' && sortDirection === 'asc'"
               class="fa fa-sort-asc float-right"></i>
            <i *ngIf="sort === 'submissionDate' && sortDirection === 'desc'"
               class="fa fa-sort-desc float-right"></i>
          </th>
          <th scope="col" class="cursor-pointer" (click)="sortColumn('time')">
            {{'On_call.start_time'|translate}}
            <i *ngIf="sort !== 'time'" class="fa fa-sort float-right"></i>
            <i *ngIf="sort === 'time' && sortDirection === 'asc'"
               class="fa fa-sort-asc float-right"></i>
            <i *ngIf="sort === 'time' && sortDirection === 'desc'"
               class="fa fa-sort-desc float-right"></i>
          </th>
          <th scope="col" class="cursor-pointer" (click)="sortColumn('quantity')">
            {{'On_call.quantity'|translate}}
            <i *ngIf="sort !== 'quantity'" class="fa fa-sort float-right"></i>
            <i *ngIf="sort === 'quantity' && sortDirection === 'asc'"
               class="fa fa-sort-asc float-right"></i>
            <i *ngIf="sort === 'quantity' && sortDirection === 'desc'"
               class="fa fa-sort-desc float-right"></i>
          </th>
          <th *ngIf="!isProvider()" scope="col" class="cursor-pointer" (click)="sortColumn('priceAmount')">
            {{'On_call.price_amount'|translate}}
            <i *ngIf="sort !== 'priceAmount'" class="fa fa-sort float-right"></i>
            <i *ngIf="sort === 'priceAmount' && sortDirection === 'asc'"
               class="fa fa-sort-asc float-right"></i>
            <i *ngIf="sort === 'priceAmount' && sortDirection === 'desc'"
               class="fa fa-sort-desc float-right"></i>
          </th>
          <th  scope="col" class="cursor-pointer" (click)="sortColumn('status')">
            {{'On_call.status'|translate}}
            <i *ngIf="sort !== 'status'" class="fa fa-sort float-right"></i>
            <i *ngIf="sort === 'status' && sortDirection === 'asc'"
               class="fa fa-sort-asc float-right"></i>
            <i *ngIf="sort === 'status' && sortDirection === 'desc'"
               class="fa fa-sort-desc float-right"></i>
          </th>
          <th></th>
        </tr>
        </thead>

        <tbody [@listStagger]='listContent.length'>

        <tr *ngFor="let onCall of listContent" class="text-center oncall-row">
          <td>
            {{onCall.purchaseOrderNumber}}
          </td>
          <td>
            {{onCall.buyerCompanyName}}
          </td>
          <td>
            {{onCall.projectName}}
          </td>
          <td>
            {{onCall.workItemReference}} - {{onCall.workUnitDescription}}
          </td>
          <td>
            {{'rate-unit.'+onCall.workUnit|translate}}
          </td>
          <td>
            {{onCall.date |localizedDate}}
          </td>
          <td>
            {{onCall.submissionDate |localizedDate}}
          </td>
          <td>
            {{(onCall.time?.substring(0, 5)) | default: "-"}}
          </td>
          <td>
            {{onCall.quantity}}
          </td>
          <td *ngIf="!isProvider()">
            {{onCall.priceAmount | currency: onCall.currency}}
          </td>
          <td>
            <span
              class="badge badge-pill"
              [ngClass]="{'VALIDATED': 'badge-success', 'SUBMITTED': 'badge-info', 'REFUSED': 'badge-danger', 'CANCELLED': 'badge-darker-red'}[onCall.status]"
              [matTooltip]="onCall.status === 'REFUSED' ? onCall.refusalComment : null">
              {{ 'Purchase_orders.common.status.' + onCall.status | translate}}
            </span>
          </td>
          <td class="text-right">

            <isp-button
              [type]="ButtonTypeEnum.ICON"
              [colour]="IspColoursEnum.secondary"
              [icon]="faComment"
              [tooltip]='onCall.comment'
              *ngIf="onCall.comment"
            ></isp-button>
            <isp-button
              [type]="ButtonTypeEnum.ICON"
              [colour]="IspColoursEnum.tertiary"
              [icon]="faComment"
              [tooltip]='onCall.refusalComment'
              *ngIf="onCall.refusalComment"
            ></isp-button>
            <isp-button
              [type]="ButtonTypeEnum.ICON"
              [colour]="IspColoursEnum.secondary"
              [icon]="faBan"
              (trigger)="confirmOncallCancellation(onCall.id);"
              tooltip='{{"Fee.modal.cancel.cancel-oncall"|translate}}'
              *ngIf="displayCancel(onCall)"
            ></isp-button>
            <isp-button
              [type]="ButtonTypeEnum.ICON"
              [colour]="IspColoursEnum.primary"
              [icon]="faDownload"
              (trigger)="downloadFile(onCall);"
              *ngIf="onCall.filename"
            ></isp-button>
          </td>
        </tr>

        </tbody>

      </table>

    </div>

  </div>

  <div class="row mt-4 slide-in-animation" *ngIf="totalElements > size">
    <div class="col-md-12">
      <pagination class="float-right" [totalItems]="totalElements" [maxSize]="5"
                  [boundaryLinks]="true"
                  [ngModel]="page"
                  (pageChanged)="changePage($event)"
                  [itemsPerPage]="size"
                  previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;"
                  lastText="&raquo;">
      </pagination>
    </div>
  </div>

</div>
