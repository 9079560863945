import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, firstValueFrom} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router} from '@angular/router';
import {AccountValidationModel} from '../../shared/models/authentication/account-validation.model';
import {
  AdministrativeManagerModel,
  CompanyCreationBodyModel
} from '../../shared/models/authentication/company-creation-body.model';
import {UserCredentialsModel} from '../../shared/models/authentication/user-credentials.model';
import {NGXLogger} from 'ngx-logger';
import {AuthorityRoleEnum} from '../../shared/enums/AuthorityRoleEnum';
import {commonProperties} from '../../../assets/environments/environment.common';
import {environment} from '../../../assets/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private httpClient: HttpClient,
              private jwtHelper: JwtHelperService,
              private router: Router,
              private logger: NGXLogger) {
  }

  static readonly TOKEN = 'access_token';
  static readonly NAME_CLAIM = 'name';
  static readonly AUTHORITIES_CLAIM = 'authorities';
  static readonly TENANT_ID_CLAIM = 'tenantId';
  static readonly LANGUAGE_CLAIM = 'lang';
  static readonly EMPLOYEE_ID_CLAIM = 'employeeId';

  private usernameSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private pictureSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private isCguValidated: boolean|undefined = undefined;

  mapFormValueToResource(value: any): CompanyCreationBodyModel {
    const result: CompanyCreationBodyModel = new CompanyCreationBodyModel();

    result.name = value.name;
    result.type = value.type;
    result.origin = value.origin.alpha2Code;
    result.incorporationNumber = value?.incorporationNumber;

    const administrativeManager: AdministrativeManagerModel = new AdministrativeManagerModel();
    administrativeManager.email = value.administrativeManager.email;
    administrativeManager.firstName = value.administrativeManager.firstName;
    administrativeManager.lastName = value.administrativeManager.lastName;
    administrativeManager.phoneNumber = value.administrativeManager.phoneNumber.internationalNumber.replace(/\s/g, '').replace('-', '');

    result.administrativeManager = administrativeManager;
    return result;
  }

  createCompany(params: any) {
    return this.httpClient.post(environment.api_root + commonProperties.companyCreation, this.mapFormValueToResource(params));
  }

  testUserEmail(userEmail: any): Observable<any> {
    let params = new HttpParams();
    params = params.set('email', userEmail);
    return this.httpClient.get(environment.api_root + commonProperties.userEmailTest, {params});
  }

  login(userCredentials: UserCredentialsModel) {
    return this.httpClient.post(environment.api_root + commonProperties.login, userCredentials);
  }

  storeToken(res: any) {
    localStorage.setItem(commonProperties.token, res.access_token);
    localStorage.setItem(commonProperties.tokenExpiration, res.expires_in);
    this.setUsername(this.jwtHelper.decodeToken(localStorage.getItem(AuthenticationService.TOKEN))[AuthenticationService.NAME_CLAIM]);
  }

  setUsername(username: string) {
    this.usernameSubject.next(username);
  }

  isAuthenticated() {
    return !this.jwtHelper.isTokenExpired();
  }

  logout() {
    const token = localStorage.getItem(AuthenticationService.TOKEN);
    this.isCguValidated = undefined;
    localStorage.clear();
    if (token) {
      this.httpClient.post(environment.api_root + commonProperties.logout, {token})
        .subscribe(() => {
          this.router.navigate(['/login']);
        }, () => {
          this.router.navigate(['/login']);
        });
    } else {
      this.router.navigate(['/login']);
    }
  }


  decodeToken(key?: string): string {
    const token = this.jwtHelper.decodeToken(localStorage.getItem(AuthenticationService.TOKEN));
    if (key) {
      return token[key];
    } else {
      return token;
    }
  }

  getRole() {
    if (this.isAuthenticated()) {
      return this.jwtHelper.decodeToken(localStorage.getItem(AuthenticationService.TOKEN))[AuthenticationService.AUTHORITIES_CLAIM];
    } else {
      // this.logger.debug('User not authenticated.');
    }
  }

  getEmployeeId() {
    if (this.isAuthenticated()) {
      return this.jwtHelper.decodeToken(localStorage.getItem(AuthenticationService.TOKEN))[AuthenticationService.EMPLOYEE_ID_CLAIM];
    } else {
      // this.logger.debug('User not authenticated.');
    }
  }

  getEmployeeAccount(employeeId: string) {
    const params = new HttpParams().set('employeeId', employeeId);
    return this.httpClient.get(environment.api_root + commonProperties.accountByEmployeeId, {params: params});
  }

  getMyEmployeeAccount() {
    const params = new HttpParams().set('employeeId', this.decodeToken('employeeId'));
    return this.httpClient.get(environment.api_root + commonProperties.accountByEmployeeId, {params: params});
  }

  getCompanyStatus() {
    if (this.isAuthenticated()) {
      return this.jwtHelper.decodeToken(localStorage.getItem(AuthenticationService.TOKEN)).enterpriseStatus;
    } else {
      return null;
    }
  }

  getCompanyId() {
    if (this.isAuthenticated()) {
      return this.jwtHelper.decodeToken(localStorage.getItem(AuthenticationService.TOKEN))[AuthenticationService.TENANT_ID_CLAIM];
    } else {
      return null;
    }
  }

  forgetPassword(email: string) {
    const params = new HttpParams().set('email', email);
    return this.httpClient.get(environment.api_root + commonProperties.forgetPassword, {params: params});
  }

  /**
   * Reset a password by specifying the token received by email and the new password
   * @param token reset password token
   * @param password new password to set for the account
   */
  resetPassword(token: string, password: string) {
    const params = new HttpParams().set('resetKey', token);
    return this.httpClient.post(environment.api_root + commonProperties.resetPassword, new AccountValidationModel(password), {params});
  }

  getUserLang() {
    if (this.isAuthenticated()) {
      return this.jwtHelper.decodeToken(localStorage.getItem(AuthenticationService.TOKEN)).lang;
    } else {
      return null;
    }
  }

  updateUserLang(body: any) {
    return this.httpClient.put(environment.api_root + commonProperties.lang.replace(':employeeId', this.decodeToken('employeeId')), body);
  }

  getName() {
    if (this.isAuthenticated()) {
      return this.jwtHelper.decodeToken(localStorage.getItem(AuthenticationService.TOKEN))[AuthenticationService.NAME_CLAIM];
    } else {
      return null;
    }
  }

  getUsername() {
    if (this.isAuthenticated() && !this.usernameSubject.value) {
      this.setUsername(this.jwtHelper.decodeToken(localStorage.getItem(AuthenticationService.TOKEN))[AuthenticationService.NAME_CLAIM]);
    }
    return this.usernameSubject.asObservable();
  }

  validateAccount(token: string, password: string) {
    const params = new HttpParams().set('activationKey', token);
    return this.httpClient.post(environment.api_root + commonProperties.activateAccount, new AccountValidationModel(password), {params: params});
  }

  refreshToken() {
    const token = localStorage.getItem(AuthenticationService.TOKEN);
    const refreshTokenBody = {
      token
    };
    return this.httpClient.post(environment.api_root + commonProperties.refreshToken, refreshTokenBody);

  }

  setPicture(photo: any) {
    this.pictureSubject.next(photo);
  }

  getPicture() {
    return this.pictureSubject.asObservable();
  }

  isUserProvider() {
    return this.getRole() === AuthorityRoleEnum.PROVIDER;
  }

  isUserFreelancer() {
    return this.getRole() === AuthorityRoleEnum.FREELANCER;
  }

  changePassword(params) {
    return this.httpClient.post(environment.api_root + commonProperties.changePassword, params);
  }

  getCgu(): Observable<any> {
    const url = environment.api_root + commonProperties.cgu;
    const headers = new HttpHeaders();
    const options = {
      headers: headers.set('Accept', 'application/pdf'),
      responseType: 'blob' as 'json'
    };
    return this.httpClient.get<Blob>(url, options);
  }

  async getCguStatus(): Promise<boolean>  {
    if (this.isCguValidated === undefined) {
      const url = environment.api_root + commonProperties.cguUser;
      this.isCguValidated = await firstValueFrom(this.httpClient.get<boolean>(url));
    }
    return this.isCguValidated;
  }

  acceptCgu(): Observable<boolean>  {
    this.isCguValidated = true;
    const url = environment.api_root + commonProperties.cguUser;
    const params = new HttpParams().set('accepted', true);

    return this.httpClient.post<boolean>(url, null, {params});
  }

  getUserId() {
    return this.jwtHelper.decodeToken(localStorage.getItem(AuthenticationService.TOKEN))['userId'];
  }
}

