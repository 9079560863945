import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {ActivityAreaModel} from '../../../views/company-config/activity-areas/activity-area.model';
import {ActivityAreasService} from '../../../views/company-config/services/activity-areas.service';
import {NgSelectConfigModel} from './ng-select-config.model';

@Component({
  selector: 'app-activity-area-dropdown',
  templateUrl: './activity-area-dropdown.component.html',
  styleUrls: ['./activity-area-dropdown.component.scss'],
})
export class ActivityAreaDropdownComponent implements OnInit {
  activityAreas: ActivityAreaModel[] = [];
  @Output() change = new EventEmitter<ActivityAreaModel[]>();
  @Output() hasContent = new EventEmitter<boolean>();
  hasEmitted: boolean = false;
  @Input() parentActivityArea: ActivityAreaModel;
  @Input() isMultiple: boolean = false;
  @Input() config: NgSelectConfigModel;
  @Input() selectedActivityAreas: ActivityAreaModel[];
  @Input() placeholder: string;

  constructor(
    private service: ActivityAreasService,
  ) {
  }

  ngOnInit(): void {
    this.searchActivityArea(this.parentActivityArea);
  }

  searchActivityArea(parentActivityArea?: ActivityAreaModel) {
    this.service.getAll().subscribe(data => {
      const final = [];
      for (const item of data) {
        if ((!parentActivityArea && !item.parentActivityAreaId) || item.parentActivityAreaId === parentActivityArea?.id) {
          final.push(item);
        }
      }
      this.activityAreas = final;
      if (!this.hasEmitted) {
        this.hasContent.emit(this.activityAreas.length > 0);
        this.hasEmitted = true;
      }
    });
  }

}
