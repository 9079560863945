import {FeeCategoryEnum} from '../../enums/FeeCategoryEnum';
import {FeeStatusEnum} from '../../enums/Fee.status.enum';

export class FeeDetailsModel {
  id: string;
  projectName: string;
  purchaseOrderNumber: string;
  purchaseOrderId: number;
  buyerCompanyName: string;
  buyerTenantId: string;
  date: string;
  category: FeeCategoryEnum;
  amount: number;
  status: FeeStatusEnum;
  amountIncludingTax: number;
  amountExcludingTax: number;
  taxAmount: number;
  refusalComment: string;
  comment: string;
  feeFileName: string;
  origin: string;
  subjectToVat: boolean;
  expenditureCountry: string;
}
