<div class="row">
  <div class="col-md-12">
    <div class="header">{{ "Invoice.invoice" | translate }}</div>
  </div>
</div>

<ng-container *ngIf="!firstCallDone; else firstCallDoneBlock">
  <app-spinner [backgroundTransparent]="true"></app-spinner>
</ng-container>

<ng-template #firstCallDoneBlock>
  <div *ngIf="!isListEmpty; else noCompanyBlock" @slideInStagger>
    <!-- basic search and advanced search forms -->
    <div class="row mb-3 slide-in-animation">
      <div class="col" [ngClass]="{'col-md-5': !isAdvancedSearchDisplayed,'col-md-12': isAdvancedSearchDisplayed}">
        <div class="text-right" [ngClass]="{ 'offset-advanced-search': isAdvancedSearchDisplayed }">
          <button class="advanced-search-button" (click)="toggleAdvancedSearch()">
            <u>{{ "Invoice.advanced_search" | translate }}</u>&nbsp;
            <i class="fa ml-1" [ngClass]="{'fa-angle-down': isAdvancedSearchDisplayed,'fa-angle-left': !isAdvancedSearchDisplayed}" aria-hidden="true"></i>
          </button>
        </div>

        <form
          [formGroup]="advancedSearchForm"
          (ngSubmit)="advancedSearchFormSubmit()"
          *ngIf="isAdvancedSearchDisplayed; else basicSearchBlock"
          @fadeIn
        >
          <div class="row">
            <div class="col form-group">
              <label class="mb-1 advanced-search-label" for="invoice-number-input">{{ "Invoice.search_fields.invoice_number" | translate }}</label>
              <input type="text" class="form-control" id="invoice-number-input" formControlName="number"/>
            </div>
            <div class="col form-group">
              <label class="mb-1 advanced-search-label" for="purchase-order-number-input">{{ "Invoice.search_fields.po_number" | translate }}</label>
              <input type="text" class="form-control" id="purchase-order-number-input" formControlName="purchaseOrderNumber"/>
            </div>
            <div class="col form-group">
              <label class="mb-1 advanced-search-label" for="buyer-name-input">{{ "Invoice.search_fields.buyer" | translate }}</label>
              <input type="text" class="form-control" id="buyer-name-input" formControlName="buyerName"/>
            </div>
            <div class="col form-group">
              <label class="mb-1 advanced-search-label" for="status-input">{{ "Invoice.search_fields.status" | translate }}</label>
              <select class="custom-select" id="status-input" formControlName="status">
                <option selected></option>
                <option [value]="status" *ngFor="let status of status">
                  {{ 'Invoice.status.'+status | translate }}
                </option>
              </select>
            </div>
            <div class="col form-group">
              <label class="mb-1 advanced-search-label" for="status-input">{{ "Invoice.search_fields.mode" | translate }}</label>
              <select class="custom-select" id="mode-input" formControlName="mode">
                <option selected></option>
                <option [value]="mode" *ngFor="let mode of modes">
                  {{ mode | titlecase | statusReadable }}
                </option>
              </select>
            </div>
            <div class="col form-group">
              <label class="mb-1 advanced-search-label" for="type-input">{{ "Invoice.search_fields.type" | translate }}</label>
              <ng-select
                [items]="activityTypes"
                [multiple]="true"
                [closeOnSelect]="false"
                [hideSelected]="false"
                [labelForId]="'type-input'"
                [searchable]="true"
                formControlName="activityType"
                class="ng-select-custom"
                id="type-input"
              >
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{ index }}" type="checkbox" [checked]="item$.selected"/>{{ 'Invoice.type.'+item | translate }}
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items | slice: 0:1">
                    <span class="ng-value-label">
                      {{ 'Invoice.type.'+item | translate }}
                    </span>
                  </div>
                  <div class="ng-value" *ngIf="items.length > 1">
                    <span class="ng-value-label">
                      {{ items.length - 1 }} more...</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col form-group">
              <label class="mb-1 advanced-search-label" for="contractMode-input">{{ "Invoice.search_fields.contract_mode" | translate }}</label>
              <ng-select
                [items]="contractModes"
                [multiple]="true"
                [closeOnSelect]="false"
                [hideSelected]="false"
                [labelForId]="'contractMode-input'"
                [searchable]="true"
                formControlName="contractMode"
                class="ng-select-custom"
                id="contractMode-input"
              >
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{ index }}" type="checkbox" [checked]="item$.selected"/>
                  {{"Invoice.mode." + item| translate }}
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items | slice: 0:1">
                    <span class="ng-value-label">{{"Invoice.mode." + item| translate }}</span>
                  </div>
                  <div class="ng-value" *ngIf="items.length > 1">
                    <span class="ng-value-label">
                      {{ items.length - 1 }} {{ "global.common.more-ellipsis" | translate }}
                    </span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col form-group">
              <label class="mb-1 advanced-search-label" for="credited-input">{{"Invoice.credited" | translate }}</label>
              <select class="custom-select" id="credited-input" formControlName="credited">
                <option selected></option>
                <option [value]="true">{{ "Invoice.yes" | translate }}</option>
                <option [value]="false">{{ "Invoice.no" | translate }}</option>
              </select>
            </div>

            <div class="col d-flex align-items-end pl-0">
              <button class="btn btn-outline-primary px-5 mb-3" type="submit" [disabled]="advancedSearchForm.pristine">
                {{"Invoice.button_search"|translate}}
              </button>
            </div>
          </div>
        </form>

        <ng-template #basicSearchBlock>
          <div class="input-group search-input-group">
            <label for="search"></label>
            <input
              type="text"
              class="form-control search-input border-right-0 border"
              placeholder="{{ 'global.common.search_ellipsis' | translate }}"
              id="search"
              [value]="basicSearch"
              (input)="triggerBasicSearch($event.target.value)"
            />
            <span class="input-group-append">
              <div class="input-group-text bg-transparent">
                <fa-duotone-icon [icon]="faSearch" class="primary"></fa-duotone-icon>
              </div>
            </span>
          </div>
        </ng-template>
      </div>
    </div>

    <!-- line for page size and message of number of elements of the page -->
    <div class="row justify-content-between align-items-center mb-3 slide-in-animation">
      <div class="col-md-3 form-inline">
        <select name="result" id="select-result" class="custom-select mr-2" [(ngModel)]="size" (change)="changeSize()">
          <option [value]="5">5</option>
          <option [value]="10">10</option>
          <option [value]="20">20</option>
        </select>

        <label for="select-result">{{ "global.pages.results_per_page" | translate }}</label>
      </div>
      <div class="col-md-2">
        <span class="float-right">{{ "global.pages.showing" | translate }}
          <strong>{{ numberOfElements }} {{ "global.pages.of" | translate }} {{ totalElements }}</strong>
          {{ "global.pages.entries" | translate }}
        </span>
      </div>
    </div>

    <div *ngIf="listContent.length > 0; else noResultCompanyBlock" @slideInStagger>
      <div class="row slide-in-animation">
        <div class="col-md-12">
          <table
            class="table table-material box-shadow-hover table-responsive-sm"
          >
            <thead class="thead-normal">
            <tr class="text-center">
              <th
                scope="col"
                class="cursor-pointer"
                (click)="sortColumn('number')"
                title="Sort number"
              >
                {{ "Invoice.number" | translate }}
                <i *ngIf="sort !== 'number'" class="fa fa-sort"></i>
                <i
                  *ngIf="sort === 'number' && sortDirection === 'asc'"
                  class="fa fa-sort-asc"
                ></i>
                <i
                  *ngIf="sort === 'number' && sortDirection === 'desc'"
                  class="fa fa-sort-desc"
                ></i>
              </th>
              <th scope="col">
                {{ "Invoice.buyer" | translate }}
              </th>
              <th
                scope="col"
                class="cursor-pointer"
                (click)="sortColumn('status')"
                title="Sort status"
              >
                {{ "Invoice.status.title" | translate }}
                <i *ngIf="sort !== 'status'" class="fa fa-sort"></i>
                <i *ngIf="sort === 'status' && sortDirection === 'asc'" class="fa fa-sort-asc"></i>
                <i *ngIf="sort === 'status' && sortDirection === 'desc'" class="fa fa-sort-desc"></i>
              </th>
              <th scope="col">
                {{ "Invoice.type.title" | translate }}
              </th>
              <th
                scope="col"
                class="cursor-pointer"
                (click)="sortColumn('mode')"
                title="Sort mode"
              >
                {{ "Invoice.search_fields.mode" | translate }}
                <i *ngIf="sort !== 'mode'" class="fa fa-sort"></i>
                <i *ngIf="sort === 'mode' && sortDirection === 'asc'" class="fa fa-sort-asc"></i>
                <i *ngIf="sort === 'mode' && sortDirection === 'desc'" class="fa fa-sort-desc"></i>
              </th>
              <th
                scope="col"
                class="cursor-pointer"
                (click)="sortColumn('createdDate')"
                title="Sort date"
              >
                {{ "Invoice.date" | translate }}
                <i *ngIf="sort !== 'createdDate'" class="fa fa-sort"></i>
                <i *ngIf="sort === 'createdDate' && sortDirection === 'asc'" class="fa fa-sort-asc"></i>
                <i *ngIf="sort === 'createdDate' && sortDirection === 'desc'" class="fa fa-sort-desc"></i>
              </th>
              <th scope="col">
                {{ "Invoice.period" | translate }}
              </th>
              <th scope="col">
                {{ "Invoice.provider" | translate }}
              </th>
              <th scope="col">
                {{ "Invoice.credited" | translate }}
              </th>
              <th scope="col">Actions</th>
            </tr>
            </thead>

            <tbody>
            <tr class="text-center" *ngFor="let invoice of listContent">
              <td>
                {{ invoice.number }}
                <div class="small text-muted">
                  <span>{{ "Invoice.po_n" | translate }}</span> |
                  {{ invoice.purchaseOrders?.join(', ') }}
                </div>
              </td>

              <td>{{ invoice.buyers?.join(', ') }}</td>

              <td>
                  <span
                    class="badge"
                    [ngClass]="{
                      'badge-primary': invoice.status === InvoicingStatusEnum.REFUSED,
                      'badge-success': invoice.status === InvoicingStatusEnum.VALIDATED,
                      'badge-warning': invoice.status === InvoicingStatusEnum.TRANSMITTED
                    }"
                    matTooltip="{{invoice.moderationReason}}" placement="bottom"
                  >
                    {{ 'Invoice.invoice_list.status.' + invoice.status | translate }}
                  </span>
              </td>

              <td>
                <ng-container *ngFor="let type of invoice.activityTypes">{{ type | statusReadable }}</ng-container>
              </td>

              <td>
                  <span
                    class="badge"
                    [ngClass]="{
                      'badge-info': invoice.mode === 'MANUAL',
                      'badge-success': invoice.mode === 'AUTO'
                    }"
                  >
                    {{ 'Invoice.invoice_list.mode.' + invoice.mode| translate }}
                  </span>
              </td>

              <td>{{ invoice.transmissionDate | localizedDate }}</td>

              <td *ngIf="invoice.periods?.length>0">
                <ng-container
                  *ngFor="let period of invoice.periods">{{ period | localizedDate: "MMM YYYY" | default: ("global.common.not-set"|translate) }}
                </ng-container>
              </td>
              <td *ngIf="!invoice.periods ||invoice.periods.length == 0">
                {{"global.common.not-set"|translate}}
              </td>

              <td>{{ invoice.employees?.join(', ') | default: ("global.common.not-set"|translate) }}</td>

              <td>
                <fa-duotone-icon *ngIf="invoice.credited" [icon]="faFileCircleXmark" class="secondary"></fa-duotone-icon>
              </td>

              <td class="d-flex flex-row justify-content-around">
                <a *ngIf="!invoice.credited && ([InvoiceStatusEnum.VALIDATED, InvoiceStatusEnum.TO_BE_PAID].includes(invoice.status))" [title]="'Invoice.request_credit_note' | translate" [routerLink]="['/accounting/invoice', invoice.id, 'credit-note-generation']">
                  <fa-duotone-icon [icon]="faFileInvoice"></fa-duotone-icon>
                </a>

                <a [title]="'Invoice.cancel-credit-note-requested' | translate" *ngIf="invoice.creditNoteRequested && !invoice.credited" (click)="cancelCreditNote(invoice.id)">
                  <fa-duotone-icon [icon]="faBan"></fa-duotone-icon>
                </a>

                <a *ngIf="invoice.status == InvoiceStatusEnum.VALIDATED" [title]="'Invoice.download_invoice_file' | translate"
                   (click)="downloadInvoice(invoice)">
                  <fa-duotone-icon [icon]="faDownload"></fa-duotone-icon>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- pagination -->
      <div class="row slide-in-animation" *ngIf="totalElements > size">
        <div class="col-md-12">
          <pagination
            class="float-right"
            [totalItems]="totalElements"
            [maxSize]="5"
            [boundaryLinks]="true"
            [ngModel]="page"
            (pageChanged)="changePage($event)"
            [itemsPerPage]="size"
            previousText="&lsaquo;"
            nextText="&rsaquo;"
            firstText="&laquo;"
            lastText="&raquo;"
          >
          </pagination>
        </div>
      </div>
    </div>

    <ng-template #noResultCompanyBlock>
      <div class="text-center my-3" @slideIn>
        <img
          src="assets/img/result_empty.svg"
          alt="No results"
          style="width: 30rem"
        />
        <h5 class="font-weight-bold mt-3">
          {{ "Invoice.no_data" | translate }}
        </h5>
      </div>
    </ng-template>
  </div>

  <ng-template #noCompanyBlock>
    <app-no-content
      class="company-list-empty"
      content="{{ 'Invoice.no_invoices_yet' | translate }}"
      iconClassName="fa fa-money"
      @slideIn
    >
    </app-no-content>
  </ng-template>
</ng-template>
