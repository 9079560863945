import {Component, EventEmitter, Input, Output} from '@angular/core';
import * as moment from 'moment';
import {faFile, faGlobe, faIndustry, faInstitution, faMapMarker, faTag} from '@fortawesome/free-solid-svg-icons';
import {faCalendar, faClock, faMoneyBill1} from '@fortawesome/free-regular-svg-icons';
import {CallForTenderListItemModel} from '../../../../shared/models/call-for-tender/call-for-tender-list-item.model';
import {ActivityAreaModel} from '../../../company-config/activity-areas/activity-area.model';


@Component({
  selector: 'app-call-for-tender-list-card',
  templateUrl: './call-for-tender-list-card.component.html',
  styleUrls: ['./call-for-tender-list-card.component.scss']
})
export class CallForTenderListCardComponent {
  @Input() callForTender: CallForTenderListItemModel;
  @Input() activityAreas: ActivityAreaModel[];
  @Output() callForTenderDetail = new EventEmitter<{ id: string }>();
  protected readonly faTag = faTag;
  protected readonly faCalendar = faCalendar;
  protected readonly faClock = faClock;
  protected readonly faMapMarker = faMapMarker;
  protected readonly faGlobe = faGlobe;
  protected readonly faInstitution = faInstitution;
  protected readonly faFile = faFile;
  protected readonly faMoneyBill1 = faMoneyBill1;
  protected readonly faIndustry = faIndustry;
  page: number = 0;

  stringifyListOfLeagues(): string {
    return this.callForTender.leagues.map(league => league.name).join(', ');
  }

  onCallForTenderDetail(): void {
    this.callForTenderDetail.emit({id: this.callForTender.id});
  }

  shouldDisplayRange(): boolean {
    if (this.callForTender.contractMode === 'FIXED_PRICE') {
      return this.callForTender.minAmount &&
        this.callForTender.minAmount !== 0 &&
        this.callForTender.minAmount !== this.callForTender.maxAmount;
    } else {
      return this.callForTender.minDailyRate &&
        this.callForTender.minDailyRate !== 0 &&
        this.callForTender.minDailyRate !== this.callForTender.maxDailyRate;
    }
  }

  getActivityAreas(cft: CallForTenderListItemModel) {
    if (!cft?.activityAreaIds?.length) {
      return '';
    }

    return cft.activityAreaIds
      .map(id => this.activityAreas.find(area => area.id === id)?.label)
      .filter(label => label) // Remove undefined values
      .join(', ');
  }
}
