import {ActivityTypeEnum} from './activity-type.enum';

export class InvoiceItemResource {
  activityType: ActivityTypeEnum;
  itemId?: number;
  itemUuid?: string;
}

export class ProformaBodyResourceModel {
  fileContent?: string;
  taxAmount?: number;
  taxlessTotal?: number;
  totalIncludingTax?: number;
  proforma: boolean = true;
}

export class InvoiceProformaBodyResourceModel extends ProformaBodyResourceModel {
  items: InvoiceItemResource[];
  daysUntilDue: number;
  invoiceDate: Date;
  invoiceNumber: string;
}

export class CreditNoteProformaBodyResourceModel extends ProformaBodyResourceModel {
  creditNoteDate: Date;
  reason: string;
  creditNoteNumber: string;
}

export class SupplierInvoiceConfig {
  billableUnder: number;
}
