import {INavData} from '@coreui/angular';
import {AuthorityRoleEnum} from './shared/enums/AuthorityRoleEnum';
import {CompanyStatusEnum} from './shared/enums/Company.status.enum';

export const navItems: MyINavData[] = [
  {
    key: 'core.components.navbar.dashboard',
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-home',
    role: []
  },
  {
    key: 'core.components.navbar.companies',
    name: 'Companies',
    url: '/companies',
    icon: 'cil-bank',
    role: [],
    companyStatus: [CompanyStatusEnum.COMPLETED]
  },
  {
    key: 'core.components.navbar.employees',
    name: 'Employees',
    url: '/employees',
    icon: 'icon-people',
    role: [AuthorityRoleEnum.SUPPLIER, AuthorityRoleEnum.SUPPLIER_ADMIN, AuthorityRoleEnum.FREELANCER],
    companyStatus: [CompanyStatusEnum.COMPLETED]
  },
  {
    key: 'core.components.navbar.mission',
    name: 'Mission',
    url: '/mission',
    icon: 'cil-briefcase',
    role: [AuthorityRoleEnum.FREELANCER, AuthorityRoleEnum.SUPPLIER, AuthorityRoleEnum.SUPPLIER_ADMIN],
    companyStatus: [CompanyStatusEnum.COMPLETED]
  },
  {
    key: 'core.components.navbar.activity-reports',
    name: 'Activity Reports',
    url: '/activity-reports',
    icon: 'cil-file',
    role: [AuthorityRoleEnum.FREELANCER, AuthorityRoleEnum.PROVIDER],
    companyStatus: [CompanyStatusEnum.COMPLETED]
  },
  {
    key: 'core.components.navbar.activity-reports',
    name: 'Activity Reports',
    url: '/activity-reports/activity-sheets',
    icon: 'cil-file',
    role: [ AuthorityRoleEnum.SUPPLIER, AuthorityRoleEnum.SUPPLIER_ADMIN],
    companyStatus: [CompanyStatusEnum.COMPLETED]
  },
  {
    key: 'core.components.navbar.fees',
    name: 'Fees',
    url: '/fees',
    icon: 'cil-money',
    role: [AuthorityRoleEnum.FREELANCER, AuthorityRoleEnum.PROVIDER, AuthorityRoleEnum.SUPPLIER, AuthorityRoleEnum.SUPPLIER_ADMIN],
    companyStatus: [CompanyStatusEnum.COMPLETED]
  },
  {
    key: 'core.components.navbar.on-call',
    name: 'On-call',
    url: '/on-call',
    icon: 'cil-briefcase',
    role: [AuthorityRoleEnum.FREELANCER, AuthorityRoleEnum.PROVIDER, AuthorityRoleEnum.SUPPLIER, AuthorityRoleEnum.SUPPLIER_ADMIN],
    companyStatus: [CompanyStatusEnum.COMPLETED]
  },
  {
    title: true,
    key: 'core.components.navbar.contract-documents-title',
    name: 'CONTRACT DOCUMENT',
    role: [AuthorityRoleEnum.FREELANCER, AuthorityRoleEnum.SUPPLIER, AuthorityRoleEnum.SUPPLIER_ADMIN],
    companyStatus: [CompanyStatusEnum.COMPLETED]
  },
  {
    key: 'core.components.navbar.legal-documents',
    name: 'Legal documents',
    url: '/company-config/legal-documents/company-identity-document',
    icon: 'cil-description',
    badge: undefined,
    role: [AuthorityRoleEnum.FREELANCER, AuthorityRoleEnum.SUPPLIER, AuthorityRoleEnum.SUPPLIER_ADMIN],
    companyStatus: [CompanyStatusEnum.COMPLETED]
  },
  {
    key: 'core.components.navbar.contracts',
    name: 'Contracts',
    url: '/contracts',
    icon: 'cil-file',
    badge: undefined,
    role: [AuthorityRoleEnum.FREELANCER, AuthorityRoleEnum.SUPPLIER, AuthorityRoleEnum.SUPPLIER_ADMIN],
    companyStatus: [CompanyStatusEnum.COMPLETED]
  },
  {
    key: 'core.components.navbar.purchase-orders',
    name: 'Purchase orders',
    url: '/purchase-orders',
    icon: 'cil-notes',
    badge: undefined,
    role: [AuthorityRoleEnum.FREELANCER, AuthorityRoleEnum.SUPPLIER, AuthorityRoleEnum.SUPPLIER_ADMIN],
    companyStatus: [CompanyStatusEnum.COMPLETED]
  },

  {
    title: true,
    key: 'core.components.navbar.marketplace-title',
    name: 'MARKETPLACE',
    role: [AuthorityRoleEnum.FREELANCER, AuthorityRoleEnum.SUPPLIER, AuthorityRoleEnum.SUPPLIER_ADMIN],
    companyStatus: [CompanyStatusEnum.COMPLETED]
  },
  {
    key: 'core.components.navbar.calls-for-tender',
    name: 'Call for tender',
    url: '/marketplace/calls-for-tender',
    icon: 'icon-notebook',
    role: [AuthorityRoleEnum.FREELANCER, AuthorityRoleEnum.SUPPLIER, AuthorityRoleEnum.SUPPLIER_ADMIN],
    companyStatus: [CompanyStatusEnum.COMPLETED]
  },
  {
    key: 'core.components.navbar.calls-for-tender-offers',
    name: 'Offers',
    url: '/marketplace/calls-for-tender-offers',
    icon: 'icon-book-open',
    role: [AuthorityRoleEnum.FREELANCER, AuthorityRoleEnum.SUPPLIER, AuthorityRoleEnum.SUPPLIER_ADMIN],
    companyStatus: [CompanyStatusEnum.COMPLETED]
  },
  {
    title: true,
    key: 'core.components.navbar.accounting-title',
    name: 'Accounting',
    role: [AuthorityRoleEnum.FREELANCER, AuthorityRoleEnum.SUPPLIER, AuthorityRoleEnum.SUPPLIER_ADMIN],
    companyStatus: [CompanyStatusEnum.COMPLETED]
  },
  {
    key: 'core.components.navbar.to-generate',
    name: 'To Generate',
    url: '/accounting/to-generate',
    icon: 'cil-pen-alt',
    badge: undefined,
    role: [AuthorityRoleEnum.FREELANCER, AuthorityRoleEnum.SUPPLIER, AuthorityRoleEnum.SUPPLIER_ADMIN],
    companyStatus: [CompanyStatusEnum.COMPLETED]
  },
  {
    key: 'core.components.navbar.invoices',
    name: 'Invoices',
    url: '/accounting/invoices',
    icon: 'cil-wallet',
    role: [AuthorityRoleEnum.FREELANCER, AuthorityRoleEnum.SUPPLIER, AuthorityRoleEnum.SUPPLIER_ADMIN],
    companyStatus: [CompanyStatusEnum.COMPLETED]
  },
  {
    key: 'core.components.navbar.credit-notes',
    name: 'Credit Notes',
    url: '/accounting/credit-notes',
    icon: 'cil-wallet',
    role: [AuthorityRoleEnum.FREELANCER, AuthorityRoleEnum.SUPPLIER, AuthorityRoleEnum.SUPPLIER_ADMIN],
    companyStatus: [CompanyStatusEnum.COMPLETED]
  },
  {
    title: true,
    name: 'Graphical charter',
    role: []
  },
  {
    name: 'Typography',
    url: '/graphical-charter/typography',
    icon: 'icon-drop',
    role: []
  },
  {
    name: 'Alerts & Buttons',
    url: '/graphical-charter/buttons',
    icon: 'icon-pencil',
    role: []
  },
  {
    name: 'Forms',
    url: '/graphical-charter/forms',
    icon: 'icon-pencil',
    role: []
  },
  {
    name: 'Download CoreUI',
    url: 'http://coreui.io/angular/',
    icon: 'icon-cloud-download',
    class: 'mt-auto',
    variant: 'success',
    attributes: {target: '_blank', rel: 'noopener'},
    role: []
  },
  {
    name: 'Try CoreUI PRO',
    url: 'http://coreui.io/pro/angular/',
    icon: 'icon-layers',
    variant: 'danger',
    attributes: {target: '_blank', rel: 'noopener'},
    role: []
  }
];

export interface MyINavData extends INavData {
  key?: string;
  role?: string[];
  companyStatus?: string[];
}
