import {Component, Input, OnInit} from '@angular/core';
import {RequestDetailModel} from '../../../../../../shared/models/mission/mission-detail.model';
import {
  PurchaseOrderItemOriginalTypeEnum,
  PurchaseOrderItemTypeEnum,
  PurchaseOrderModel,
} from '../../../../../../shared/models/purchase-order/purchase-order.model';
import {PurchaseOrderService} from '../../../../../purchase-order/services/purchase-order-service';
import {MissionService} from '../../../../services/mission.service';
import {ProviderModel} from '../../../../../../shared/models/mission/provider.model';
import {faCalendarWeek, faHeadset, faMoneyBills} from '@fortawesome/pro-duotone-svg-icons';
import {IspColoursEnum} from '@i-supplier/angular-shared-module';
import {ContractModeEnum} from '../../../../../invoice/models/contract-mode.enum';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-request-purchase-order-detail',
  templateUrl: './request-purchase-order-detail.component.html',
  styleUrls: ['./request-purchase-order-detail.component.scss'],
})
export class RequestPurchaseOrderDetailComponent implements OnInit {
  @Input() providers: ProviderModel[];
  @Input() requestId: string;
  PurchaseOrderItemTypeEnum = PurchaseOrderItemTypeEnum;
  protected readonly ContractModeEnum = ContractModeEnum;
  protected readonly IspColoursEnum = IspColoursEnum;
  protected readonly faCalendarWeek = faCalendarWeek;
  protected readonly faMoneyBills = faMoneyBills;
  protected readonly faHeadset = faHeadset;
  purchaseOrder: PurchaseOrderModel;
  request: RequestDetailModel;

  constructor(
    private requestService: MissionService,
    private purchaseOrderService: PurchaseOrderService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
  ) {}


  ngOnInit(): void {
    this.loadData();
  }

  private generateNoProviderPO(): void {
    const provider = this.request.requestClientStepResource.billing.initialRequest;
    this.purchaseOrder.items.push({
      quantity: 1,
      startDate: provider.startDate,
      endDate: provider.endDate,
      unitPrice: provider.fee,
      type: PurchaseOrderItemTypeEnum.FEES,
      originalType: PurchaseOrderItemOriginalTypeEnum.REQUEST,
      originalId: provider.id,
    });
    this.purchaseOrder.items.push({
      quantity: 1,
      endDate: provider.endDate,
      startDate: provider.startDate,
      unitPrice: provider.onCall,
      type: PurchaseOrderItemTypeEnum.ON_CALL,
      originalType: PurchaseOrderItemOriginalTypeEnum.REQUEST,
      originalId: provider.id,
    });
    this.purchaseOrder.items.push({
      quantity: 1,
      endDate: provider.endDate,
      startDate: provider.startDate,
      unitPrice: provider.amount,
      type: PurchaseOrderItemTypeEnum.AMOUNT,
      originalType: PurchaseOrderItemOriginalTypeEnum.REQUEST,
      originalId: provider.id,
    });
  }

  private generateProviderPO(): void {
    for (const provider of this.request.requestClientStepResource.billing.initialRequest.providers) {
      this.purchaseOrder.items.push({
        quantity: 1,
        startDate: provider.startDate,
        endDate: provider.endDate,
        unitPrice: provider.fee,
        providerId: provider.providerId,
        type: PurchaseOrderItemTypeEnum.FEES,
        originalType: PurchaseOrderItemOriginalTypeEnum.PROVIDER,
        originalId: provider.id,
      });
      this.purchaseOrder.items.push({
        quantity: 1,
        endDate: provider.endDate,
        startDate: provider.startDate,
        unitPrice: provider.onCall,
        providerId: provider.providerId,
        type: PurchaseOrderItemTypeEnum.ON_CALL,
        originalType: PurchaseOrderItemOriginalTypeEnum.PROVIDER,
        originalId: provider.id,
      });
      this.purchaseOrder.items.push({
        quantity: provider.quantity,
        endDate: provider.endDate,
        startDate: provider.startDate,
        unitPrice: provider.rate,
        providerId: provider.providerId,
        type: PurchaseOrderItemTypeEnum.QUANTITY,
        originalType: PurchaseOrderItemOriginalTypeEnum.PROVIDER,
        originalId: provider.id,
      });
    }
  }

  getPoItem(type: PurchaseOrderItemTypeEnum) {
    if (!this.purchaseOrder || !this.purchaseOrder.items) {
      return [];
    }
    return this.purchaseOrder.items.filter(item => item.type === type);
  }

  getPoItemTotal(type: PurchaseOrderItemTypeEnum): number {
    if (!this.purchaseOrder || !this.purchaseOrder.items) {
      return 0;
    }
    return this.purchaseOrder.items.filter(item => item.type === type).reduce((acc, item) => acc + (item.unitPrice * item.quantity), 0);
  }

  getProviderInfo(providerId: any): string {
    const provider = this.providers.find(pro => pro.id === providerId.toString());
    if (!provider) {
      return '';
    }
    return provider.pointOfContact.firstName + ' ' + provider.pointOfContact.lastName;
  }

  loadData(): void {
    this.requestService.getRequestById(this.requestId).subscribe(value => {
      this.request = value;

      if (!this.request.purchaseOrderId) {
        this.generateNoPO();
      }
      if (this.request.purchaseOrderId) {
        this.purchaseOrderService.getPurchaseOrderById(this.request.purchaseOrderId).subscribe({
          next: purchaseOrder => this.purchaseOrder = purchaseOrder,
          error: () => this.toastrService.error(this.translateService.instant('global.ts.error'))
        });
      }
    });
  }

  get poPeriod() {
    const amountPOItems = this.getPoItem(PurchaseOrderItemTypeEnum.AMOUNT);
    const minStartDate = amountPOItems.reduce((acc, item) => {
      const itemStartDate = new Date(item.startDate);
      return acc && itemStartDate < acc ? itemStartDate : acc || itemStartDate;
    }, null);

    const maxEndDate = amountPOItems.reduce((acc, item) => {
      const itemEndDate = new Date(item.endDate);
      return acc && itemEndDate > acc ? itemEndDate : acc || itemEndDate;
    }, null);
    return {earliestStartDate: minStartDate, earliestEndDate: maxEndDate};
  }

  private generateNoPO(): void {
    this.purchaseOrder = new PurchaseOrderModel();
    if (this.request.requestClientStepResource.billing.initialRequest.providers.length > 0) {
      this.generateProviderPO();
    }
    if (this.request.requestClientStepResource.billing.initialRequest.amount > 0) {
      this.generateNoProviderPO();
    }
  }
}
