import {MissionClientStepModel} from './mission-client-step.model';
import {MissionSupplierStepModel} from './mission-supplier-step.model';

export class RequestDetailModel {
  requestId: string;
  clientStep: MissionClientStepModel;
  supplierStep: MissionSupplierStepModel;

  constructor(object?: any) {
    if (object) {
      this.requestId = object.requestId;
      this.clientStep = new MissionClientStepModel(object.clientStep);
      this.supplierStep = new MissionSupplierStepModel(object.supplierStep);
    } else {
      this.clientStep = new MissionClientStepModel();
      this.supplierStep = new MissionSupplierStepModel();
    }
  }
}
