import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {IconModule, IconSetModule, IconSetService} from '@coreui/icons-angular';
import {AppComponent} from './app.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import {DefaultLayoutComponent} from './containers';
import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import {SignupComponent} from './signup/signup.component';
import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppFooterModule,
  AppHeaderModule,
  AppSidebarModule
} from '@coreui/angular';

// Import routing module
import {AppRoutingModule} from './app.routing';

// Import 3rd party components
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input-gg';
import {NgSelectModule} from '@ng-select/ng-select';
import {SharedModule} from './shared/shared.module';
import {LoginComponent} from './login/login.component';
import {LoggerModule, NGXLogger, NgxLoggerLevel} from 'ngx-logger';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router} from '@angular/router';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {CoreModule} from './core/core.module';
import {ForgetPasswordComponent} from './forget-password/forget-password.component';
import {AccountValidationComponent} from './account-validation/account-validation.component';
import {
  PasswordMeterProgressBarComponent
} from './shared/components/password-meter/password-meter-progress-bar/password-meter-progress-bar.component';
import {PasswordMeterComponent} from './shared/components/password-meter/password-meter.component';
import {AuthenticationService} from './core/services/authentication.service';
import {TimepickerActions} from 'ngx-bootstrap/timepicker';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {commonProperties} from '../assets/environments/environment.common';
import {DatePipe, NgIf} from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import { MissionModule } from './views/mission/mission.module';
import { EmployeeModule } from './views/employee/employee.module';
import { InvoiceModule } from './views/invoice/invoice.module';
import { MarketplaceModule } from './views/marketplace/marketplace.module';
import { OnCallModule } from './views/on-call/on-call.module';
import { PurchaseOrderModule } from './views/purchase-order/purchase-order.module';
import {AvatarModule} from 'ngx-avatars';
import {environment} from '../assets/environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {IsupplierSharedModule} from '@i-supplier/angular-shared-module';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';


registerLocaleData(localeFr);
registerLocaleData(localeEn);

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export function initConfig(injector: Injector, logger: NGXLogger) {
  return () => {
    if (!environment.production) {
      const updatedConfig = logger.getConfigSnapshot();
      updatedConfig.level = NgxLoggerLevel.ERROR;
      logger.updateConfig(updatedConfig);
    }
    const jwtHelper = injector.get(JwtHelperService);
    const router = injector.get(Router);
    const token = localStorage.getItem(AuthenticationService.TOKEN);
    if (token) {
      if (jwtHelper.isTokenExpired()) {
        localStorage.removeItem('access_token');
        router.navigate(['login']);
      }

      const xhr = new XMLHttpRequest();
      xhr.open('POST', environment.api_root + commonProperties.tokenValidation);
      xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');

      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status !== 204) {
          localStorage.removeItem('access_token');
          router.navigate(['login']);
        }
      });

      xhr.send(JSON.stringify({
        token,
        type: 'SUPPLIER'
      }));
    }
  };
}

const APP_CONTAINERS = [DefaultLayoutComponent];

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AppAsideModule,
        AppBreadcrumbModule.forRoot(),
        AppFooterModule,
        AppHeaderModule,
        AppSidebarModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        CarouselModule.forRoot(),
        CoreModule,
        ReactiveFormsModule,
        IconModule,
        ReactiveFormsModule,
        IconSetModule.forRoot(),
        // ngx-translate and the loader module
        HttpClientModule,
        LoggerModule.forRoot({
            level: NgxLoggerLevel.DEBUG,
            timestampFormat: 'dd/MM/yyyy HH:mm:ss',
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        NgxIntlTelInputModule,
        NgSelectModule,
        FormsModule,
        SharedModule,
        MissionModule,
        EmployeeModule,
        InvoiceModule,
        MarketplaceModule,
        OnCallModule,
        PurchaseOrderModule,
        AvatarModule,
        FontAwesomeModule,
        IsupplierSharedModule,
        NgIf,
    ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    SignupComponent,
    LoginComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    AccountValidationComponent,
    PasswordMeterProgressBarComponent,
    PasswordMeterComponent
  ],
  providers: [
    DatePipe,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initConfig,
      deps: [Injector, NGXLogger]
    },
    IconSetService,
    TimepickerActions,
    TranslateService,
    BsModalService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
