import {query, transition, trigger, useAnimation} from '@angular/animations';
import {HttpResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {faCommentCaptions, faDownload, faSearch} from '@fortawesome/pro-duotone-svg-icons';
import {ButtonTypeEnum, IspColoursEnum, SizeEnum} from '@i-supplier/angular-shared-module';
import {NGXLogger} from 'ngx-logger';
import {Subscription} from 'rxjs';
import {fadeInAnimation, slideIn50StaggerAnimation, slideInAnimation} from '../../../shared/animation/common.animation';
import {ListContentComponent} from '../../../shared/components/list-content/list-content.component';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {CreditNoteStatusEnum} from '../models/credit-note-status.enum';
import {InvoiceStatusEnum} from '../models/invoice-status.enum';
import {InvoicingModel} from '../models/InvoicingModel';
import {InvoiceService} from '../services/invoice.service';

@Component({
  selector: 'credit-note-list',
  templateUrl: './credit-note-list.component.html',
  styleUrls: ['./credit-note-list.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ]),
    trigger('slideIn', [
      transition(':enter', [
        useAnimation(slideInAnimation)
      ])
    ]),
    trigger('slideInStagger', [
      transition(':enter', [
        query('.slide-in-animation', [
          useAnimation(slideIn50StaggerAnimation)
        ], {optional: true})
      ])
    ])
  ]
})
export class CreditNoteListComponent extends ListContentComponent implements OnInit, OnDestroy {

  constructor(
    private invoiceService: InvoiceService,
    private fb: FormBuilder,
    private logger: NGXLogger,
    public route: ActivatedRoute,
    public router: Router
  ) {
    super(router, route);
    this.advancedSearchForm = this.fb.group({
      number: [null],
      purchaseOrderNumber: [null],
      buyerName: [null],
      linkedNumber: [null],
      status: [null],
      mode: [null]
    });
  }
  protected readonly faSearch = faSearch;
  protected readonly faDownload = faDownload;
  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly IspColoursEnum = IspColoursEnum;
  protected readonly faCommentCaptions = faCommentCaptions;
  protected readonly SizeEnum = SizeEnum;
  subscriptions: Subscription[] = [];

  listContent: InvoicingModel[] = [];

  status = Object.values(CreditNoteStatusEnum);

  listParamValidator = {
    page: RegExp('^[1-9][0-9]*$'),
    size: ['5', '10', '20'],
    sort: RegExp('^(number|status||date)\,(asc|desc)$'),
    search: RegExp('.{3,}'),
    number: RegExp('^[a-zA-Z0-9_.-]+$'),
    linkedNumber: RegExp('^[a-zA-Z0-9_.-]+$'),
    purchaseOrderNumber: RegExp('^[a-zA-Z0-9_.-]+$'),
    status: Object.values(InvoiceStatusEnum)
  };

  protected readonly InvoiceStatusEnum = InvoiceStatusEnum;

  ngOnInit(): void {
    super.ngOnInit();
    this.sort = 'date';
    this.sortDirection = 'desc';
    this.subscribeToQueryParam();
  }


  // Override method
  mapQueryParamToAdvancedSearchForm(params: any): void {
    Object.keys(params).forEach(paramKey => {
      if (this.advancedSearchForm.contains(paramKey)) {
        this.advancedSearchForm.get(paramKey).setValue(params[paramKey]);
        this.isAdvancedSearchDisplayed = true;
        this.isSearchActive = true;
      }
    });
  }

  // Override method
  mapAdvancedSearchFormToQueryParam(advancedSearchForm: any): any {
    if (advancedSearchForm.hasOwnProperty('contractMode') && advancedSearchForm.contractMode) {
      advancedSearchForm.contractMode = advancedSearchForm.contractMode.join();
    }
    return advancedSearchForm;
  }

  // Override method
  retrieveListContent(params: any): void {
    const page = params.page ? params.page - 1 : 0;
    const size = params.size ? params.size : 5;
    const sort = params.sort ? params.sort : 'date,desc';
    const search = params.search ? params.search : null;
    const advancedSearch = this.invoiceService.mapAdvancedSearchForm(this.advancedSearchForm.value);
    advancedSearch.types = ['SUPPLIER_CREDIT_NOTE'];
    this.invoiceService.getInvoicesByPage(page, size, sort, search, advancedSearch).subscribe({
      next: (res: ListContentModel) => {
        this.listContent = res.content;
        this.totalElements = res.totalElements;
        this.numberOfElements = res.numberOfElements;
        this.isListEmpty = !this.isSearchActive && res.empty;
        if (res.totalPages !== 0 && params.page > res.totalPages) {
          this.updateQueryParam({page: res.totalPages});
        }
        this.firstCallDone = true;
      },
      error: (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      },
      }
    );
  }

  downloadInvoice(invoice) {
    this.invoiceService.downloadFile(invoice.id).subscribe({
        next: (res: HttpResponse<Blob>) => {
          const headers = res.headers;
          const contentDisposition = headers.get('content-disposition');
          let filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          filename = filename.replace(/"/g, '');
          const a = document.createElement('a');
          a.href = URL.createObjectURL(res.body);
          a.download = filename;
          a.click();
          a.remove();
        },
        error: (error: any) => {
          this.logger.error(error.url, '- STATUS :', error.status);
        },
      },
    );
  }
}
