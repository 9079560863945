import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss']
})

export class PdfPreviewComponent {
  @Input() srcPdf: any;
  @Input() zoom: number = 1;
  @Input() scale: 'page-height' | 'page-fit' | 'page-width' = 'page-fit';
  numPage: number = 1;
  totalPages: number;

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
  }

  increment() {this.numPage++; }

  decrement() {this.numPage--; }
}
