import {MissionClientStepModel} from './mission-client-step.model';
import {MissionSupplierStepModel} from './mission-supplier-step.model';

export class RequestDetailModel {
  requestId: string;
  requestClientStepResource: MissionClientStepModel;
  requestSupplierStepResource: MissionSupplierStepModel;
  purchaseOrderId: string;
  effectiveStartDate: Date;
  effectiveEndDate: Date;

  constructor(object?: any) {
    if (object) {
      this.requestId = object.requestId;
      this.requestClientStepResource = new MissionClientStepModel(object.clientStep);
      this.requestSupplierStepResource = new MissionSupplierStepModel(object.supplierStep);
    } else {
      this.requestClientStepResource = new MissionClientStepModel();
      this.requestSupplierStepResource = new MissionSupplierStepModel();
    }
  }
}
