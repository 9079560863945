<div class="modal-body p-5">
  <h5>{{'On_call.new_on_call'|translate}}</h5>
  <hr/>
  <form [formGroup]="onCallForm" (ngSubmit)="createAction()">
    <div class="row">
      <div class="col col-md-6">
        <div class="form-group">
          <label for="purchaseOrder">{{'On_call.po_num'|translate}}<span
            class="text-primary">*</span></label>
          <div>
            <select class="form-control custom-select" formControlName="purchaseOrder"
                    id="purchaseOrder" (change)="selectedPO()">
              <option [ngValue]="null" selected>{{'On_call.po_num'|translate}}</option>
              <option *ngFor="let aOnCall of filterEnvelopeList(onCallEnvelopeList)"
                      [ngValue]="aOnCall.purchaseOrderNumber">
                {{aOnCall.projectName}} - {{aOnCall.purchaseOrderNumber}}
              </option>
            </select>
          </div>
          <app-form-feedback *ngIf="onCallForm.get('purchaseOrder').errors?.required &&
                (onCallForm.get('purchaseOrder').dirty || onCallForm.get('purchaseOrder').touched)"
                             message="{{'global.feedback.required'|translate}}">
          </app-form-feedback>
        </div>
      </div>
      <div *ngIf="allowedToTransmit === false" class="col-12 text-center text-primary">{{'Activity_report.waiting_for_order'|translate}}</div>
      <div class="col col-md-6 col" [class.d-none]="allowedToTransmit && onCallForm.get('purchaseOrder').errors?.required">
        <div class="form-group">
          <label for="type">{{'On_call.type'|translate}}<span
            class="text-primary">*</span></label>
          <div>
            <select class="form-control custom-select" formControlName="type" id="type"
                    (change)="selectedType()">
              <option [ngValue]="null" selected>{{'On_call.type'|translate}}</option>
              <option *ngFor="let aType of currentParam?.workItems" [ngValue]="aType.reference">
                {{aType.reference}} - {{aType.description}}
              </option>
            </select>
            <app-form-feedback *ngIf="!onCallForm.get('type').getRawValue() &&
                        (onCallForm.get('type').dirty || onCallForm.get('type').touched)"
                               message="{{'global.feedback.required'|translate}}">
            </app-form-feedback>
          </div>
        </div>
      </div>

    </div>
    <div class="row" [class.d-none]="!onCallForm.get('type').getRawValue()">
      <div class="col"
           [ngClass]="{'col-md-6': !onCallForm.get('time').disabled, 'col-md-12': onCallForm.get('time').disabled}">
        <div class="form-group">
          <label for="date">
            {{'On_call.date'|translate}}<span class="text-primary">*</span>
          </label>

          <div class="float-right" *ngIf="dateRangeCheck()">
            <div class="custom-control custom-checkbox">
              <input
                id="periodCheck"
                formControlName="isPeriodCheck"
                (change)="togglePeriod()"
                type="checkbox"
                class="custom-control-input"
              />
              <label class="custom-control-label" for="periodCheck">
                {{'On_call.on_call_period'|translate}}
              </label>
            </div>
          </div>

          <input *ngIf="this.onCallForm.get('isPeriodCheck').value && dateRangeCheck()"
                 id="missionRangeDate"
                 type="text" class="form-control mb-3"
                 placeholder="{{'On_call.on_call_start_end_dates'|translate}}"
                 bsDaterangepicker readonly
                 formControlName="dateRange"
                 [minDate]="minDate"
                 [maxDate]="maxDate"
                 [bsConfig]="{rangeInputFormat: 'DD-MM-YYYY',
                    containerClass:'theme-blue',
                    adaptivePosition: true,
                    isAnimated: true }"/>

          <input *ngIf="!this.onCallForm.get('isPeriodCheck').value"
                 bsDatepicker readonly type="text"
                 class="form-control" id="date"
                 formControlName="date"
                 [minDate]="minDate"
                 [maxDate]="maxDate"
                 [bsConfig]="{dateInputFormat: 'DD/MM/YYYY',
                    containerClass:'theme-blue',
                    adaptivePosition: true,
                    isAnimated: true }"/>

          <app-form-feedback *ngIf="onCallForm.get('date').errors?.required &&
                (onCallForm.get('date').dirty || onCallForm.get('date').touched)"
                             message="{{'global.feedback.required'|translate}}">
          </app-form-feedback>
          <app-form-feedback *ngIf="onCallForm.get('date').errors?.pattern
                && (onCallForm.get('date').dirty || onCallForm.get('date').touched)"
                             message="{{'global.feedback.pattern_date'|translate}}">
          </app-form-feedback>
        </div>
      </div>
      <div class="col col-md-6" [class.d-none]="onCallForm.get('time').disabled">
        <div class="form-group">
          <label for="time">{{'On_call.start_time'|translate}} <span
            class="text-primary">*</span></label>
          <timepicker class="d-flex justify-content-center" [showMeridian]="false"
                      formControlName="time" id="time"></timepicker>
          <app-form-feedback *ngIf="onCallForm.get('time').errors?.required &&
                (onCallForm.get('time').dirty || onCallForm.get('time').touched)"
                             message="{{'global.feedback.required'|translate}}">
          </app-form-feedback>
        </div>
      </div>

      <div class="col" [ngClass]="{'col-md-6': !isProvider(), 'col-md-12': isProvider()}">
        <div class="form-group">
          <label for="quantity">{{'On_call.quantity'|translate}} <span
            class="text-primary">*</span></label>
          <input type="number" class="form-control" formControlName="quantity" id="quantity" onlyNumbers>
          <app-form-feedback *ngIf="onCallForm.get('quantity').errors?.required &&
                (onCallForm.get('quantity').dirty || onCallForm.get('quantity').touched)"
                             message="{{'global.feedback.required'|translate}}">
          </app-form-feedback>
          <app-form-feedback *ngIf="onCallForm.get('quantity').errors?.min  &&
                (onCallForm.get('quantity').dirty || onCallForm.get('quantity').touched)"
                             message="{{'On_call.feedback.quantity_min'|translate}}">
          </app-form-feedback>
          <app-form-feedback *ngIf="onCallForm.get('quantity').errors?.max  &&
                (onCallForm.get('quantity').dirty || onCallForm.get('quantity').touched)"
                             message="{{'On_call.feedback.quantity_max'|translate}}">
          </app-form-feedback>
        </div>
      </div>
      <div class="col-md-6" *ngIf="!isProvider()">

        <div class="form-group">
          <label
            for="dailyRate">{{dailyRateByDate ? ('On_call.daily_rate'|translate) : ('On_call.hour_rate'|translate)}}
            <span class="text-primary">*</span></label>
          <input type="number" class="form-control" id="dailyRate"
                 inputmode="decimal"
                 formControlName="dailyRate" onlyNumbers>
          <app-form-feedback *ngIf="onCallForm.get('dailyRate').errors?.required &&
                (onCallForm.get('dailyRate').dirty || onCallForm.get('dailyRate').touched)"
                             message="{{'global.feedback.required'|translate}}">
          </app-form-feedback>
        </div>
      </div>

    </div>

    <div class="row my-2" [class.d-none]="!onCallForm.get('type').getRawValue()">

      <div class="col col-md-6">
        <div *ngIf="onCallForm.get('fileName').value"
             class="row d-flex align-items-center justify-content-center">
          <div class="col-2"></div>
          <div class="col-8">
            <div class="form-group my-auto">
              <button type="button" class="btn btn-outline-warning btn-block no-download">
                <i class="fa fa-file" aria-hidden="true"></i>
                {{(onCallForm.get('fileName').value.length > 18) ?
                (onCallForm.get('fileName').value | slice:0:18) + '...' :
                onCallForm.get('fileName').value }}
              </button>
            </div>
          </div>
          <div class="col-2">
            <div>
              <i class="fa fa-times fa-lg delete" aria-hidden="true" (click)="deleteFile()"></i>
            </div>
          </div>
        </div>

        <div *ngIf="!onCallForm.get('fileName').value"
             class="h-100 d-flex align-items-center justify-content-center">
          {{'On_call.no_file'|translate}}
        </div>
      </div>

      <div class="col col-md-6 d-flex align-items-center justify-content-center">
        <div class="row">
          <div class="form-group my-auto">
            <button type="button" class="btn btn-outline-info px-5"
                    (click)="fileUpload.click()">
            <span>
              <i class="fa fa-upload" aria-hidden="true"></i>
              {{'On_call.upload_new_file'|translate}}
            </span>
              <ng-container>
                <input #fileUpload type="file" class="custom-file-input" multiple
                       formControlName="file" id="file"
                       (change)="onFileChange($event.target.files)" style="display:none;">
              </ng-container>
            </button>
          </div>
        </div>
      </div>

    </div>

    <div class="row" [class.d-none]="!onCallForm.get('type').getRawValue()">
      <div class="col">
        <div class="form-group last-form-group">
          <label for="comment">{{'On_call.comment'|translate}}
          </label>
          <textarea id="comment" class="form-control" rows="3" formControlName="comment"
                    maxlength="255"
                    placeholder="{{'On_call.comment'|translate}}">
                  </textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex flex-column justify-content-center align-items-center">
        <div class="mt-4">
          <button class="btn btn-outline-warning px-5 mr-3" type="button"
                  (click)="cancelAction()">{{'On_call.button_cancel'|translate}}
          </button>
          <button type="submit" class="btn btn-primary px-5"
                  [disabled]="!onCallForm.valid">{{'On_call.button_create'|translate}}</button>
        </div>
      </div>
    </div>
  </form>
</div>
