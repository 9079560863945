import {ClientModel} from "./client.model";
import {MissionBillingModel} from "./mission-billing.model";
import {RequestStatusEnum} from './mission-list-model';


export class MissionClientStepModel {
  requestId: string;
  buyerId: string;
  parentRequestId: string;
  parentRequestNumber: string;
  client: ClientModel;
  billing: MissionBillingModel;
  comment: string;
  status?: RequestStatusEnum;
  purchaseOrderId: string;

  constructor(object?: any) {
    if (object) {
      this.client = new ClientModel(object.client);
      this.billing = new MissionBillingModel(object.billing);
      this.requestId = object.requestId;
      this.buyerId = object.buyerId;
      this.comment = object.comment;
    } else {
      this.client = new ClientModel();
      this.billing = new MissionBillingModel();
    }
  }
}
