export enum AuthorityRoleEnum {
  FREELANCER = 'ROLE_FREELANCER',
  PROVIDER = 'ROLE_PROVIDER',
  SUPPLIER = 'ROLE_SUPPLIER',
  SUPPLIER_ADMIN = 'ROLE_SUPPLIER_ADMIN'
}

export enum AuthorityEnum {
  FREELANCER = 'FREELANCER',
  PROVIDER = 'PROVIDER',
  SUPPLIER = 'SUPPLIER',
  SUPPLIER_ADMIN = 'SUPPLIER_ADMIN',
  OTHER = 'OTHER'
}
