import {ActivityAreaModel} from '../../../views/company-config/activity-areas/activity-area.model';
import {CallForTenderModel} from "./call-for-tender.model";

export class CallForTenderOfferListItemModel {
  id: string;
  title: string;
  providerId: string;
  supplierName: string;
  providerFirstName: string;
  providerLastName: string;
  providerEmail: string;
  callForTenderId: string;
  nickName: string;
  experience: string;
  dailyRate: number;
  amount: number;
  resumeFileName: string;
  resumeFileContent: string;
  comment: string;
  status: string;
  meetings: any;
  moderationComment: string;
  priceNegotiation: any;
  negotiation: boolean;
  auditRecords: any;
  callForTender: CallForTenderModel;
}
