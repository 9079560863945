import {CommonModule, NgIf} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {IsupplierSharedModule} from '@i-supplier/angular-shared-module';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateModule} from '@ngx-translate/core';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {SharedModule} from '../../shared/shared.module';
import {CreditNoteListComponent} from './credit-note-list/credit-note-list.component';
import {InvoiceListComponent} from './invoice-list/invoice-list.component';
import {InvoiceRoutingModule} from './invoice-routing.module';
import {TMInvoiceListComponent} from './t-m-invoice-list/t-m-invoice-list.component';
import {
  ActivitySheetToGenerateComponent,
} from './to-generate/activity-sheet-to-generate/activity-sheet-to-generate.component';
import {CreditNoteGenerationComponent} from './to-generate/credit-note-generation/credit-note-generation.component';
import {
  DuePaymentsToGenerateComponent,
} from './to-generate/due-payments-to-generate/due-payments-to-generate.component';
import {FeesToGenerateComponent} from './to-generate/fees-to-generate/fees-to-generate.component';
import {OnCallsToGenerateComponent} from './to-generate/on-calls-to-generate/on-calls-to-generate.component';
import {ProformaGenerationComponent} from './to-generate/proforma-generation/proforma-generation.component';
import {ToGenerateComponent} from './to-generate/to-generate.component';

@NgModule({
  declarations: [
    InvoiceListComponent,
    TMInvoiceListComponent,
    ToGenerateComponent,
    ActivitySheetToGenerateComponent,
    DuePaymentsToGenerateComponent,
    OnCallsToGenerateComponent,
    FeesToGenerateComponent,
    ProformaGenerationComponent,
    CreditNoteListComponent,
    CreditNoteGenerationComponent,
  ],
  imports: [
    CommonModule,
    InvoiceRoutingModule,
    SharedModule,
    TranslateModule,
    ReactiveFormsModule,
    NgSelectModule,
    PaginationModule,
    FormsModule,
    PdfViewerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    BsDatepickerModule,
    IsupplierSharedModule,
    NgIf,
    TranslateModule,
  ],
})
export class InvoiceModule {
}
