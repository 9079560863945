import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {maximumSizeFile} from '../../../../../../shared/validators/maximum-size-file.validator';
import {requiredFileType} from '../../../../../../shared/validators/required-file-type.validator';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {ProviderDisplayModel, ProviderModel} from '../../../../../../shared/models/mission/provider.model';
import {MissionBillingModel} from '../../../../../../shared/models/mission/mission-billing.model';
import {NGXLogger} from 'ngx-logger';
import {EmployeeService} from '../../../../../employee/services/employee.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ProviderService} from '../../../../services/provider.service';
import {ToastrService} from 'ngx-toastr';
import {transition, trigger, useAnimation} from '@angular/animations';
import {fadeInAnimation} from '../../../../../../shared/animation/common.animation';
import {regex} from '../../../../../../shared/regex/form.regex';
import {AuthenticationService} from '../../../../../../core/services/authentication.service';
import {RoleEnum} from '../../../../../../shared/enums/CallForTenderOffers.enum';
import {TranslateService} from '@ngx-translate/core';
import {ButtonTypeEnum, IspColoursEnum, SizeEnum} from '@i-supplier/angular-shared-module';
import {faUser, faEdit, faFileContract, faWarning, faPenToSquare} from '@fortawesome/pro-duotone-svg-icons';
import {MissionService} from '../../../../services/mission.service';
import { RequestStatusEnum } from '../../../../../../shared/models/mission/mission-list-model';

@Component({
  selector: 'provider-view-detail',
  templateUrl: './provider-view-detail.component.html',
  styleUrls: ['./provider-view-detail.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeInAnimation)
      ])
    ])
  ]
})
export class ProviderViewDetailComponent implements OnInit, OnDestroy {

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private logger: NGXLogger,
    private employeeService: EmployeeService,
    private providerService: ProviderService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    public authService: AuthenticationService,
    private missionService: MissionService
  ) {
    this.providerForm = this.fb.group({
      id: [null],
      employeeId: [null],
      employee: [null, [Validators.required]],
      cvFile: [null, [maximumSizeFile(2, 'mo')]],
      cvLink: [null, [requiredFileType('pdf')]],
      title: [null, [Validators.pattern(regex.title), Validators.maxLength(60), Validators.required]],
      pointOfContact: this.fb.group({
        firstName: [null, [Validators.required, Validators.pattern(regex.firstName), Validators.maxLength(50)]],
        lastName: [null, [Validators.required, Validators.pattern(regex.lastName), Validators.maxLength(50)]],
        email: [null, [Validators.required, Validators.pattern(regex.email), Validators.maxLength(100)]],
        phoneNumber: [null, [Validators.required]]
      }),

    });
  }

  get displayCreateEmployeeBtn(): boolean {
    return this.supplierId && !this.providerForm.get('employeeId').value && this.userRole !== 'ROLE_FREELANCER';
  }

  get displayEmployeeTypeError(): boolean {
    return this.providerForm.get('employee').value && !this.providerForm.get('employee').value.hasOwnProperty('providerType') && this.providerForm.get('employee').value.id;
  }
  @ViewChild('providerModal', {static: true}) providerModal: { show: () => void; hide: () => void; };
  providerModalValue: ProviderDisplayModel = new ProviderDisplayModel();

  @Input() providers: ProviderModel[];
  @Input() supplierId: string;
  @Input() requestId: string;
  @Input() billingConfig: MissionBillingModel;
  @Output() newModifyEvent = new EventEmitter<any>();

  $employeeSearch: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  employeeSearchList: any[];
  employeeSearchPage: number;
  isLastPageEmployeeSearch: boolean;
  hasEmployeeIdKey: boolean;

  userRole: string;
  userName: string;
  nickName: string;

  providerForm: FormGroup;
  private subscriptions: Subscription[] = [];
  protected readonly SizeEnum = SizeEnum;
  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly faEdit = faEdit;
  protected readonly IspColoursEnum = IspColoursEnum;
  missionStatus: RequestStatusEnum;
  protected readonly RequestStatusEnum = RequestStatusEnum;
  contact: any;

  protected readonly faFileContract = faFileContract;
  protected readonly faUser = faUser;
  protected readonly faWarning = faWarning;

  ngOnInit(): void {
    this.userRole = this.authService.getRole();
    this.subscribeToEmployeeSearch();
    this.listenToSelectEmployeeChanged();
    this.initIfFreelancer();

    if (this.providers) {
      this.hasEmployeeIdKey = !!this.providers.find(key => key.employeeId);
    }

    this.missionService.getMissionClientStepById(this.requestId).subscribe({
      next: res => {
        this.missionStatus = res.status;
        if (this.missionStatus !== RequestStatusEnum.CONTACT_INITIATED){
          this.providerForm.get('employee').disable();
        }
      }
    });
  }

  openEditProviderModal(provider: any) {
    this.employeeService.getProviderByCompanyId(provider.pointOfContact.email, 0).subscribe(data => {
      this.contact = data.content[0] || provider.pointOfContact;
      provider.pointOfContact = this.contact;

      this.providerForm.patchValue({
        employee: this.contact,
        employeeId: this.contact?.id || null,
        ...provider,
      });

      this.providerModalValue = provider;
      this.providerModal.show();
    });
  }

  submitProviderForm() {
    this.providerService.editProvider(this.requestId, this.providerForm.value).subscribe({
      next: () => {
        const formValue = this.providerForm.value;
        const provider = this.providerService.mapProviderFormValueToResource(formValue);
        this.newModifyEvent.emit(provider);
        // Find index in providers Table to edit provider
        const index = this.providers.findIndex(p => p.id === provider.id);

        if (this.providers[index].cvLink !== provider.cvLink) {
          const file = this.providerForm.get('cvFile').value;
          this.providerService.uploadResume(this.requestId, provider.id, file).subscribe({
            error: (err) => this.logger.error(err)
          });
        }
        this.toastrService.success(this.translateService.instant('Mission_view.provider.ts.provider_updated.title'));
      },
      error: () => {
        this.toastrService.error(this.translateService.instant('global.ts.error'));
      }
    });
    this.providerModal.hide();
  }

  searchEmployee($event: { term: string; items: any[] }): void {
    this.$employeeSearch.next($event.term);
  }

  onScrollEmployeeSearch(): void {
    if (!this.isLastPageEmployeeSearch) {
      this.employeeSearchPage++;
      this.retrieveEmployeeSearchList();
    }
  }

  setResumeToProviderForm(event): void {
    if (event.target.files && event.target.files[0]) {
      const resume = event.target.files[0];
      this.providerForm.get('cvLink').setValue(resume.name);
      this.providerForm.get('cvFile').setValue(resume);
    }
  }

  goToCreateProvider(): void {
    const employeeToCreate = this.providerForm.get('pointOfContact').value;
    employeeToCreate.phoneNumber = this.providerForm.get('pointOfContact.phoneNumber').value;
    employeeToCreate.role = 'PROVIDER';
    employeeToCreate.title = this.providerForm.get('title').value;
    localStorage.setItem('employeeToCreate', JSON.stringify(employeeToCreate));
    this.router.navigate(['employees', 'create'], {queryParams: {'requestId': this.requestId}}).then();
  }

  goToUpdateProvider() {
    this.router.navigate(['employees', this.providerForm.get('employee').value.id, 'update'], {'queryParams': {'requestId': this.requestId}}).then();
  }

  downloadResume(): void {
    if (this.providerForm.get('cvFile').value) {
      const url = window.URL.createObjectURL(this.providerForm.get('cvFile').value);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.providerForm.get('cvLink').value;
      a.click();
      a.remove();
    } else {
      this.subscriptions.push(this.providerService.downloadResume(this.requestId,
        this.providerForm.get('id').value).subscribe(response => {
        const url = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = url;
        a.download = this.providerForm.get('cvLink').value;
        a.click();
        a.remove();
      }, error => {
        this.logger.error(error.url, '- STATUS :', error.status);
        this.toastrService.error(this.translateService.instant('global.ts.error'));
      }));
    }
  }

  private subscribeToEmployeeSearch(): void {
    this.subscriptions.push(this.$employeeSearch.pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(value => {
        this.employeeSearchList = [];
        if (value) {
          this.employeeSearchPage = 0;
          this.retrieveEmployeeSearchList();
        }
      })
    );
  }

  private retrieveEmployeeSearchList(): void {
    const searchValue = this.$employeeSearch.getValue();
    this.subscriptions.push(this.employeeService.getProviderByCompanyId(searchValue.trim(), this.employeeSearchPage).subscribe(
      (res: any) => {
        this.employeeSearchList = this.employeeSearchList.concat(res.content);
        this.isLastPageEmployeeSearch = res.last;
      }, (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
      }
    ));
  }

  private listenToSelectEmployeeChanged() {
    this.subscriptions.push(this.providerForm.get('employee').valueChanges.subscribe({
      next: value => this.providerForm.get('employeeId').setValue(value && this.providerForm.get('employee').value.id ? this.providerForm.get('employee').value.id : null)
    }));
  }

  private initIfFreelancer(): void {
    if (this.userRole === RoleEnum.FREELANCER) {
      this.subscriptions.push(this.authService.getUsername().subscribe(value => {
        if (value) {
          this.userName = value;
          this.nickName = value.charAt(0) + '' + value.substring(value.indexOf(' ') + 1).substring(0, 2);
        }
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  protected readonly faPenToSquare = faPenToSquare;
}
