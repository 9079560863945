import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../../assets/environments/environment';
import {commonProperties} from '../../../../assets/environments/environment.common';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {CompanyItemModel} from '../../../shared/models/companies/company-item.model';
import {LegalInformationModel} from '../../../shared/models/companies/legal-information.model';
import {ListContentModel} from '../../../shared/models/list-content.model';
import {CompanyTaxesPostModel, TaxNumber} from '../../../shared/models/companies/CompanyTaxesModel';
import { CompanyCreationBodyModel } from '../../../shared/models/companies/company-creation-body.model';


@Injectable({
  providedIn: 'root'
})
export class MyCompanyService {
  billableCompanies = 'invoicingMgtApi/invoice-configs/billable-companies';

  private companyToUpdateSubject = new BehaviorSubject(null);

  constructor(private httpClient: HttpClient, private authService: AuthenticationService) {
  }

  getMyCompany(): Observable<LegalInformationModel> {
    return this.httpClient.get<LegalInformationModel>(environment.api_root + commonProperties.myCompany.replace(':companyId', this.authService.decodeToken('tenantId')));
  }

  getSuppliersCompaniesByPage(search: string, page: number, size: number): Observable<ListContentModel> {
    let params = new HttpParams();
    params = params.set('name', search).set('page', page.toString()).set('size', size.toString()).set('types', 'SUPPLIER');
    return this.httpClient.get<ListContentModel>(environment.api_root + commonProperties.suppliersList, {params});
  }

  getSuppliersById(supplierId): Observable<CompanyItemModel> {
    return this.httpClient.get<CompanyItemModel>(environment.api_root + commonProperties.supplierById.replace(':supplierId', supplierId));
  }

  getLegalForms(): Observable<any> {
    return this.httpClient.get(environment.api_root + commonProperties.legalForms);
  }

  mapLegalInformationFormToModel(value: any): LegalInformationModel {
    const result: LegalInformationModel = new LegalInformationModel();
    result.id = value.id;
    result.name = value.name;
    result.incorporationNumber = value.incorporationNumber;
    result.registrationCity = value.registrationCity;
    result.legalForm = value.legalForm;
    result.customLegalForm = value.customLegalForm;
    result.shareCapital = value.shareCapital;
    result.registrationNumber = value.registrationNumber;
    result.vatNumber = value.vatNumber;
    result.origin = value.origin.alpha2Code;
    result.phoneNumber = value.phoneNumber.internationalNumber.replace(/\s/g, '').replace('-', '');
    result.subjectToVat = value.TAX.subjectToVat;
    result.addressId = value.addressId;
    if (result.bank) {
      result.bank.bankName = value.bank.bankName;
      result.bank.iban = value.bank.iban;
      result.bank.bicOrSwift = value.bank.bicOrSwift;
      result.bank.ibanType = value.bank.ibanType;
    }
    result.hasDpo = value.hasDpo;
    if (result.hasDpo) {
      result.firstNameDpo = value.firstNameDpo;
      result.lastNameDpo = value.lastNameDpo;
      result.emailDpo = value.emailDpo;
    }
    return result;
  }

  updateCompanyById(legalInformationModel: LegalInformationModel) {
    return this.httpClient.put(environment.api_root + commonProperties.myCompany.replace(':companyId', legalInformationModel.id), legalInformationModel);
  }

  getCompanyToUpdate() {
    return this.companyToUpdateSubject.asObservable();
  }

  setCompanyToUpdate(company) {
    this.companyToUpdateSubject.next(company);
  }

  getUserAllowedRolesForEmployeeCreation(): Observable<any> {
    return this.httpClient.get(environment.api_root + commonProperties.authorities);
  }

  getCountries() {
    return this.httpClient.get(environment.api_root + commonProperties.countries);
  }

  getCompanyById(companyId: string) {
    return this.httpClient.get(environment.api_root + commonProperties.myCompany.replace(':companyId', companyId));
  }

  getCompanyRoles(types: string) {
    let params = new HttpParams();
    params = params.set('types', types);
    return this.httpClient.get(environment.api_root + commonProperties.authoritiesByCompanyType, {params});
  }

  getBillableCompaniesByPage(page: number, size: number, sort: string, search: string): Observable<ListContentModel> {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    }
    params = params.set('page', page.toString()).set('size', size.toString()).set('sort', sort);
    return this.httpClient.get<ListContentModel>(environment.api_root + this.billableCompanies, {params});
  }

  mapLegalInformationToCompanyTaxesPostModel(tvaFormValue: { taxGroupId: string; numbers: TaxNumber[]; }) {
    const companyTaxesPostModel: CompanyTaxesPostModel = new CompanyTaxesPostModel();
    companyTaxesPostModel.numbers = [];
    companyTaxesPostModel.taxGroupId = tvaFormValue.taxGroupId;
    tvaFormValue.numbers.map(taxType => companyTaxesPostModel.numbers.push({number: taxType.number, taxTypeId: taxType.taxTypeId}));
    return companyTaxesPostModel;
  }
}
