import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../assets/environments/environment';
import {TranslatorService} from '../../../core/services/translator.service';
import {ActivityAreaModel, LinkActivityAreaCompanyModel} from '../activity-areas/activity-area.model';


@Injectable({
  providedIn: 'root'
})
export class ActivityAreasService {

  private static activityAreasByCompanyId = 'enterpriseMgtApi/companies/:companyId/activityArea';
  private static activityAreasBasePath = 'enterpriseMgtApi/activityArea';
  constructor(private httpClient: HttpClient, private translatorService: TranslatorService) { }

  getAll(): Observable<ActivityAreaModel[]> {
    const options = {
      headers: new HttpHeaders().set('Accept-Language', this.translatorService.getLanguage()),
    };
    return this.httpClient.get<ActivityAreaModel[]>(environment.api_root + ActivityAreasService.activityAreasBasePath, options);
  }

  linkActivityAreaToCompany(params: LinkActivityAreaCompanyModel, companyId: string): Observable<any> {
    const options = {
      headers: new HttpHeaders().set('Accept-Language', this.translatorService.getLanguage())
    };
    return this.httpClient.put<LinkActivityAreaCompanyModel>(
      environment.api_root + ActivityAreasService.activityAreasByCompanyId.replace(':companyId', companyId), params, options
    );
  }

  getActivityAreasByCompany(companyId: string): Observable<any[]> {
    const options = {
      headers: new HttpHeaders().set('Accept-Language', this.translatorService.getLanguage()),
    };
    return this.httpClient.get<any>(environment.api_root + ActivityAreasService.activityAreasByCompanyId.replace(':companyId', companyId), options);
  }
}
