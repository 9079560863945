import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {DateFormatterService} from '@i-supplier/angular-shared-module';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NGXLogger} from 'ngx-logger';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {RoleService} from '../../../core/services/role.service';
import {ContractModeEnum} from '../../../shared/enums/CallForTenderOffers.enum';
import {OnCallParamModel, OnCallPostBody, WorkItemModel} from '../../../shared/models/on-call/on-call.model';
import {BadgesService} from '../../../shared/service/badges.service';
import {FileUtilsService} from '../../../shared/utils/file-utils.service';
import {maximumSizeFile} from '../../../shared/validators/maximum-size-file.validator';
import {requiredFileType} from '../../../shared/validators/required-file-type.validator';
import {EnveloppeResource} from '../../fees/services/enveloppe.resource';
import {PurchaseOrderService} from '../../purchase-order/services/purchase-order-service';
import {OnCallService} from '../services/on-call.service';

@Component({
  selector: 'create-on-call-modal',
  templateUrl: './create-on-call-modal.component.html',
  styleUrls: ['./create-on-call-modal.component.scss']
})
export class CreateOnCallModalComponent implements OnInit, OnDestroy, AfterContentChecked {
  @Output() actionConfirmed = new EventEmitter<any>();
  @Output() actionCanceled = new EventEmitter();
  subscriptions: Subscription[] = [];
  onCallForm: FormGroup;
  onCallEnvelopeList: EnveloppeResource[] = [];
  currentOnCallEnvelope: EnveloppeResource;
  currentParam: OnCallParamModel;
  currentType: WorkItemModel;
  fileToUpload: File = null;
  labelImport: string;
  dailyRateByDate = true;
  minDate: Date;
  maxDate: Date;
  allowedToTransmit: boolean;

  constructor(
    public modalRef: BsModalRef,
    private onCallService: OnCallService,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private logger: NGXLogger,
    private badgesService: BadgesService,
    private translateService: TranslateService,
    private authService: AuthenticationService,
    private cdref: ChangeDetectorRef,
    private purchaseOrderService: PurchaseOrderService,
    private dateFormatterService: DateFormatterService
  ) {}

  ngOnInit(): void {
    this.onCallForm = this.fb.group({
      purchaseOrder: [null, [Validators.required]],
      date: [null, [Validators.required]],
      type: [{value: null, disabled: true}, [Validators.required]],
      dailyRate: [{value: null, disabled: true}, [Validators.required, Validators.min(0)]],
      quantity: [null, [Validators.required, Validators.min(0), Validators.max(24)]],
      time: [{value: null, disabled: true}, [Validators.required]],
      comment: [null],
      file: [null, [requiredFileType(['pdf'])]],
      fileSize: [null, [maximumSizeFile(2, 'mo')]],
      fileContent: [null],
      fileName: [null, [requiredFileType('pdf')]],
      isPeriodCheck: [false],
      dateRange: [null]
    });
    this.subscribeToStatusChange();
  }

  private subscribeToStatusChange() {
    this.subscriptions.push(this.onCallForm.get('dateRange').valueChanges.subscribe((res: any) => {
        if (res) {
          this.updateDailyRate();
          this.onCallForm.get('quantity').patchValue(this.daysBetweenDates(res[0], res[1]));
        }
      }
    ));
    this.subscriptions.push(this.onCallForm.get('date').valueChanges.subscribe((res: any) => {
        if (res) {
          this.updateDailyRate();
        }
      }
    ));
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  setOnCallValidityPeriod() {
    this.minDate = this.dateFormatterService.formatDateTimeZoneForDatepicker(this.currentOnCallEnvelope.startDate);
    this.maxDate = this.dateFormatterService.formatDateTimeZoneForDatepicker(this.currentOnCallEnvelope.endDate);
  }

  selectedPO() {
    this.currentOnCallEnvelope = this.onCallEnvelopeList.find(aOnCall => aOnCall.purchaseOrderNumber === this.onCallForm.get('purchaseOrder').value);
    this.allowedToTransmit = undefined;
    this.purchaseOrderService.getPurchaseOrdersWaitingForRequest(this.currentOnCallEnvelope.requestId).subscribe(value => {
      this.allowedToTransmit = !value;
    });
    this.onCallForm.get('type').setValue(null);
    this.onCallForm.get('type').reset();
    this.setOnCallValidityPeriod();


    this.subscriptions.push(this.onCallService.getPurchaseOrderContract(this.currentOnCallEnvelope.purchaseOrderId).subscribe((data: Required<{onCallParamId: number}>) => {
      this.subscriptions.push(this.onCallService.getOnCallParam(data.onCallParamId.toString()).subscribe((res: OnCallParamModel) => {
        res.workItems = res.workItems.sort((a, b) => {
          return a.reference > b.reference ? 1 : -1;
        });
        this.currentParam = res;
        this.onCallForm.get('type').enable();
      }));
    }));
  }

  selectedType() {
    this.onCallForm.get('dateRange').setValue(null);
    this.onCallForm.get('dateRange').reset();
    this.onCallForm.get('date').setValue(null);
    this.onCallForm.get('date').reset();
    this.currentType = this.currentParam.workItems.find(aType => aType.reference === this.onCallForm.get('type').value);
    if (this.currentType.workUnit === 'DAY' || this.currentType.workUnit === 'PACKAGE_DAY') {
      this.onCallForm.get('time').disable();
      this.onCallForm.get('quantity').disable();
      this.onCallForm.get('quantity').setValue(1);
    } else {
      this.updateControl(this.onCallForm.get('isPeriodCheck'), null);
      this.onCallForm.get('time').enable();
      this.onCallForm.get('quantity').enable();
      this.onCallForm.get('quantity').setValue(null);
    }
  }

  createAction(): void {
    this.onCallService.postOnCall(this.mapCreateModalDataToPostBody(this.onCallForm.value)).subscribe({
      next: data => {
        this.actionConfirmed.emit(data);
        this.toastrService.success(this.translateService.instant('On_call_ts.successful_on_call_update'));
        this.badgesService.refreshInvoiceToGenerateBadge();
        this.modalRef.hide();
      },
      error: (error:  Required<{url: string, status: number, error: {errors: {onCallResource: [{message: string}]}}}>) => {
        this.logger.error(error.url, '- STATUS :', error.status);
        if (error.error.errors.onCallResource.length > 0 ) {
          error.error.errors.onCallResource.filter(x => !x.message.endsWith('Invalid On-Call POST/PUT resource')).forEach(res => this.toastrService.info(res.message.replaceAll('&eacute;', 'é').replaceAll('&agrave;', 'à')));
        } else {
          this.toastrService.error(this.translateService.instant('global.ts.error'));
        }
      }
    });
  }

  async onFileChange(files: FileList) {
    this.labelImport = Array.from(files)
    .map(f => f.name)
    .join(', ');
    this.fileToUpload = files.item(0);
    this.onCallForm.get('fileSize').setValue(this.fileToUpload);
    const fileBase64 = await FileUtilsService.convertFileToBase64(this.fileToUpload);
    this.onCallForm.get('fileName').setValue(this.fileToUpload.name);
    this.onCallForm.get('fileContent').setValue(fileBase64);
  }

  deleteFile() {
    this.onCallForm.get('fileName').setValue(null);
    this.onCallForm.get('fileContent').setValue(null);
  }

  cancelAction(): void {
    this.actionCanceled.emit();
    this.modalRef.hide();
  }


  filterEnvelopeList(itemList: EnveloppeResource[]): EnveloppeResource[] {
    return itemList.filter(value => {
      if (!value.enveloppes['ON_CALL']) {
        return false;
      }
      for (const envelop of value.enveloppes['ON_CALL']) {
        if (envelop.remaining > 0) {
          return true;
        }
      }
      return false;
    });
  }

  mapCreateModalDataToPostBody(object: any): OnCallPostBody {
    const result = new OnCallPostBody();
    result.buyerTenantId = this.currentOnCallEnvelope.buyerId;
    if (object.comment) {
      result.comment = object.comment;
    }
    result.dailyRate = object.dailyRate;
    if (object.fileContent) {
      result.fileContent = object.fileContent;
      result.fileName = object.fileName;
    }
    result.onCallConfigId = this.currentParam.id;
    result.purchaseOrderNumber = object.purchaseOrder;
    if (this.currentType.workUnit === 'HOUR' || this.currentType.workUnit === 'PACKAGE_HOUR') {
      result.time = moment(object.time).format('HH:mm');
      result.quantity = object.quantity;
    } else {
      result.quantity = 1;
    }
    result.workItemId = this.currentType.id;
    result.type = object.type;
    result.isPeriodCheck = !!object.isPeriodCheck;

    if (result.isPeriodCheck) {
      const startDateRange = this.dateFormatterService.timeZoneOffset(object.dateRange[0]);
      const endDateRange = this.dateFormatterService.timeZoneOffset(object.dateRange[1]);
      result.startDateRange = startDateRange;
      result.endDateRange = endDateRange;
    } else {
      result.date = this.dateFormatterService.timeZoneOffset(object.date);
    }
    return result;
  }

  dateRangeCheck () {
    return this.onCallForm.get('type').value && (['DAY', 'PACKAGE_DAY']).includes(this.currentType.workUnit);
  }

  daysBetweenDates(date1: Date, date2: Date): number {
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const daysDiff = Math.floor(timeDiff / oneDay);
    return daysDiff + 1; // Add 1 to include the first date
  }

  togglePeriod() {
    const dateControl = this.onCallForm.get('date');
    const dateRangeControl = this.onCallForm.get('dateRange');
    const quantityControl = this.onCallForm.get('quantity');

    if (this.onCallForm.get('isPeriodCheck').value) {
      this.updateControl(dateControl, null);
      this.updateControl(dateRangeControl, Validators.required);
    } else {
      this.updateControl(dateRangeControl, null);
      this.updateControl(dateControl, Validators.required);
      quantityControl.setValue(1);
    }
  }

  updateDailyRate() {
    const dateControl = this.onCallForm.get('date');

    if (this.currentOnCallEnvelope.contractMode === ContractModeEnum.BILL_ONLY || this.currentOnCallEnvelope.contractMode === ContractModeEnum.FIXED_PRICE) {
      this.dailyRateByDate = true;
      this.onCallForm.get('dailyRate').enable();
      this.onCallForm.get('dailyRate').setValue(null);
    } else if (this.currentOnCallEnvelope.contractMode === ContractModeEnum.T_M) {
      this.dailyRateByDate = this.currentOnCallEnvelope.dailyRateByDate;
      const enveloppe = this.currentOnCallEnvelope.enveloppes['ON_CALL'].filter(item => {
        return new Date(item.startDate) <= dateControl.value && dateControl.value <= new Date(item.endDate);
      }).pop();
      this.onCallForm.get('dailyRate').enable();
      this.onCallForm.get('dailyRate').setValue(enveloppe?.unitPrice);
    } else {
      this.dailyRateByDate = true;
      this.onCallForm.get('dailyRate').disable();
      this.onCallForm.get('dailyRate').setValue(null);
    }
  }

  isProvider() {
    return this.authService.isUserProvider();
  }

  // Helper function to update control validators and reset
  private updateControl(control: AbstractControl, validator: ValidatorFn | null) {
    control.clearValidators();
    control.setValidators(validator);
    control.updateValueAndValidity();
    control.reset();
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
  }
}
